<template>
  <input
    v-model="text"
    class="form-control"
    type="text"
    :placeholder="$t('insertTextPlaceholder')"
    @input="$emit('input', { value: text })"
  />
</template>
<script>
export default {
  props: ['value'],

  data() {
    return {
      text: this.value.value || ''
    }
  }
}
</script>
