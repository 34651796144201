import VueDialog from '@/libs/VueDialog'
import AlertDialog from '@/components/Alert'

const alert = {
  show(options) {
    VueDialog.show(AlertDialog, options)
  },

  info(message, title = 'Information') {
    alert.show({ type: 'info', title, message })
  },

  question(message, title = 'Question?') {
    alert.show({ type: 'question', title, message })
  },

  warning(message, title = 'Warning') {
    alert.show({ type: 'warning', title, message })
  },

  error(message, title = 'Error!') {
    alert.show({ type: 'error', title, message })
  }
}

export default alert
