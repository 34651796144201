<template>
  <multiselect
    :value="objectValue"
    :placeholder="placeholder"
    track-by="value"
    label="name"
    select-label=""
    selected-label=""
    deselect-label=""
    :options="options"
    :option-height="36"
    :searchable="false"
    :allow-empty="false"
    @input="onChange"
  />
</template>
<script>
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect
  },
  props: {
    value: {},
    options: {
      type: Array,
      required: true
    },
    placeholder: {
      type: String,
      default: function () {
        return this.$t('makeASelectionLabel')
      }
    }
  },

  computed: {
    objectValue() {
      return this.options.find(option => option.value === this.value)
    }
  },

  methods: {
    onChange({ value }) {
      this.$emit('input', value)
    }
  }
}
</script>
