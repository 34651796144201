<template>
  <span>
    {{ $t('toolboxSmallProfileInTheLast') }}
    <span v-if="isValid" class="criteria-condition-args">
      {{ conditionArgs.amount }}
      {{ translations[conditionArgs.operator] || '' }}
    </span>
    <span v-else class="invalid-text">{{ $t('toolboxSmallProfileMissingData') }}</span>
  </span>
</template>
<script>
export default {
  props: ['conditionArgs'],

  data() {
    return {
      translations: {
        days: this.$t('toolboxSmallProfileDays'),
        hours: this.$t('toolboxSmallProfileHours'),
        minutes: this.$t('toolboxSmallProfileMinutes')
      }
    }
  },

  computed: {
    isValid() {
      return !(
        isNaN(this.conditionArgs.amount) || this.conditionArgs.amount === ''
      )
    }
  }
}
</script>
