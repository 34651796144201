<template>
  <ul>
    <li>
      <icon v-tooltip:left="$t('actionTooltipArrived')" glyph="user" />
      {{ numberFilter(entered) }}
    </li>
    <li>
      <icon v-tooltip:left="$t('yesLabel')" glyph="tick-box" />
      {{ numberFilter(stats['Decision:evaluatedYes']) }}
    </li>
    <li>
      <icon v-tooltip:left="$t('noLabel')" glyph="cross-circle" />
      {{ numberFilter(stats['Decision:evaluatedNo']) }}
    </li>
  </ul>
</template>
<script>
import { numberFilter } from '@/helpers/filters'

export default {
  props: ['stats'],

  data() {
    return {
      numberFilter
    }
  },

  computed: {
    entered() {
      return (
        (Number(this.stats['Decision:evaluatedYes']) || 0) +
        (Number(this.stats['Decision:evaluatedNo']) || 0)
      )
    }
  }
}
</script>
