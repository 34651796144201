<template>
  <div class="group-ct">
    <div class="retry-for">
      <input
        ref="unitValue"
        :value="retry_for"
        class="form-control"
        type="number"
        :min="1"
        :max="999"
        :disabled="disabled"
        @change="updateUnitValue"
      />
    </div>
    <div class="retry-for-period">
      <multiselect
        :value="selectedOption"
        :options="options"
        :disabled="disabled"
        placeholder="Select Option"
        track-by="id"
        label="label"
        select-label=""
        selected-label=""
        deselect-label=""
        :option-height="38"
        :searchable="false"
        :allow-empty="false"
        @input="updateUnitType"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    retry_for: {
      type: Number,
      require: true
    },

    retry_for_period: {
      type: String,
      require: true
    },

    disabled: {
      type: Boolean
    }
  },

  data() {
    return {
      options: [
        { id: 'seconds', label: this.$t('retryForSeconds') },
        { id: 'minutes', label: this.$t('retryForMinutes') },
        { id: 'hours', label: this.$t('retryForHours') },
        { id: 'days', label: this.$t('retryForDays') },
        { id: 'weeks', label: this.$t('retryForWeeks') }
      ]
    }
  },

  computed: {
    selectedOption: function () {
      return this.options.find(({ id }) => id === this.retry_for_period)
    }
  },

  methods: {
    updateUnitValue(e) {
      let value = parseInt(e.target.value)

      if (value < 1) {
        value = 1
      }

      if (value > 999) {
        value = 999
      }

      this.$emit('retry_for', value)
    },

    updateUnitType(value) {
      this.$emit('retry_for_period', value.id)
    }
  }
}
</script>
<style scoped>
.group-ct {
  width: 50%;
  margin-top: 15px;
  display: flex;
}

.form-control {
  height: 38px;
}

.retry-for {
  width: 15%;
  margin-right: 25px;
}

.retry-for-period {
  width: 85%;
}

.select2-container--bootstrap .select2-selection--single {
  height: 38px !important;
}

.select2 .select2-container .select2-container--bootstrap .form-control {
  width: 100% !important;
}
</style>
