<template>
  <div style="height: 100%; overflow-y: scroll; padding: 30px">
    <label>{{ $t('emailEngagementStatsTitle') }}:</label>
    <div class="stats-bar">
      <div class="stats-bar-item">
        <icon class="stats-bar-item-icon" glyph="email-open" />
        <div class="stats-bar-item-title">
          {{ $t('emailEngagementStatsOpensLabel') }}:
        </div>
        <div class="stats-bar-item-number">
          {{ numberFilter(uniqueOpensTotal) }}
          <span v-if="uniqueOpensTotal !== 'N/A'">
            ({{ percentageOfFilter(uniqueOpensTotal, deliveredTotal) }})
          </span>
        </div>
      </div>
      <div class="stats-bar-item">
        <icon class="stats-bar-item-icon" glyph="mouse" />
        <div class="stats-bar-item-title">
          {{ $t('emailEngagementStatsClicksLabel') }}:
        </div>
        <div class="stats-bar-item-number">
          {{ numberFilter(uniqueClicksTotal) }}
          <span v-if="uniqueOpensTotal !== 'N/A'">
            ({{ percentageOfFilter(uniqueClicksTotal, deliveredTotal) }})
          </span>
        </div>
      </div>
      <div class="stats-bar-item">
        <icon class="stats-bar-item-icon" glyph="email-unsubscribe" />
        <div class="stats-bar-item-title">
          {{ $t('emailEngagementStatsUnsubscribesLabel') }}:
        </div>
        <div class="stats-bar-item-number">
          {{ numberFilter(unsubscribesTotal) }}
          ({{ percentageOfFilter(unsubscribesTotal, deliveredTotal) }})
        </div>
      </div>
    </div>
    <hr />

    <div class="btn-group pull-right">
      <button
        class="btn btn-default"
        :class="{ active: viewMode === 'numbers' }"
        @click="viewMode = 'numbers'"
      >
        {{ $t('emailEngagementStatsNumbersLabel') }}
      </button>
      <button
        class="btn btn-default"
        :class="{ active: viewMode === 'percent' }"
        @click="viewMode = 'percent'"
      >
        {{ $t('emailEngagementStatsPercentLabel') }}
      </button>
    </div>

    <keep-alive>
      <date-line-chart
        v-if="viewMode === 'numbers'"
        key="numbersChart"
        :chart-data="numbersChartData"
        :title="$t('emailEngagementStatsOpensClicksUnsubscribes')"
        title-class="action-color-email"
        :y-label="$t('actionReportingNumberOfProfiles')"
      />

      <date-line-chart
        v-if="viewMode === 'percent'"
        key="percentChart"
        :y-axes-percent="true"
        :chart-data="percentChartData"
        :title="$t('emailEngagementStatsOpensClicksUnsubscribes')"
        title-class="action-color-email"
        :y-label="$t('actionReportingPercentageChartLabel')"
      />
    </keep-alive>
  </div>
</template>
<script>
import TrendLineMixin from '@/libs/TrendLineMixin'
import DateLineChart from '@/components/DateLineChart'
import { numberFilter, percentageOfFilter } from '@/helpers/filters'

export default {
  components: {
    DateLineChart
  },

  mixins: [TrendLineMixin],
  props: [
    'deliveredTotal',
    'uniqueOpensTotal',
    'uniqueClicksTotal',
    'unsubscribesTotal',

    'dateRange',
    'sentStats',
    'openStats',
    'clickStats'
  ],

  data() {
    return {
      viewMode: 'numbers',
      numberFilter,
      percentageOfFilter
    }
  },

  computed: {
    openedPercentStats() {
      const total = [],
        sentStats = this.sentStats,
        openStats = this.openStats

      sentStats.forEach((entered, idx) =>
        total.push(Math.round((openStats[idx] / entered) * 100) || 0)
      )

      return total
    },

    clickedPercentStats() {
      const total = [],
        sentStats = this.sentStats,
        clickStats = this.clickStats

      sentStats.forEach((entered, idx) =>
        total.push(Math.round((clickStats[idx] / entered) * 100) || 0)
      )

      return total
    },

    numbersChartData() {
      const openStats = this.openStats === 'N/A' ? [] : this.openStats
      const clickStats = this.clickStats === 'N/A' ? [] : this.clickStats

      return {
        labels: this.dateRange,
        datasets: [
          {
            label: this.$t('emailEngagementStatsDatasetOpensLabel'),
            backgroundColor: 'RGBA(77, 140, 192, 0.2)',
            borderColor: 'RGBA(77, 140, 192, 1)',
            borderWidth: 1,
            data: openStats
          },
          {
            tooltip: false,
            fill: false,
            label: this.$t('emailEngagementStatsDatasetTrendLineLabel'),
            data: this.getTrendLine(openStats),
            backgroundColor: 'RGBA(77, 140, 192, 1)',
            borderColor: 'RGBA(77, 140, 192, 1)',
            spanGaps: true
          },
          {
            label: this.$t('emailEngagementStatsDatasetClicks'),
            backgroundColor: 'RGBA(255, 195, 113, 0.2)',
            borderColor: 'RGBA(255, 195, 113, 1)',
            borderWidth: 1,
            data: clickStats
          },
          {
            tooltip: false,
            fill: false,
            label: this.$t('emailEngagementStatsDatasetTrendLineLabel'),
            data: this.getTrendLine(clickStats),
            backgroundColor: 'RGBA(255, 195, 113, 1)',
            borderColor: 'RGBA(255, 195, 113, 1)',
            spanGaps: true
          }
        ]
      }
    },

    percentChartData() {
      return {
        labels: this.dateRange,
        datasets: [
          {
            label: this.$t('emailEngagementStatsDatasetOpensLabel'),
            backgroundColor: 'RGBA(77, 140, 192, 0.2)',
            borderColor: 'RGBA(77, 140, 192, 1)',
            borderWidth: 1,
            data: this.openedPercentStats
          },
          {
            tooltip: false,
            fill: false,
            label: this.$t('emailEngagementStatsDatasetTrendLineLabel'),
            data: this.getTrendLine(this.openedPercentStats),
            backgroundColor: 'RGBA(77, 140, 192, 1)',
            borderColor: 'RGBA(77, 140, 192, 1)',
            spanGaps: true
          },
          {
            label: this.$t('emailEngagementStatsDatasetClicks'),
            backgroundColor: 'RGBA(255, 195, 113, 0.2)',
            borderColor: 'RGBA(255, 195, 113, 1)',
            borderWidth: 1,
            data: this.clickedPercentStats
          },
          {
            tooltip: false,
            fill: false,
            label: this.$t('emailEngagementStatsDatasetTrendLineLabel'),
            data: this.getTrendLine(this.clickedPercentStats),
            backgroundColor: 'RGBA(255, 195, 113, 1)',
            borderColor: 'RGBA(255, 195, 113, 1)',
            spanGaps: true
          }
        ]
      }
    }
  }
}
</script>
