<template>
  <div class="editable" :class="{ disabled: disabled }">
    <div v-show="!editing" class="value" @click="focus">
      {{ value }}
      <span class="editable-icon" :class="{ hidden: disabled }">
        <i class="glyphicon glyphicon-pencil"></i>
      </span>
    </div>
    <div
      class="editor"
      :class="{ editing: editing, saving: saving, hidden: disabled }"
    >
      <input
        ref="input"
        class="field"
        :disabled="saving"
        @keyup.enter="editorSave"
      />
      <div class="saving-icon">
        <i class="glyphicon glyphicon-asterisk spinning"></i>
      </div>
      <div class="save-options">
        <button class="save" @click="editorSave">
          <i class="glyphicon glyphicon-ok"></i>
        </button>
        <button class="cancel" @click="editorCancel">
          <i class="glyphicon glyphicon-remove"></i>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
let activeEditable
window.addEventListener('mousedown', (e) => {
  if (activeEditable) {
    activeEditable.handleMouseDown(e)
  }
})

export default {
  props: ['value', 'disabled'],

  data() {
    return {
      editing: false,
      saving: false
    }
  },

  watch: {
    editing() {
      if (this.editing) {
        activeEditable = this

        this.$refs.input.value = this.value
        this.$nextTick(() => this.$refs.input.focus())
      }

      if (!this.editing && activeEditable === this) {
        activeEditable = null
      }
    }
  },

  methods: {
    handleMouseDown(e) {
      if (!this.$el.contains(e.target)) {
        this.blur()
      }
    },

    blur() {
      if (!this.editing || this.saving) {
        return
      }

      const value = this.$refs.input.value
      if (this.value === value) {
        this.editing = false

        return
      }

      this.saving = true
      this.$emit('input', this.$refs.input.value, () => {
        this.saving = false
        this.editing = false
      })
    },

    focus() {
      if (this.disabled) {
        return
      }

      this.editing = true
    },

    editorSave() {
      this.blur()
    },

    editorCancel() {
      this.editing = false
    }
  }
}
</script>
<style lang="sass" scoped>
.editable
    cursor: text
    display: inline-block
    position: relative

    .value
        padding: 2px 30px 2px 5px
        transition: box-shadow 150ms ease-in 0ms
        border-radius: 3px

        &:hover
            box-shadow: 0 0 0 1px #ccc

            .editable-icon
                opacity: 1

        .editable-icon
            position: absolute
            top: 0
            right: 0
            bottom: 0
            opacity: 0
            cursor: pointer
            font-size: 14px
            padding: 3px 5px
            color: #707070
            background-color: #f0f0f0
            border-left: 1px solid #ccc
            border-top-right-radius: 3px
            border-bottom-right-radius: 3px
            transition: opacity 150ms ease-in 0ms

    .editor
        display: none
        width: 100%
        position: relative
        margin-top: -1px

        .field
            width: 300px
            border: 1px solid #ccc
            box-shadow: 0 1px 3px #e8e8e8 inset
            border-radius: 3px 3px 0 3px
            padding: 3px 3px 2px 4px
            color: #555
            background-color: #fff

            &:focus
                outline: none !important

            &:disabled
                background-color: #eee
                cursor: not-allowed

        .saving-icon
            display: none
            position: absolute
            top: 0
            right: 0
            width: 24px
            height: 100%
            padding: 4px
            color: #ccc
            border-left: 1px solid #ccc

        .save-options
            z-index: 1
            position: absolute
            top: 100%
            right: 0
            padding: 3px
            background: #f0f0f0
            border: 1px solid #ccc
            border-top: none
            box-shadow: 0 3px 6px rgba(111, 111, 111, 0.2)
            border-radius: 0 0 3px 3px

            .save,
            .cancel
                border: 1px solid #ccc
                border-radius: 3px
                font-size: 14px
                height: 24px
                padding: 2px 4px
                color: #707070
                background: #f5f5f5

                &:hover
                    color: #333
                    background: #e9e9e9
                    border-color: #999

                &:focus
                    outline: none !important

                &:active
                    box-shadow: inset 0 3px 6px 0 rgba(0, 0, 0, 0.1)



            button + button
                margin-left: 3px

    .editor.editing
        display: block

    .editor.saving
        .save-options
            display: none

        .saving-icon
            display: block

.editable.disabled
    cursor: default

    .value:hover
        box-shadow: none

        .editable-icon
            opacity: 0

.glyphicon.spinning
    animation: spin 1s infinite linear

@keyframes spin
    from
        transform: scale(1) rotate(0deg)
    to
        transform: scale(1) rotate(360deg)
</style>
