<script>
import Action from './AbstractAction'

export default {
  extends: Action,
  inject: ['workflowApi'],
  data() {
    return {
      icon: 'volume-high'
    }
  },
  created() {
    if (this.action.promotion_id && !this.readOnly) {
      this.$store.dispatch('fetchWorkflowPromotions').then(() => {
        let promotion = this.$store.state.project.workflowPromotions.find(
          (promotion) => promotion.id === this.action.promotion_id
        )
        if (!promotion) {
          this.workflowApi.updateAction(this.action.id, { promotion_id: "" })
        }
      })
    }
  }
}
</script>
