<template>
  <div>
    <icon glyph="workflow" />
    Changed
    <b>{{ fields }}</b>
    on workflow
    <b>{{ log.workflowId }}</b>
  </div>
</template>
<script>
export default {
  props: ['log', 'metadata'],

  computed: {
    fields() {
      const mapping = {
        events: 'events',
        isTransactional: 'transactional type'
      }

      return this.metadata.eventData
        .map((val) => mapping[val] || val)
        .join(', ')
    }
  }
}
</script>
