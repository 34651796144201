<template>
  <div style="color: red">
    Unknown event type
    <i>{{ eventType }}</i>
  </div>
</template>
<script>
export default {
  props: ['eventType']
}
</script>
