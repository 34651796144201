var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tabs',{attrs:{"nav-class":"nav-side","starting-tab":"quick-stats","full-height":true}},[_c('tab',{attrs:{"id":"quick-stats","position":1,"icon":"bar-chart"}},[_c('email-stats',_vm._b({},'email-stats',{
        arrivedTotal: _vm.arrivedTotal,
        sentTotal: _vm.sentTotal,
        deliveredTotal: _vm.deliveredTotal,
        bouncedTotal: _vm.bouncedTotal,
        uniqueOpensTotal: _vm.uniqueOpensTotal,
        uniqueClicksTotal: _vm.uniqueClicksTotal,
        unsubscribesTotal: _vm.unsubscribesTotal
      },false))],1),_vm._v(" "),_c('tab',{attrs:{"id":"delivery","position":2,"icon":"email","keep-alive":true}},[_c('delivery',_vm._b({},'delivery',{
        arrivedTotal: _vm.arrivedTotal,
        sentTotal: _vm.sentTotal,
        deliveredTotal: _vm.deliveredTotal,
        bouncedTotal: _vm.bouncedTotal,
        dateRange: _vm.dateRange,
        arrivedStats: _vm.arrivedStats,
        sentStats: _vm.sentStats
      },false))],1),_vm._v(" "),_c('tab',{attrs:{"id":"engagement","position":3,"icon":"email-open","keep-alive":true}},[_c('engagement',_vm._b({},'engagement',{
        deliveredTotal: _vm.deliveredTotal,
        uniqueOpensTotal: _vm.uniqueOpensTotal,
        uniqueClicksTotal: _vm.uniqueClicksTotal,
        unsubscribesTotal: _vm.unsubscribesTotal,
        dateRange: _vm.dateRange,
        sentStats: _vm.sentStats,
        openStats: _vm.openStats,
        clickStats: _vm.clickStats
      },false))],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }