<template>
  <div>
    <div>
      <div class="previews-container">
        <div class="side-icon">
          <img src="@/images/safari.svg" />
        </div>
        <div class="preview-window">
          <div class="preview-column">
            <div class="preview-row">
              <div class="icon">
                <img v-if="iconUrl" :src="iconUrl" />
                <img v-else :src="companyLogo" />
              </div>
              <div class="preview-column text-content">
                <div v-if="title.length > 0" class="title">
                  {{ title }}
                </div>
                <div class="message">
                  <span style="white-space: pre;">{{
                    message || $t('webPushYourMessageHere')
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="previews-container">
      <div class="side-icon">
        <img src="@/images/chrome.svg" />
      </div>
      <div class="preview-window">
        <div class="preview-column">
          <div class="preview-row">
            <div class="icon">
              <img src="@/images/chrome.svg" />
            </div>
            <div class="preview-column text-content chrome-firefox-text">
              <div v-if="title.length > 0" class="title">
                {{ title }}
              </div>
              <div class="url">
                {{ filteredUrl }}
              </div>
              <div class="message">
                <span style="white-space: pre;">{{
                  message || $t('webPushYourMessageHere')
                }}</span>
              </div>
            </div>
            <div class="right-icon">
              <img v-if="iconUrl" :src="iconUrl" />
              <img v-else :src="companyLogo" />
            </div>
            <div class="menu">
              <div class="button">
                {{ $t('webPushClose') }}
              </div>
              <div class="button">
                {{ $t('webPushSettings') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="previews-container">
      <div class="side-icon">
        <img src="@/images/firefox.svg" />
      </div>
      <div class="preview-window">
        <div class="preview-column">
          <div class="preview-row">
            <div class="icon">
              <img src="@/images/firefox.svg" />
            </div>
            <div class="preview-column text-content chrome-firefox-text">
              <div v-if="title.length > 0" class="title">
                {{ title }}
              </div>
              <div class="url">{{ $t('webPushVia', {filteredUrl: filteredUrl}) }}</div>
              <div class="message">
                <span style="white-space: pre;">{{
                  message || $t('webPushYourMessageHere')
                }}</span>
              </div>
            </div>
            <div class="right-icon">
              <img v-if="iconUrl" :src="iconUrl" />
              <img v-else :src="companyLogo" />
            </div>
            <div class="menu">
              <div class="button">
                {{ $t('webPushClose') }}
              </div>
              <div class="button">
                {{ $t('webPushSettings') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },

    message: {
      type: String,
      default: ''
    },

    iconUrl: {
      type: String
    },

    pictureUrl: {
      type: String
    },

    companyLogo: {
      type: String
    },

    url: {
      type: String,
      default: ''
    }
  },

  computed: {
    filteredUrl() {
      if (this.url.length < 0) {
        return
      }

      return this.url.replace(/(^\w+:|^)\/\//, '')
    }
  }
}
</script>

<style lang="sass" scoped>
.previews-container
    display: flex
    flex-direction: row
    margin: 20px 0

    .side-icon
        height: 50px
        width: 55px
        margin: auto 0

        img
            height: 100%
            width: 100%

    .preview-window
        overflow: hidden
        width: 100%
        border: 0.5px solid #ddd
        max-height: 90px
        border-radius: 10px
        margin-left: 10px
        background-color: #f0f0f0

        .preview-column
            display: flex
            flex-direction: column

            .preview-row
                display: flex
                flex-direction: row

                .icon
                    flex: 1
                    height: 45px
                    width: 45px
                    margin: 12px 10px

                    img
                        width: 100%
                        height: 100%

                .text-content
                    display: flex
                    flex-direction: column
                    margin: 10px 0 10px 0
                    flex: 7
                    overflow: hidden
                    text-overflow: ellipsis

                    .title
                        font-weight: 700
                        overflow: hidden
                        text-overflow: ellipsis

                    .message
                        white-space: nowrap
                        overflow: hidden
                        text-overflow: ellipsis

                    .url
                        height: 20px
                        overflow: hidden
                        text-overflow: ellipsis
                        color: #707070

                .chrome-firefox-text
                    flex: 6
                    overflow: hidden
                    text-overflow: ellipsis
                    margin: 5px 0 0 0

                .right-icon
                    display: flex
                    height: 50px
                    width: 50px
                    margin: 10px

                    img
                        width: 100%
                        height: 100%

                .menu
                    display: none
                    height: 69px
                    width: 70px
                    border-left: 1px solid #ddd
                    flex-direction: column
                    justify-content: center

                    .button:first-child
                        border-bottom: 1px solid #ddd

                    .button
                        text-align: center
                        padding: 7px 5px

    .preview-window:hover
        .preview-column
            .right-icon
                display: none

        .preview-column
            .preview-row
                .menu
                    display: flex
</style>
