import MxaApi from '@/libs/MxaApi'
import moment from 'moment'

export default {
  props: ['action'],

  data() {
    return {
      stats: {}
    }
  },

  mounted() {
    const projectId = this.$route.params.projectId,
      workflowId = this.$route.params.workflowId,
      actionId = this.action.id

    const endDate = moment().format('YYYY-MM-DD'),
      startDate = moment().subtract(6, 'months').format('YYYY-MM-DD')

    MxaApi.get(
      `projects/${projectId}/workflows/${workflowId}/actions/${actionId}/summary`,
      {
        params: {
          startDate,
          endDate
        }
      }
    ).then(({ data }) => {
      this.stats = Object.freeze(data)
    })
  }
}
