<template>
  <div :class="{ 'tab-full-height': fullHeight }">
    <ul class="nav nav-tabs" :class="navClass">
      <li
        v-for="tab in orderedTabs"
        :class="{ active: tab.id === activeTab }"
        @click="activateTab(tab.id)"
      >
        <a :class="{ disabled: tab.disabled }">
          <icon v-if="tab.icon" v-tooltip="tab.tooltip" :glyph="tab.icon" />
          <span v-if="tab.title">{{ tab.title }}</span>
        </a>
      </li>
      <li v-if="actionType === 'MultiDecision' && orderedTabs.length < 5">
        <a v-tooltip="$t('addDecisionGroupLabel')" @click="addDecision()">
          <icon glyph="add" />
        </a>
      </li>
    </ul>
    <div class="tab-content">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    startingTab: {
      type: String
    },
    navClass: {
      type: String,
      default: '' // example nav-pills, nav-stacked, nav-side
    },
    fullHeight: {
      type: Boolean,
      default: false
    },
    actionType: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      activeTab: this.startingTab,
      tabs: []
    }
  },

  computed: {
    orderedTabs() {
      return this.tabs.sort((tab1, tab2) => {
        return tab1.position - tab2.position
      })
    }
  },

  methods: {
    addTab(tab) {
      this.tabs.push(tab.$props)
    },

    removeTab(id) {
      this.tabs = this.tabs.filter((tab) => tab.id !== id)
      if (this.activeTab === id && this.tabs.length) {
        this.activeTab = this.tabs[0].id
      }
    },

    activateTab(id) {
      if (this.activeTab === id) return

      const tab = this.tabs.find((tab) => tab.id === id)
      if (tab.disabled) {
        return
      }

      this.$emit('change-tab', id, this.activeTab)
      this.activeTab = id
      this.$nextTick(() => (this.$el.scrollTop = 0))
    },

    addDecision() {
      this.$emit('add-multidecision-group')
    }
  }
}
</script>
<style lang="sass" scoped>
.nav-tabs li a
    cursor: pointer
    &.disabled
        cursor: not-allowed
        *
            opacity: .3

.tab-full-height
    height: 100%
    overflow-y: auto
</style>
