<template>
  <div>
    <action-icon :action-type="metadata.actionType" />
    Created
    <b>{{ metadata.actionType }}</b>
    action on workflow
    <b>{{ metadata.workflowId }}</b>
  </div>
</template>
<script>
export default {
  props: ['metadata']
}
</script>
