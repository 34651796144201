import MxaApi from '@/libs/MxaApi'
import url from '@/libs/UrlTemplateTag'
import { merge } from 'lodash'

export default {
  state: {
    user: null,
    project: null,
    xpBaseUrl: null
  },

  mutations: {
    setUser(state, user) {
      state.user = user
    },

    setProject(state, project) {
      state.project = project
    },

    setXpBaseUrl(state, xpBaseUrl) {
      state.xpBaseUrl = xpBaseUrl
    }
  },

  getters: {
    permissions(state) {
      if (state.project && state.project.roleHierarchy) {
        return state.project.roleHierarchy
      }

      return []
    },

    companyLogo(state) {
      if (state.project && state.project.icon) {
        return state.project.icon
      }

      return ''
    },

    getDomain: (state) => (domain) => {
      if (state.project && state.project.domains[domain]) {
        return state.project.domains[domain]
      }

      return ''
    },

    isFeatureEnabled: (state) => (feature) => {
      return state.project && state.project.projectFeatures[feature]
    },

    emailEnabled(state) {
      const projectChannels = state.project && state.project.projectChannels

      if (projectChannels) {
        return !!projectChannels.email
      }
    },

    smsEnabled(state) {
      const projectChannels = state.project && state.project.projectChannels

      if (projectChannels) {
        return !!projectChannels.sms
      }
    },

    pushEnabled(state) {
      const projectChannels = state.project && state.project.projectChannels

      if (projectChannels) {
        return !!(
          projectChannels.android_push ||
          projectChannels.ios_push ||
          projectChannels.web_push
        )
      }
    },

    inboxEnabled(state) {
      const projectChannels = state.project && state.project.projectChannels

      if (projectChannels) {
        return !!projectChannels.inbox
      }
    },

    getXpBaseUrl(state) {
      return state.xpBaseUrl
    },

    getLanguage(state) {
      let language = 'en'
      if (state.user && state.user.language) {
        language = state.user.language
      } else if (state.project && state.project.projectProviderLanguage) {
        language = state.project.projectProviderLanguage
      }
      return language
    }
  },

  actions: {
    setUser({ commit }, user) {
      commit('setUser', user)
    },

    async setProject({ commit, dispatch }, project) {
      const projectDetails = await MxaApi.get(url`projects/${project.id}`)
        .then(({ data }) => data)
        .catch(({ data }) => {
          throw data.title
        })

      merge(projectDetails, project)

      commit('setProject', projectDetails)
    },

    setXpBaseUrl({ commit }, xpBaseUrl) {
      commit('setXpBaseUrl', xpBaseUrl)
    }
  }
}
