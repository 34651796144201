<template>
  <div>
    <div v-for="lang in sortedLanguages" :key="lang">
      <device-message
        v-model="messages[lang]"
        :is-default="lang === defaultLang"
        :lang="lang"
        :profile-attributes="profileAttributes"
        @input="onChange"
      />
    </div>

    <div v-if="withVoucherCodes" class="form-group">
      <label>{{ $t('voucherCodeTypeLabel') }}:</label>
      <select-barcode-type
        v-model="voucher_code_enabled"
        @input="barcodeSettingChanged"
      />
    </div>

    <div v-if="voucher_code_enabled || 0 === 1" class="form-group">
      <label>{{ $t('voucherCodePositionLabel') }}:</label>
      <select-barcode-position
        v-model="voucher_code_position"
        @input="barcodeSettingChanged"
      />
    </div>

    <div v-if="webPush" class="form-group">
      <label>{{ $t('urlLabel') }}</label>
      <input v-model="url" type="text" class="form-control" @input="onChange" />
    </div>

    <div v-if="webPush" class="form-group">
      <label>{{ $t('iconLabel') }}:</label>
      <div style="display: flex">
        <label
          style="flex: 1; margin-right: 10px"
          class="btn btn-default btn-file"
          :class="{ disabled: processingImageUpload }"
        >
          {{ $t('uploadAnIconLabel') }}
          <input
            type="file"
            style="display: none;"
            @change="$emit('uploadIcon', $event)"
          />
        </label>
        <button
          style="flex: 1; margin-left: 10px"
          class="btn btn-default"
          :disabled="!iconUrl"
          @click="$emit('removeIconUrl')"
        >
          {{ $t('removeLabel') }}
        </button>
      </div>
    </div>

    <div class="form-group">
      <label>{{ $t('pictureVariantLabel') }}:</label>
      <my-select
        v-model="pictureVariant"
        :options="defaultPictureOptions"
        @input="pictureVariantChanged"
      />
    </div>

    <div v-if="pictureVariant !== 'none'" class="form-group">
      <label>{{ $t('pictureLabel') }}:</label>
      <div v-if="pictureVariant === 'url'">
        <div class="form-group" style="display: flex">
          <input
            type="text"
            class="form-control"
            :value="pictureUrl"
            @change="$emit('pictureURLChanged', $event)"
          />
        </div>
      </div>
      <div v-if="pictureVariant === 'upload'" style="display: flex">
        <label
          style="flex: 1; margin-right: 10px"
          class="btn btn-default btn-file"
          :class="{ disabled: processingImageUpload }"
        >
          {{ $t('uploadAPictureLabel') }}
          <input
            type="file"
            style="display: none;"
            @change="$emit('uploadPicture', $event)"
          />
        </label>
        <button
          style="flex: 1; margin-left: 10px"
          class="btn btn-default"
          :disabled="!pictureUrl"
          @click="$emit('removePictureUrl')"
        >
          {{ $t('removeLabel') }}
        </button>
      </div>
    </div>

    <div v-if="!webPush" class="form-group">
      <label>{{ $t('defaultActionLabel') }}:</label>
      <my-select
        v-model="defaultAction.type"
        :options="defaultActionOptions"
        @input="onChange"
      />
    </div>

    <div
      v-if="!webPush && ['url', 'deeplink'].includes(defaultAction.type)"
      class="form-group"
    >
      <label>{{ defaultActionLabel }}</label>
      <input
        v-model="defaultAction.value"
        type="text"
        class="form-control"
        @input="onChange"
      />
    </div>

    <div v-if="ios" class="form-group">
      <label>{{ $t('interruptionLevel') }}:</label>
      <my-select
        v-model="interruptionLevel"
        :options="interruptionLevelOptions"
        @input="onChange"
      />
    </div>

    <div v-if="!webPush" class="form-group">
      <label>{{ $t('payloadLabel') }}:</label>
      <div
        v-for="(payloadValue, index) in payload"
        :key="`${payloadValue.key}_${payloadValue.value}`"
        style="display: flex;margin-bottom: 20px"
      >
        <input
          v-model="payloadValue.key"
          type="text"
          class="form-control"
          style="flex:1;margin-right: 20px"
          :placeholder="$t('keyPlaceholderLabel')"
          @input="onChange"
        />

        <input
          v-model="payloadValue.value"
          type="text"
          class="form-control"
          style="flex:1;"
          :placeholder="$t('valuePlaceholderLabel')"
          @input="onChange"
        />

        <button
          class="close"
          style="margin: 0 20px"
          @click="removePayload(index)"
        >
          ×
        </button>
      </div>
      <button class="btn btn-default btn-block" @click="addPayload">
        {{ $t('addPayloadLabel') }}
      </button>
    </div>
  </div>
</template>
<script>
import ISO6391 from 'iso-639-1'
import MySelect from '@/components/Select'
import DeviceMessage from '@/components/DeviceMessage'
import SelectBarcodePosition from '@/components/SelectBarcodePosition'
import SelectBarcodeType from '@/components/SelectBarcodeType'

export default {
  components: {
    MySelect,
    DeviceMessage,
    SelectBarcodeType,
    SelectBarcodePosition
  },
  props: [
    'value',
    'defaultLang',
    'languages',
    'processingImageUpload',
    'profileAttributes',
    'webPush',
    'ios',
    'withVoucherCodes'
  ],

  data() {
    let data = JSON.parse(JSON.stringify(this.value))

    return Object.assign(
      {},
      {
        interruptionLevel: 1,
        pictureVariant: 'none',
        variantImages: {
          none: null,
          upload: null,
          url: null
        },
        voucher_code_enabled: 0,
        voucher_code_position: 0
      },
      data
    )
  },

  computed: {
    defaultPictureOptions() {
      return [
        {
          name: this.$t('devicePushOptionNone'),
          value: 'none'
        },
        {
          name: this.$t('devicePushPictureOptionUpload'),
          value: 'upload'
        },
        {
          name: this.$t('devicePushPictureOptionURL'),
          value: 'url'
        }
      ]
    },

    defaultActionOptions() {
      return [
        {
          name: this.$t('devicePushOptionNone'),
          value: 'none'
        },
        {
          name: this.$t('devicePushActionOptionGoToURL'),
          value: 'url'
        },
        {
          name: this.$t('devicePushActionOptionGoToDeeplink'),
          value: 'deeplink'
        }
      ]
      /**
       * to be added back eventually
       * {
       *    name: 'In-App Message',
       *    value: 'inapp'
       * }
       */
    },

    defaultActionLabel() {
      const mapping = {
        url: 'Open URL ',
        deeplink: 'Deeplink'
      }

      return mapping[this.defaultAction.type]
    },

    interruptionLevelOptions() {
      return [
        {
          name: this.$t('interruptionLevelPassive'),
          value: 0
        },
        {
          name: this.$t('interruptionLevelActive'),
          value: 1
        },
        {
          name: this.$t('interruptionLevelTimeSensitive'),
          value: 2
        },
        {
          name: this.$t('interruptionLevelCritical'),
          value: 3
        }
      ]
    },

    sortedLanguages() {
      const languages = Object.keys(this.messages)
        .filter(lang => lang !== this.defaultLang)
        .sort((lang1, lang2) =>
          ISO6391.getName(lang1).localeCompare(ISO6391.getName(lang2))
        )
      languages.unshift(this.defaultLang)

      return languages
    },

    currentPictureVariant() {
      if (this.pictureVariant === 'none') return null

      return this.variantImages[this.pictureVariant]
    }
  },

  watch: {
    languages: {
      immediate: true,
      handler(languages) {
        let changes = false
        languages.forEach(lang => {
          if (!this.messages[lang]) {
            this.$set(this.messages, lang, {
              title: '',
              text: ''
            })
            changes = true
          }
        })

        Object.keys(this.messages).forEach(lang => {
          if (!languages.includes(lang)) {
            this.$delete(this.messages, lang)
            changes = true
          }
        })

        if (changes) this.onChange()
      }
    },
    value: {
      deep: true,
      handler() {
        Object.assign(this.$data, JSON.parse(JSON.stringify(this.value)))
      }
    },
    pictureUrl: function(newValue) {
      this.variantImages[this.pictureVariant] = newValue
    }
  },

  methods: {
    pictureVariantChanged() {
      this.pictureUrl = this.currentPictureVariant
      this.$nextTick(function() {
        this.onChange()
      })
    },

    barcodeSettingChanged() {
      this.$nextTick(function() {
        this.onChange()
      })
    },

    onChange() {
      this.$nextTick(function() {
        this.$emit('input', JSON.parse(JSON.stringify(this.$data)))
      })
    },

    addPayload() {
      this.payload.push({
        key: '',
        value: ''
      })
      this.onChange()
    },

    removePayload(index) {
      this.$delete(this.payload, index)
      this.onChange()
    }
  }
}
</script>
