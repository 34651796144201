<template>
  <multiselect
    :value="objectValue"
    :placeholder="placeholder"
    track-by="code"
    :custom-label="customLabel"
    select-label=""
    selected-label=""
    deselect-label=""
    :options="options"
    :option-height="36"
    :searchable="true"
    :allow-empty="false"
    :disabled="disabled"
    @input="onChange"
  />
</template>
<script>
import ISO6391 from 'iso-639-1'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect
  },
  props: {
    value: {
      type: String
    },
    placeholder: {
      type: String,
      default: function () {
        return this.$t('selectALanguageLabel')
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    objectValue() {
      return this.options.find(option => option.code === this.value)
    },

    options() {
      return ISO6391.getLanguages(ISO6391.getAllCodes())
    }
  },

  methods: {
    customLabel(option) {
      return option.name
    },

    onChange({ code }) {
      this.$emit('input', code)
    }
  }
}
</script>
<style scoped>
.multiselect--active {
  z-index: 4
}

::v-deep .multiselect__select {
  height: 36px
}
</style>
