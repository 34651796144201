<template>
  <select>
    <slot></slot>
  </select>
</template>
<script>
import $ from 'jquery'
import 'select2'

export default {
  props: {
    value: {
      type: String
    },
    options: {
      type: Array
    },
    placeholder: {
      type: String
    },
    enableSearch: {
      type: Boolean,
      default: false
    },
    allowClear: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: 'resolve'
    }
  },

  watch: {
    value(value) {
      $(this.$el).val(value).trigger('change', { ignore: true })
    },
    options: 'reloadSelect2'
  },

  mounted() {
    this.initSelect2()
    $(this.$el).on('change', (ev, args) => {
      if (!(args && 'ignore' in args)) {
        this.$emit('input', $(this.$el).val())
      }
    })

    this.$nextTick(() => {
      $(this.$el).val(this.value).trigger('change', { ignore: true })
    })
  },

  methods: {
    initSelect2() {
      const select2 = $(this.$el).select2({
        theme: 'bootstrap',
        data: this.options,
        placeholder: this.placeholder,
        minimumResultsForSearch: this.enableSearch ? 1 : Infinity,
        allowClear: this.allowClear,
        width: this.width,
        templateSelection: (selected) => {
          if (selected.title) {
            return selected.title
          }

          return selected.text
        }
      })
      const classes = String(this.$el.classList)
        .split(' ')
        .filter((cls) => cls !== 'select2-hidden-accessible')
        .join(' ')
      select2.data('select2').$container.addClass(classes)
    },

    reloadSelect2() {
      // If the data changes, we empty the select box before re-render
      let select2 = $(this.$el)
      let select2Val = select2.val()

      // Empty the select and regenerate options
      select2.empty()
      this.initSelect2()

      //  If the previously selected option is still available, select it
      if (select2.find("option[value='" + select2Val + "']").length) {
        select2.val(select2Val)
      }
      select2.trigger('change')
    }
  },

  destroy() {
    $(this.$el).off().select2('destroy')
  }
}
</script>
<style lang="sass">
.select2-container--bootstrap .select2-results__options--nested .select2-results__option
    padding-left: 30px
</style>
