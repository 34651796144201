export default {
    methods: {
        updateActionSettingsFromWorkflow() {
            if (!this.workflow?.features?.voucher_codes) {
                let updated = false
                switch (this.action.type) {
                    case 'Sms': {
                        updated = this.updateSMSConfigFromWorkflow()
                        break
                    }
                    case 'Push': {
                        updated = this.updatePushConfigsFromWorkflow()
                        break
                    }
                    case 'Inbox': {
                        updated = this.updateInboxConfigFromWorkflow()
                        break
                    }
                    default: {
                        break
                    }
                }
                if (updated) {
                    this.updateAction()
                }
            }
        },

        updateSMSConfigFromWorkflow() {
            if (this?.action?.config?.voucher_code_enabled) {
                this.action.config.voucher_code_enabled = 0
                return true
            }

            return false
        },

        updatePushConfigsFromWorkflow() {
            let updated = false

            if (this?.action?.config?.android?.voucher_code_enabled) {
                this.action.config.android.voucher_code_enabled = 0
                updated = true
            }
            if (this?.action?.config?.ios?.voucher_code_enabled) {
                this.action.config.ios.voucher_code_enabled = 0
                updated = true
            }
            if (this?.action?.config?.web?.voucher_code_enabled) {
                this.action.config.web.voucher_code_enabled = 0
                updated = true
            }
            return updated
        },

        updateInboxConfigFromWorkflow() {
            if (this?.action?.config?.voucher_code_enabled) {
                this.action.config.voucher_code_enabled = 0
                return true
            }

            return false
        },

        updateAction() {
            this.workflowApi.updateAction(this.action.id, {'config':this.action.config});
        }
    }
}