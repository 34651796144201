<template>
  <router-view v-if="project" :key="project.id"></router-view>
</template>
<script>
export default {
  components: {},

  computed: {
    project() {
      return this.$store.state.app.project
    }
  }
}
</script>
