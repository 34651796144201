import { render, staticRenderFns } from "./SmsCounter.vue?vue&type=template&id=3fd13fe9&scoped=true&"
import script from "./SmsCounter.vue?vue&type=script&lang=js&"
export * from "./SmsCounter.vue?vue&type=script&lang=js&"
import style0 from "./SmsCounter.vue?vue&type=style&index=0&id=3fd13fe9&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fd13fe9",
  null
  
)

export default component.exports