<template>
  <div style="width: 296px;" class="message-container">
    <div class="title">
      <img class="icon" src="@/images/imessage.svg" />
      <span>{{ $t('messagesLabel') }}</span>
      <span class="pull-right">{{ $t('nowLabel') }}</span>
    </div>
    <div class="from-name">
      <span>{{ fromName || $t('fromNameHereLabel') }}</span>
    </div>
    <div class="content">
      <span>{{ formattedText || $t('yourMessageTextLabel') }}</span>
    </div>
    <div class="content">
      <span>{{ optOutMessage }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    fromName: {
      type: String
    },
    text: {
      type: String
    },
    unsubscribeUrl: {
      type: String
    },
    optOutMessage: {
      type: String
    },
    shortenUrl: {
      type: String
    }
  },

  computed: {
    formattedText() {
      let resultText = this.text

      if (this.shortenUrl && this.shortenUrl !== '') {
        resultText = resultText.replace(/(https?:\/\/[^\s]+)/g, this.shortenUrl)
      }

      if (this.unsubscribeUrl && !this.optOutMessage) {
        return resultText + '\n' + this.unsubscribeUrl
      }
      return resultText
    }
  }
}
</script>
<style lang="sass" scoped>
.message-container
    font-family: "Helvetica Neue",sans-serif
    font-size: 0.9em
    background-color: #ccc
    opacity: 0.9
    border-radius: 10px
    padding: 8px
    margin-left: 10px
    margin-right: 10px

    .title
        margin-bottom: 4px

        .icon
            width: 20px
            height: 20px
            border-radius: 4px

    .from-name
        font-weight: bold
    .content
        display: -webkit-box
        -webkit-box-orient: vertical
        -webkit-line-clamp: 4
        overflow: hidden
        text-overflow: ellipsis
        white-space: normal
</style>
