<template>
  <div>
    <div class="previews-container">
      <div class="side-icon">
        <img src="@/images/chrome.svg" />
      </div>
      <div
        class="preview-window"
        :class="{ expanded: isChromeExpanded }"
        @click="toggleChromeContent"
      >
        <div class="preview-column">
          <div class="preview-row">
            <div class="preview-header">
              <div class="header-icon">
                <img src="@/images/chrome_grey.svg" />
              </div>
              <div>Chrome</div>
              <div class="separator">
                |
              </div>
              <div class="url">
                {{ filteredUrl }}
              </div>
              <div v-show="showChromeExpand" class="expand-arrows">
                <div v-if="!isChromeExpanded" class="arrow-down" />
                <div v-else class="arrow-up" />
              </div>
            </div>
          </div>
          <div class="preview-row">
            <div class="preview-column text-content">
              <div v-if="title" class="title">
                {{ title }}
              </div>
              <div
                id="chrome-message"
                class="message"
                :class="{ expanded: isChromeExpanded }"
              >
                <span style="white-space: pre;">{{
                  message || $t('webPushYourMessageHere')
                }}</span>
              </div>
            </div>
            <div class="icon">
              <img v-if="iconUrl" :src="iconUrl" />
              <img v-else :src="companyLogo" />
            </div>
          </div>
          <div
            v-if="pictureUrl"
            class="picture"
            :class="{ expanded: isChromeExpanded }"
          >
            <img :src="pictureUrl" />
          </div>
        </div>
      </div>
    </div>
    <div class="previews-container">
      <div class="side-icon">
        <img src="@/images/firefox.svg" />
      </div>
      <div
        class="preview-window"
        :class="{ expanded: isFirefoxExpanded }"
        @click="toggleFirefoxContent"
      >
        <div class="preview-column">
          <div class="preview-row">
            <div class="preview-header">
              <div class="header-icon">
                <img src="@/images/firefox_grey.svg" />
              </div>
              <div>Firefox</div>
              <div class="separator">
                |
              </div>
              <div class="url">
                {{ filteredUrl }}
              </div>
              <div v-if="showFirefoxExpand" class="expand-arrows">
                <div v-if="!isFirefoxExpanded" class="arrow-down" />
                <div v-else class="arrow-up" />
              </div>
            </div>
          </div>
          <div class="preview-row">
            <div class="preview-column text-content">
              <div v-if="title" class="title">
                {{ title }}
              </div>
              <div
                id="firefox-message"
                class="message"
                :class="{ expanded: isFirefoxExpanded }"
              >
                <span style="white-space: pre;">{{
                  message || $t('webPushYourMessageHere')
                }}</span>
              </div>
            </div>
            <div class="icon">
              <img v-if="iconUrl" :src="iconUrl" />
              <img v-else :src="companyLogo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },

    message: {
      type: String,
      default: ''
    },

    iconUrl: {
      type: String
    },

    pictureUrl: {
      type: String
    },

    companyLogo: {
      type: String
    },

    url: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      isChromeExpanded: false,
      isFirefoxExpanded: false,
      componentLoaded: false
    }
  },

  computed: {
    filteredUrl() {
      return this.url.replace(/(^\w+:|^)\/\//, '')
    },

    showFirefoxExpand() {
      if (!this.componentLoaded) {
        return false
      }

      let messageBox = document.getElementById(`firefox-message`)
      if (messageBox && this.message.length > 0) {
        if (
          messageBox.scrollWidth > messageBox.clientWidth ||
          this.isFirefoxExpanded
        ) {
          return true
        }
      }

      return false
    },

    showChromeExpand() {
      if (!this.componentLoaded) {
        return false
      }

      let messageBox = document.getElementById(`chrome-message`)
      if (messageBox && this.message.length > 0) {
        if (
          messageBox.scrollWidth > messageBox.clientWidth ||
          this.isChromeExpanded
        ) {
          return true
        }
      }

      return !!this.pictureUrl
    }
  },

  mounted() {
    this.componentLoaded = true
  },
  methods: {
    toggleChromeContent() {
      if (this.showChromeExpand) {
        this.isChromeExpanded = !this.isChromeExpanded
      }
    },

    toggleFirefoxContent() {
      if (this.showFirefoxExpand) {
        this.isFirefoxExpanded = !this.isFirefoxExpanded
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.previews-container
    display: flex
    flex-direction: row
    margin: 20px 0
    min-height: 50px

    .side-icon
        height: 50px
        width: 55px
        margin: auto 0

        img
            height: 100%
            width: 100%

    .preview-window
        max-height: 100px
        width: 100%
        border: 0.5px solid #ddd
        overflow: hidden
        margin-left: 10px

        .preview-column
            display: flex
            flex-direction: column
            overflow: hidden
            text-overflow: ellipsis

            .preview-row
                display: flex
                flex-direction: row

                .preview-header
                    display: flex
                    flex-direction: row
                    width: 100%
                    color: #757575
                    margin: 5px 5px

                    .header-icon
                        width: 20px
                        height: 20px
                        margin: 0 5px

                        img
                            height: 100%
                            width: 100%

                    .separator
                        margin: 0 5px
                        font-size: 12px
                        line-height: 20px

                    .url
                        height: 20px
                        max-width: 250px
                        overflow: hidden
                        text-overflow: ellipsis
                        color: #707070

                    .arrow-down
                        margin-top: 7px
                        margin-left: 5px
                        width: 0
                        height: 0
                        border-left: 5px solid transparent
                        border-right: 5px solid transparent
                        border-top: 5px solid #757575

                    .arrow-up
                        margin-top: 7px
                        margin-left: 5px
                        width: 0
                        height: 0
                        border-left: 5px solid transparent
                        border-right: 5px solid transparent
                        border-bottom: 5px solid #757575

                    .expand-arrows:hover
                        cursor: pointer

                .text-content
                    display: flex
                    flex-direction: column
                    margin: 5px 10px
                    flex: 6

                    .title
                        font-weight: 500
                        white-space: nowrap
                        overflow: hidden
                        text-overflow: ellipsis

                    .message
                        overflow: hidden
                        text-overflow: ellipsis

                    .expanded
                        max-height: 80px
                        word-wrap: break-word
                        overflow: hidden
                        text-overflow: ellipsis

                .icon
                    flex: 1
                    width: 60px
                    height: 60px
                    margin-right: 5px
                    margin-bottom: 5px

                    img
                        height: 100%
                        width: 100%

            .picture
                width: 405px
                text-align: center
                padding-bottom: 2px
                display: none

                img
                    max-height: 240px
                    max-width: 100%

            .expanded
                display: unset

    .expanded
        max-height: 400px
</style>
