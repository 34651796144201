<template>
  <transition name="el-zoom-in-top" @after-leave="doDestroy">
    <div v-show="showPopper" class="el-color-dropdown">
      <div class="el-color-dropdown__main-wrapper">
        <hue-slider
          ref="hue"
          :color="color"
          vertical
          style="float: right"
        ></hue-slider>
        <sv-panel ref="sl" :color="color"></sv-panel>
      </div>
      <alpha-slider v-if="showAlpha" ref="alpha" :color="color"></alpha-slider>
      <predefine
        v-if="predefine"
        :color="color"
        :colors="predefine"
      ></predefine>
      <div class="el-color-dropdown__btns">
        <span class="el-color-dropdown__value">
          <el-input
            v-model="customInput"
            :validate-event="false"
            size="mini"
            @keyup.native.enter="handleConfirm"
            @blur="handleConfirm"
          ></el-input>
        </span>
        <el-button
          size="mini"
          type="text"
          class="el-color-dropdown__link-btn"
          @click="$emit('clear')"
        >
          Clear
        </el-button>
        <el-button
          plain
          size="mini"
          class="el-color-dropdown__btn"
          @click="confirmValue"
        >
          OK
        </el-button>
      </div>
    </div>
  </transition>
</template>

<script>
import SvPanel from 'element-ui/packages/color-picker/src/components/sv-panel'
import HueSlider from 'element-ui/packages/color-picker/src/components/hue-slider'
import AlphaSlider from 'element-ui/packages/color-picker/src/components/alpha-slider'
import Predefine from 'element-ui/packages/color-picker/src/components/predefine'
import Popper from 'element-ui/src/utils/vue-popper'
import Locale from 'element-ui/src/mixins/locale'
import ElInput from 'element-ui/packages/input'
import ElButton from 'element-ui/packages/button'

export default {
  name: 'ElColorPickerDropdown',

  components: {
    SvPanel,
    HueSlider,
    AlphaSlider,
    ElInput,
    ElButton,
    Predefine
  },

  mixins: [Popper, Locale],

  props: {
    color: {
      required: true
    },
    showAlpha: Boolean,
    predefine: Array
  },

  data() {
    return {
      customInput: ''
    }
  },

  computed: {
    currentColor() {
      const parent = this.$parent
      return !parent.value && !parent.showPanelColor ? '' : parent.color.value
    }
  },

  watch: {
    showPopper(val) {
      if (val === true) {
        this.$nextTick(() => {
          const { sl, hue, alpha } = this.$refs
          sl && sl.update()
          hue && hue.update()
          alpha && alpha.update()
        })
      }
    },

    currentColor: {
      immediate: true,
      handler(val) {
        this.customInput = val
      }
    }
  },

  mounted() {
    this.$parent.popperElm = this.popperElm = this.$el
    this.referenceElm = this.$parent.$el
  },

  methods: {
    confirmValue() {
      this.$emit('pick')
    },

    handleConfirm() {
      this.color.fromString(this.customInput)
    }
  }
}
</script>
