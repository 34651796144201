// @link https://github.com/danxexe/sms-counter/blob/master/sms_counter.js

const gsm7bitChars  = "@£$¥èéùìòÇ\\nØø\\rÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !\\\"#¤%&'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà",
    gsm7bitExChar = "\\^{}\\\\\\[~\\]|€",

    gsm7bitRegExp       = new RegExp("^[" + gsm7bitChars + "]*$"),
    gsm7bitExRegExp     = new RegExp("^[" + gsm7bitChars + gsm7bitExChar + "]*$"),
    gsm7bitExOnlyRegExp = new RegExp("^[\\" + gsm7bitExChar + "]*$"),

    GSM_7BIT    = 'GSM_7BIT',
    GSM_7BIT_EX = 'GSM_7BIT_EX',
    UCS_2       = 'UCS_2',

    messageLength = {
        GSM_7BIT: 160,
        GSM_7BIT_EX: 160,
        UCS_2: 70
    },

    multiMessageLength = {
        GSM_7BIT: 153,
        GSM_7BIT_EX: 153,
        UCS_2: 67
    };

class SmsCounter {

    constructor(shortenUrl = false) {
        this.shortenUrl = shortenUrl
    }

    count(text) {
        if (this.shortenUrl && this.shortenUrl !== '') {
            text = text.replace(/(https?:\/\/[^\s]+)/g, this.shortenUrl)
        }

        let encoding    = this.detectEncoding(text),
            length      = text.length,
            per_message = messageLength[encoding];

        if (encoding === GSM_7BIT_EX) {
            length += this.countGsm7bitEx(text);
        }

        if (length > per_message) {
            per_message = multiMessageLength[encoding];
        }

        let messages  = Math.ceil(length / per_message),
            remaining = (per_message * messages) - length;
        if(remaining === 0 && messages === 0){
            remaining = per_message;
        }

        return {
            encoding: encoding,
            length: length,
            per_message: per_message,
            remaining: remaining,
            messages: messages
        };
    }

    detectEncoding(text) {
        switch (false) {
            case text.match(gsm7bitRegExp) === null:
                return GSM_7BIT;
            case text.match(gsm7bitExRegExp) === null:
                return GSM_7BIT_EX;
            default:
                return UCS_2;
        }
    }

    countGsm7bitEx(text) {
        let chars = [], char;
        for (let i = 0; i < text.length; i++) {
            char = text[i];
            if (char.match(gsm7bitExOnlyRegExp) !== null) {
                chars.push(char);
            }
        }
        return chars.length;
    }
}

export default SmsCounter;
