<template>
  <div>
    <div class="chart-title" :class="titleClass">{{ title }}</div>
    <canvas ref="canvas" :width="width" :height="height"></canvas>
  </div>
</template>
<script>
import { Bar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Bar,

  mixins: [reactiveProp],
  props: {
    title: {
      type: String,
      default: function () {
        return this.$t('stackedBarChartYesOrNoTitle')
      }
    },
    titleClass: {},
    xLabel: {
      type: String,
      default: ''
    },
    yLabel: {
      type: String,
      default: function () {
        return this.$t('actionReportingPercentageChartLabel')
      }
    },
    width: {
      default: 1140,
      type: Number
    },
    height: {
      default: 500,
      type: Number
    }
  },

  data() {
    return {
      options: {
        tooltips: {
          callbacks: {
            label(tooltipItem, data) {
              const label = data.datasets[tooltipItem.datasetIndex].label
              return `${label}: ${tooltipItem.yLabel}%`
            }
          }
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: true
              },
              barPercentage: 1,
              ticks: {
                // moment.js L = Month numeral, day of month, year 09/04/1986 (based on locale)
                callback: (value) => value.format('L')
              },
              scaleLabel: {
                display: !!this.xLabel,
                fontStyle: 600,
                fontSize: 18,
                labelString: this.xLabel
              }
            }
          ],
          yAxes: [
            {
              stacked: true,
              gridLines: {
                display: true
              },
              ticks: {
                beginAtZero: true,
                callback: (val) => (val % 1 === 0 ? `${val}%` : '')
              },
              scaleLabel: {
                display: true,
                fontStyle: 600,
                fontSize: 18,
                labelString: this.yLabel
              }
            }
          ]
        },
        elements: {
          point: {
            radius: 0,
            hitRadius: 10,
            hoverRadius: 5
          }
        }
      }
    }
  },

  mounted() {
    this.renderChart(this.chartData, this.options)
  }
}
</script>
<style lang="sass" scoped>
.chart-title
    font-weight: 600
    font-size: 20px
</style>
