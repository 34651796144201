<template>
  <div>
    <label>{{ $t('delayToolboxSmallProfileAttributeLabel') }}</label>
    <profile-attributes
      :value="action.options.profileAttribute"
      :options="profileAttributes"
      :disabled="readOnly"
      @input="updateProfileAttribute"
    />
    <allowed-resume-window-section
      :action="action"
      :read-only="readOnly"
      @saveSafeTime="updateAllowedResumeWindow"
    />
  </div>
</template>
<script>
import ProfileAttributes from '@/components/ProfileAttributes'
import AllowedResumeWindowSection from './AllowedResumeWindowSection'

export default {
  components: {
    AllowedResumeWindowSection,
    ProfileAttributes
  },
  inject: ['workflowApi'],
  props: ['action', 'readOnly'],

  computed: {
    profileAttributes() {
      return this.$store.state.project.attributes
    }
  },

  methods: {
    saveChanges(changes) {
      this.workflowApi.updateAction(this.action.id, {
        options: Object.assign({}, this.action.options, changes)
      })
    },

    updateProfileAttribute(profileAttribute) {
      this.saveChanges({
        type: 'profileAttribute',
        profileAttribute
      })
    },

    updateAllowedResumeWindow(startTime, endTime) {
      this.saveChanges({
        startSafeTime: startTime,
        endSafeTime: endTime
      })
    }
  }
}
</script>
<style lang="sass" scoped></style>
