<template>
  <div style="padding: 20px">
    <label>{{ $t('actionReportingSmsDeliveryStats') }}</label>
    <div class="stats-bar">
      <div class="stats-bar-item">
        <icon class="stats-bar-item-icon" glyph="sms" />
        <div class="stats-bar-item-title">{{ $t('actionReportingSent') }}</div>
        <div class="stats-bar-item-number">
          {{ numberFilter(smsSentTotal) }}
          ({{ percentageOfFilter(smsSentTotal, smsArrivedTotal) }})
        </div>
      </div>
    </div>
    <hr />
    <date-line-chart
      :chart-data="chartData"
      :title="$t('actionReportingTotalNumberOfSmsSent')"
      title-class="action-color-messages"
      :yLabel="$t('actionReportingNumberOfProfiles')"
    />
  </div>
</template>
<script>
import TrendLineMixin from '@/libs/TrendLineMixin'
import DateLineChart from '@/components/DateLineChart'
import { numberFilter, percentageOfFilter } from '@/helpers/filters'

export default {
  props: [
    'smsArrivedTotal',
    'smsSentTotal',

    'dateRange',
    'smsSentStats',
    'smsUnsubscribesStats'
  ],

  mixins: [TrendLineMixin],

  components: {
    DateLineChart
  },

  data() {
    return {
      numberFilter,
      percentageOfFilter
    }
  },

  computed: {
    chartData() {
      return {
        labels: this.dateRange,
        datasets: [
          {
            label: this.$t('actionReportingStatsSentLabel'),
            backgroundColor: 'RGBA(77, 140, 192, 0.2)',
            borderColor: 'RGBA(77, 140, 192, 1)',
            borderWidth: 1,
            data: this.smsSentStats
          },
          {
            tooltip: false,
            fill: false,
            label: this.$t('actionReportingStatsTrendLineLabel'),
            data: this.getTrendLine(this.smsSentStats),
            backgroundColor: 'RGBA(77, 140, 192, 1)',
            borderColor: 'RGBA(77, 140, 192, 1)',
            spanGaps: true
          }
        ]
      }
    }
  }
}
</script>
