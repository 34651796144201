class TrendLineGenerator {
  constructor() {
    this.count = 0
    this.sumX = 0
    this.sumX2 = 0
    this.sumXY = 0
    this.sumY = 0
  }

  add(x, y) {
    this.count++
    this.sumX += x
    this.sumX2 += x * x
    this.sumXY += x * y
    this.sumY += y
  }

  project(x) {
    const det = this.count * this.sumX2 - this.sumX * this.sumX
    const offset = (this.sumX2 * this.sumY - this.sumX * this.sumXY) / det
    const scale = (this.count * this.sumXY - this.sumX * this.sumY) / det

    return offset + x * scale
  }

  getLine() {
    const line = []

    line.length = this.count
    line[0] = Math.max(0, this.project(0))
    line[line.length - 1] = Math.max(0, this.project(line.length - 1))

    return line
  }
}

export default TrendLineGenerator
