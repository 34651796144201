<template>
  <modal
    ref="modal"
    title="Import Errors"
    size="large"
    :show-cancel-button="false"
    @ok="$refs.modal.close()"
  >
    <p>Content successfully imported, but the following issues were found.</p>
    <ul class="list-unstyled">
      <li v-for="message in messages" class="message">
        <icon
          :glyph="$options.messageIcon[message.level]"
          :class="message.level"
        />
        {{ message.description }}
      </li>
    </ul>
  </modal>
</template>
<script>
import Modal from '@/components/Modal'

export default {
  components: {
    Modal
  },
  props: {
    messages: {
      type: Array,
      required: true
    }
  },

  messageIcon: {
    warning: 'warning-triangle',
    error: 'exclamation-mark-circle'
  }
}
</script>
<style lang="sass" scoped>
.message
    margin-bottom: 5px

.icon
    margin-right: 5px

.warning
    color: $brand-warning

.error
    color: $brand-danger
</style>
