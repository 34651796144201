<template>
  <div :class="{ 'tab-full-height': fullHeight }">
    <ul class="nav nav-tabs" :class="navClass">
      <li
        v-for="tab in orderedTabs"
        :key="tab.id"
        :class="{ active: tab.id === activeTab }"
        @click="activateTab(tab.id)"
      >
        <a :class="{ disabled: tab.disabled }" class="icon">
          <img v-if="tab.icon" :src="require('@/images/' + tab.icon + '.svg')" />
          <span v-if="tab.title">{{ tab.title }}</span>
        </a>
      </li>
    </ul>
    <div class="tab-content">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    startingTab: {
      type: String
    },
    navClass: {
      type: String,
      default: '' // example nav-pills, nav-stacked, nav-side
    },
    fullHeight: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      activeTab: this.startingTab,
      tabs: []
    }
  },

  computed: {
    orderedTabs() {
      return this.tabs.slice(0).sort((tab1, tab2) => {
        return tab1.position - tab2.position
      })
    }
  },

  methods: {
    addTab(tab) {
      this.tabs.push(tab.$props)
    },

    removeTab(id) {
      this.tabs = this.tabs.filter(tab => tab.id !== id)
      if (this.activeTab === id && this.tabs.length) {
        this.activeTab = this.tabs[0].id
      }
    },

    activateTab(id) {
      if (this.activeTab === id) return

      const tab = this.tabs.find(tab => tab.id === id)
      if (tab.disabled) {
        return
      }

      this.$emit('change-tab', id, this.activeTab)
      this.activeTab = id
      this.$nextTick(() => (this.$el.scrollTop = 0))
    }
  }
}
</script>
<style lang="sass" scoped>
.nav-tabs li a
    cursor: pointer
    &.disabled
        cursor: not-allowed
        *
            opacity: .3

    .tab-full-height
        height: 100%
        overflow-y: auto

    .icon
        display: inline-block
        width: 1em
        height: 1em

    .icon img
        position: relative
        bottom: -0.125em
</style>
