<template>
  <div style="height: 100%; overflow-y: scroll; padding: 30px">
    <label>{{ $t('emailStatsTitle') }}:</label>
    <div class="stats-grid">
      <div class="stats-grid-item">
        <icon class="stats-grid-item-icon" glyph="user" />
        <div class="stats-grid-item-title">
          {{ $t('emailStatsArrivedLabel') }}:
        </div>
        <div class="stats-grid-item-number">
          {{ numberFilter(arrivedTotal) }}
        </div>
      </div>
      <div class="stats-grid-item">
        <icon class="stats-grid-item-icon" glyph="email" />
        <div class="stats-grid-item-title">
          {{ $t('emailStatsSentLabel') }}:
        </div>
        <div class="stats-grid-item-number">
          {{ numberFilter(sentTotal) }}
          ({{ percentageOfFilter(sentTotal, arrivedTotal) }})
        </div>
      </div>
      <div class="stats-grid-item">
        <icon class="stats-grid-item-icon" glyph="inbox" />
        <div class="stats-grid-item-title">
          {{ $t('emailStatsDeliveredLabel') }}:
        </div>
        <div class="stats-grid-item-number">
          {{ numberFilter(deliveredTotal) }}
          ({{ percentageOfFilter(deliveredTotal, sentTotal) }})
        </div>
      </div>
      <div class="stats-grid-item">
        <icon class="stats-grid-item-icon" glyph="arrow-redirect" />
        <div class="stats-grid-item-title">
          {{ $t('emailStatsBouncedLabel') }}:
        </div>
        <div class="stats-grid-item-number">
          {{ numberFilter(bouncedTotal) }}
          ({{ percentageOfFilter(bouncedTotal, sentTotal) }})
        </div>
      </div>
      <div class="stats-grid-item">
        <icon class="stats-grid-item-icon" glyph="email-open" />
        <div class="stats-grid-item-title">
          {{ $t('emailStatsOpensLabel') }}:
        </div>
        <div class="stats-grid-item-number">
          {{ numberFilter(uniqueOpensTotal) }}
          <span v-if="uniqueOpensTotal !== 'N/A'">
            ({{ percentageOfFilter(uniqueOpensTotal, deliveredTotal) }})
          </span>
        </div>
      </div>
      <div class="stats-grid-item">
        <icon class="stats-grid-item-icon" glyph="mouse" />
        <div class="stats-grid-item-title">
          {{ $t('emailStatsClicksLabel') }}:
        </div>
        <div class="stats-grid-item-number">
          {{ numberFilter(uniqueClicksTotal) }}
          <span v-if="uniqueClicksTotal !== 'N/A'">
            ({{ percentageOfFilter(uniqueClicksTotal, deliveredTotal) }})
          </span>
        </div>
      </div>
      <div class="stats-grid-item">
        <icon class="stats-grid-item-icon" glyph="email-unsubscribe" />
        <div class="stats-grid-item-title">
          {{ $t('emailStatsUnsubscribesLabel') }}:
        </div>
        <div class="stats-grid-item-number">
          {{ numberFilter(unsubscribesTotal) }}
          ({{ percentageOfFilter(unsubscribesTotal, deliveredTotal) }})
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { numberFilter, percentageOfFilter } from '@/helpers/filters'

export default {
  props: [
    'arrivedTotal',
    'sentTotal',
    'deliveredTotal',
    'bouncedTotal',
    'uniqueOpensTotal',
    'uniqueClicksTotal',
    'unsubscribesTotal'
  ],

  data() {
    return {
      numberFilter,
      percentageOfFilter
    }
  }
}
</script>
