<template>
  <div ref="parent" class="bee-shopbox-plugin-wrapper">
    <el-container class="bee-shopbox-plugin">
      <div>
        <el-col>
          <el-row type="flex" justify="center" class="title" :gutter="0">
            {{
              isNewBlock
                ? 'Add Recommendation Block'
                : 'Edit Recommendation Block'
            }}
          </el-row>
          <el-divider />
          <el-row type="flex" justify="start" :gutter="0">
            <div class="flex-column">
              <span class="section-title">Recommendation Logic</span>
              <el-divider />
              <div class="flex-column">
                <el-row type="flex" :gutter="0">
                  <el-radio v-model="state.recommendationVariant" :label="1">
                    Product Viewed History
                  </el-radio>
                  <el-tooltip placement="top" effect="light">
                    <template #content>
                      <div>
                        Allows you to call a list of products a specific user has
                        previously viewed.
                      </div>
                    </template>
                    <i class="el-icon-question"></i>
                  </el-tooltip>
                </el-row>
                <el-row type="flex" :gutter="0">
                  <el-radio v-model="state.recommendationVariant" :label="2">
                    Find Similar
                  </el-radio>
                  <el-tooltip placement="top" effect="light">
                    <template #content>
                      <div>
                        This logic is only for event-triggered campaigns
                        <br />
                        Return products most relevant to the user based on a
                        specific product of interest.
                      </div>
                    </template>
                    <i class="el-icon-question"></i>
                  </el-tooltip>
                </el-row>
                <el-row type="flex" :gutter="0">
                  <el-radio v-model="state.recommendationVariant" :label="3">
                    Cross-Sell
                  </el-radio>
                  <el-tooltip placement="top" effect="light">
                    <template #content>
                      <div>
                        This logic is only for event-triggered campaigns
                        <br />
                        Returns a list of products that would make good companion
                        products.
                      </div>
                    </template>
                    <i class="el-icon-question"></i>
                  </el-tooltip>
                </el-row>
              </div>
              <bee-shopbox-plugin-product-viewed-history
                v-if="state.recommendationVariant === 1"
                v-model="state.settings.productViewedHistory"
              />
              <bee-shopbox-plugin-find-similar
                v-if="state.recommendationVariant === 2"
                v-model="state.settings.findSimilar"
              />
              <bee-shopbox-plugin-cross-sell
                v-if="state.recommendationVariant === 3"
                v-model="state.settings.crossSell"
              />
            </div>
          </el-row>
          <el-divider />
          <div class="flex-column">
            <span class="section-title">Optional Details</span>
            <el-divider />
            <el-row
              type="flex"
              class="variant"
              justify="space-between"
              :gutter="0"
            >
              <span class="options-title">Product Title</span>
              <el-switch
                v-model="state.includeProductTitle"
                :active-value="true"
                :inactive-value="false"
                active-color="#1d87c8"
              />
            </el-row>
            <el-divider v-if="state.includeProductTitle" />
            <div v-if="state.includeProductTitle" class="flex-column">
              <el-row
                type="flex"
                class="variant"
                justify="space-around"
                :gutter="0"
              >
                <div class="field">
                  <label class="option-label">Alignment</label>
                  <multiselect
                    v-model="state.productTitleAlignment"
                    :options="alignmentVariants"
                    :custom-label="selectLabel"
                    select-label=""
                    selected-label=""
                    deselect-label=""
                    :option-height="36"
                    :searchable="false"
                    :allow-empty="false"
                    :multiple="false"
                  />
                </div>
                <div class="field">
                  <label class="option-label">Color</label>
                  <color-picker-field
                    v-model="state.titleColor"
                    :is-form-element="false"
                    label-text
                    :append-to-body="false"
                    :boundaries-element="$refs.parent"
                  />
                </div>
              </el-row>
            </div>
            <el-divider />
            <el-row
              type="flex"
              class="variant"
              justify="space-between"
              :gutter="0"
            >
              <span class="options-title">Product Price</span>
              <el-switch
                v-model="state.includeProductPrice"
                :active-value="true"
                :inactive-value="false"
                active-color="#1d87c8"
              />
            </el-row>
            <el-divider v-if="state.includeProductPrice" />
            <div v-if="state.includeProductPrice" class="flex-column">
              <el-row
                type="flex"
                class="variant"
                justify="space-around"
                :gutter="0"
              >
                <div class="field">
                  <label class="option-label">Alignment</label>
                  <multiselect
                    v-model="state.productPriceAlignment"
                    :options="alignmentVariants"
                    :custom-label="selectLabel"
                    select-label=""
                    selected-label=""
                    deselect-label=""
                    :option-height="36"
                    :searchable="false"
                    :allow-empty="false"
                    :multiple="false"
                  />
                </div>
                <div class="field">
                  <label class="option-label">Color</label>
                  <color-picker-field
                    v-model="state.priceColor"
                    :is-form-element="false"
                    label-text
                    :append-to-body="false"
                    :boundaries-element="$refs.parent"
                  />
                </div>
              </el-row>
            </div>
            <el-divider />
            <el-row
              type="flex"
              class="variant"
              justify="space-between"
              :gutter="0"
            >
              <span class="options-title">Product Link Button</span>
              <el-switch
                v-model="state.includeProductLinkButton"
                :active-value="true"
                :inactive-value="false"
                active-color="#1d87c8"
              />
            </el-row>
            <el-divider v-if="state.includeProductLinkButton" />
            <div
              v-if="state.includeProductLinkButton"
              class="product-link-button-section"
            >
              <div class="field">
                <label class="option-label">Button title</label>
                <redactor
                  v-model="state.button"
                  :plugins="redactorPlugins"
                ></redactor>
              </div>
              <div class="field">
                <label class="option-label">Color</label>
                <color-picker-field
                  v-model="state.buttonColor"
                  :is-form-element="false"
                  label-text
                  :append-to-body="false"
                  :boundaries-element="$refs.parent"
                />
              </div>
            </div>
          </div>
        </el-col>
      </div>
    </el-container>
    <div class="footer">
      <el-divider />
      <el-row type="flex" justify="space-between" :gutter="0">
        <el-button @click="cancel">Cancel</el-button>
        <el-button type="primary" @click="save">Save</el-button>
      </el-row>
    </div>
  </div>
</template>

<script>
import ColorPickerField from '@/components/ColorPickerField'
import BeeShopboxPluginProductViewedHistory from '@/components/AddOns/ProductRecommendations/BeeShopboxPluginProductViewedHistory'
import BeeShopboxPluginFindSimilar from '@/components/AddOns/ProductRecommendations/BeeShopboxPluginFindSimilar'
import BeeShopboxPluginCrossSell from '@/components/AddOns/ProductRecommendations/BeeShopboxPluginCrossSell'
import Redactor from '@/components/Redactor'
import { cloneDeep } from 'lodash'

export default {
  name: 'BeeShopboxPlugin',

  components: {
    BeeShopboxPluginProductViewedHistory,
    BeeShopboxPluginFindSimilar,
    BeeShopboxPluginCrossSell,
    Redactor,
    ColorPickerField
  },

  props: {
    resolve: {
      type: Function,
      required: true
    },
    reject: {
      type: Function,
      required: true
    },
    currentState: {
      type: Object
    }
  },

  data: function () {
    return {
      projectID: undefined,
      isNewBlock: true,
      contentRE: /^<p>(.+)<\/p>$/,
      redactorPlugins: ['fontcolor'],
      state: {
        recommendationVariant: 1,
        settings: {
          productViewedHistory: {
            productsCount: 3,
            page: 1
          },
          findSimilar: {
            productsCount: 3
          },
          crossSell: {
            productsCount: 3,
            page: 1
          }
        },
        includeProductTitle: true,
        productTitleAlignment: 'left',
        titleColor: '#1d87c8',
        includeProductPrice: true,
        productPriceAlignment: 'left',
        priceColor: '#1d87c8',
        includeProductLinkButton: true,
        button: '',
        buttonColor: '#1d87c8'
      },
      alignmentVariants: ['left', 'center', 'right']
    }
  },

  computed: {
    recommendationType() {
      switch (this.state.recommendationVariant) {
        case 1: {
          return 'viewedhistory'
        }
        case 2: {
          return 'findsimilar'
        }
        case 3: {
          return 'crosssell'
        }
      }
    },
    recommendationParameters() {
      switch (this.state.recommendationVariant) {
        case 1: {
          return `{
            num_products: ${this.state.settings.productViewedHistory.productsCount},
            page: ${this.state.settings.productViewedHistory.page}
          }`
        }
        case 2: {
          return `{
            num_products: ${this.state.settings.findSimilar.productsCount}
          }`
        }
        case 3: {
          return `{
            num_products: ${this.state.settings.crossSell.productsCount},
            page: ${this.state.settings.crossSell.page}
          }`
        }
      }
    },
    productsCount() {
      switch (this.state.recommendationVariant) {
        case 1: {
          return this.state.settings.productViewedHistory.productsCount
        }
        case 2: {
          return this.state.settings.findSimilar.productsCount
        }
        case 3: {
          return this.state.settings.crossSell.productsCount
        }
      }
    },
    buttonStyles() {
      return `display: inline-block; text-decoration: none; padding: 15px 25px; border-radius: 5px; background-color: ${this.state.buttonColor};`
    },
    buttonContent() {
      if (this.contentRE.test(this.state.button)) {
        const match = this.contentRE.exec(this.state.button)
        return match[1]
      } else {
        return this.state.button
      }
    }
  },

  mounted() {
    if (Object.keys(this.currentState).length > 0) {
      this.updateState(this.currentState)
    }
  },

  methods: {
    selectLabel(value) {
      if (!value) return

      return value.charAt(0).toUpperCase() + value.slice(1)
    },
    updateState(state) {
      this.isNewBlock = false
      this.state = cloneDeep(state)
    },
    save() {
      const resultContent = this.generateContent()
      this.resolve({
        type: 'html',
        value: {
          html: resultContent
        }
      })
      this.$vuedals.close('bee-shopbox-plugin-modal')
    },
    cancel() {
      this.reject('Editing has been canceled')
      this.$vuedals.close('bee-shopbox-plugin-modal')
    },
    generateContent() {
      return `<div data-plugin-state="${encodeURIComponent(
        JSON.stringify(this.state)
      )}">${this.twigWrapper()}</div>`
    },
    twigWrapper() {
      return `{% set products = recommendations("${this.recommendationType}", ${
        this.recommendationParameters
      }) %}
      {% set productsCount = products|length %}
      {% if productsCount > 0 %}
      {% set columnWidth = ((100 - (5 * (productsCount - 1)))/productsCount) | round(2) %}
      ${this.twigContent()}
      {% endif %}`
    },
    twigContent() {
      let content = '<table style="width: 100%; text-align: center;"><tbody>'

      content += this.imagesRow()

      if (this.state.includeProductTitle) {
        content += this.titlesRow()
      }

      if (this.state.includeProductPrice) {
        content += this.pricesRow()
      }

      if (this.state.includeProductLinkButton) {
        content += this.buttonsRow()
      }

      content += '</tbody></table>'

      return content
    },
    imagesRow() {
      let content = '<tr>'

      for (let i = 0; i < this.productsCount; i++) {
        content += `{% if products[${i}] %}<td style="width: {{ columnWidth }}%;">`

        content += `<img width="150" src="{{ products[${i}].imageUrl }}" onerror='this.src ="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJYAAACWCAYAAAA8AXHiAAAAAXNSR0IArs4c6QAAAqpJREFUeF7t0kENACAQA0Hwb/YcQIIG9jcV0Mdm9sycZQp8LrDB+lzU3SsAFghJAbCSrE7BYiApAFaS1SlYDCQFwEqyOgWLgaQAWElWp2AxkBQAK8nqFCwGkgJgJVmdgsVAUgCsJKtTsBhICoCVZHUKFgNJAbCSrE7BYiApAFaS1SlYDCQFwEqyOgWLgaQAWElWp2AxkBQAK8nqFCwGkgJgJVmdgsVAUgCsJKtTsBhICoCVZHUKFgNJAbCSrE7BYiApAFaS1SlYDCQFwEqyOgWLgaQAWElWp2AxkBQAK8nqFCwGkgJgJVmdgsVAUgCsJKtTsBhICoCVZHUKFgNJAbCSrE7BYiApAFaS1SlYDCQFwEqyOgWLgaQAWElWp2AxkBQAK8nqFCwGkgJgJVmdgsVAUgCsJKtTsBhICoCVZHUKFgNJAbCSrE7BYiApAFaS1SlYDCQFwEqyOgWLgaQAWElWp2AxkBQAK8nqFCwGkgJgJVmdgsVAUgCsJKtTsBhICoCVZHUKFgNJAbCSrE7BYiApAFaS1SlYDCQFwEqyOgWLgaQAWElWp2AxkBQAK8nqFCwGkgJgJVmdgsVAUgCsJKtTsBhICoCVZHUKFgNJAbCSrE7BYiApAFaS1SlYDCQFwEqyOgWLgaQAWElWp2AxkBQAK8nqFCwGkgJgJVmdgsVAUgCsJKtTsBhICoCVZHUKFgNJAbCSrE7BYiApAFaS1SlYDCQFwEqyOgWLgaQAWElWp2AxkBQAK8nqFCwGkgJgJVmdgsVAUgCsJKtTsBhICoCVZHUKFgNJAbCSrE7BYiApAFaS1SlYDCQFwEqyOgWLgaQAWElWp2AxkBQAK8nqFCwGkgJgJVmdgsVAUgCsJKtTsBhICoCVZHUKFgNJAbCSrE7BYiApAFaS1ekFrSg7/4n7r/kAAAAASUVORK5CYII="' />`

        content += '</td>'
        if (i < this.productsCount - 1) {
          content += '<td style="width:5%;"></td>'
        }

        content += '{% endif %}'
      }

      content += '</tr>'

      return content
    },
    titlesRow() {
      let content = '<tr>'

      for (let i = 0; i < this.productsCount; i++) {
        content += `{% if products[${i}] %}<td style="width: {{ columnWidth }}%;">`

        content += `<p style="text-align: ${this.state.productTitleAlignment}; color: ${this.state.titleColor};">{{ products[${i}].name }}</p>`

        content += '</td>'
        if (i < this.productsCount - 1) {
          content += '<td style="width:5%;"></td>'
        }

        content += '{% endif %}'
      }

      content += '</tr>'

      return content
    },
    pricesRow() {
      let content = '<tr>'

      for (let i = 0; i < this.productsCount; i++) {
        content += `{% if products[${i}] %}<td style="width: {{ columnWidth }}%;">`

        content += `<p style="text-align: ${this.state.productPriceAlignment}; color: ${this.state.priceColor};">{{ products[${i}].price }}</p>`

        content += '</td>'
        if (i < this.productsCount - 1) {
          content += '<td style="width:5%;"></td>'
        }

        content += '{% endif %}'
      }

      content += '</tr>'

      return content
    },
    buttonsRow() {
      let content = '<tr>'

      for (let i = 0; i < this.productsCount; i++) {
        content += `{% if products[${i}] %}<td style="width: {{ columnWidth }}%;">`

        content += `<a href="{{ products[${i}].url }}" style="${this.buttonStyles}">${this.buttonContent}</a>`

        content += '</td>'
        if (i < this.productsCount - 1) {
          content += '<td style="width:5%;"></td>'
        }

        content += '{% endif %}'
      }

      content += '</tr>'

      return content
    }
  }
}
</script>

<style scoped>
.bee-shopbox-plugin-wrapper {
  display: grid;
  grid-template-rows: 1fr 65px;
  max-height: calc(85vh - 40px);
}

.bee-shopbox-plugin {
  font-family: 'Open Sans', sans-serif;
  flex-direction: column;
  margin: 10px 0;
  padding: 10px 15px;
  overflow-y: auto;
  overflow-x: hidden;
}

.footer {
  bottom: 0;
  width: calc(100% - 40px);
  padding: 0 0px 20px;
  background: white;
  z-index: 1000;
  margin: 0 auto;
}

.footer .el-divider {
  margin-top: 0;
}

.title {
  font-size: 20px;
  text-align: center;
}

.section-title {
  font-size: 20px;
}

.flex-column {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.options-title {
  font-size: 16px;
}

.notification {
  height: 100%;
  justify-content: center;
}

>>> label.el-radio {
  align-items: center;
}

>>> label {
  display: flex;
  margin-bottom: 5px;
}

>>> .field {
  margin: 5px 0;
  flex: 0 0 40%;
}

>>> .el-divider--horizontal {
  margin: 14px 0;
}

>>> .el-radio__label {
  font-size: 18px;
}

>>> .el-switch__label > span {
  font-size: 16px;
}

>>> span,
>>> label {
  text-transform: none !important;
}

>>> .redactor-layer {
  padding: 10px;
}

.option-label {
  font-size: 14px;
}

.product-link-button-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.product-link-button-section .field {
  flex: 0 0 90%;
}

>>> .is-checked > .el-radio__inner,
>>> .el-button--primary {
  border-color: #1d87c8;
  background: #1d87c8;
}

>>> .is-checked > .el-radio__label {
  color: #1d87c8;
}

>>> .el-select .el-input.is-focus .el-input__inner {
  border-color: #1d87c8;
}

>>> .el-input--small .el-input__inner {
  height: 32px;
  line-height: 32px;
}

>>> .color-picker-ct .el-input__inner {
  border: none !important;
  height: 34px !important;
}
</style>

<style>
html,
body {
  margin: 0;
}

.el-select-dropdown__item.selected {
  color: #1d87c8;
}

.el-select-dropdown.el-popper {
  top: unset !important;
  left: unset !important;
}

.icon-block:before {
  content: '\E6DB';
}

[class^='icon-'],
[class*=' icon-'] {
  font-family: element-icons !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}
.colorpicker[id^='collorpicker_'] {
  top: unset !important;
  left: unset !important;
}
</style>
