<template>
  <div>
    <span>{{ $t('decisionCriteriaCurrentDateOptionLabel') }}</span>
    <span>{{ negatorText }}</span>
    <span :is="conditionCmp" :condition-args="conditionArgs"></span>
    <span>in {{ formatTimezone }}</span>
  </div>
</template>
<script>
import MissingCondition from './MissingCondition'

export default {
  props: ['negator', 'condition', 'conditionArgs'],

  computed: {
    negatorText: function () {
      return !this.negator ? 'is' : 'is not'
    },

    formatTimezone: function () {
      if (
        this.conditionArgs.timezone === 'profile' ||
        this.conditionArgs.timezone === ''
      ) {
        return 'Profile Timezone'
      }

      return this.conditionArgs.timezone + ' timezone'
    },

    conditionCmp() {
      try {
        return require(`./Profile/${this.condition}`).default
      } catch (e) {}

      return MissingCondition
    }
  }
}
</script>
