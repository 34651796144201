<template>
  <input
    :value="value"
    :placeholder="$t('noMessageAvailableLabel')"
    :allow-empty="false"
    disabled="true"
    readonly
    type="text"
    class="form-control"
  />
</template>
<script>
export default {
  props: {
    value: {
      required: true
    }
  }
}
</script>
