<template>
  <div style="margin: 20px">
    <div class="form-group">
      <label class="control-label">
        {{ $t('delayToolboxSmallDelayDelayBy') }}
      </label>
      <multiselect
        :value="optionsType"
        :options="options"
        :placeholder="$t('adRetargetToolboxSmallOptionSelectPlaceholder')"
        :select-label="$t('vueMultiSelectSelectLabel')"
        :selected-label="$t('vueMultiSelectSelectedLabel')"
        :deselect-label="$t('vueMultiSelectDeselectLabel')"
        :deselect-title="$t('vueMultiSelectDeselectTitle')"
        :custom-label="optionLabels"
        style="z-index: 3"
        :disabled="readOnly"
        @input="saveType"
      />
    </div>
    <component
      :is="action.options.type"
      :action="action"
      :read-only="readOnly"
    />
  </div>
</template>
<script>
import ProfileAttribute from './Delay/ProfileAttribute'
import RelativeDate from './Delay/RelativeDate'
import SpecificDate from './Delay/SpecificDate'
import TimePeriod from './Delay/TimePeriod'

export default {
  components: {
    ProfileAttribute,
    RelativeDate,
    SpecificDate,
    TimePeriod
  },
  inject: ['workflowApi'],
  props: ['action', 'readOnly'],

  computed: {
    options() {
      return [
        {name: this.$t('delayToolboxSmallProfileAttribute'), value: 'profileAttribute'},
        {name: this.$t('delayToolboxSmallRelativeDate'), value: 'relativeDate'},
        {name: this.$t('delayToolboxSmallSpecificDate'), value: 'specificDate'},
        {name: this.$t('delayToolboxSmallTimePeriod'), value: 'timePeriod'}
      ]
    },

    optionsType() {
      let type = this.action.options.type

      switch (type) {
        case 'profileAttribute':
          return {name: this.$t('delayToolboxSmallProfileAttribute'), value: type}

        case 'relativeDate':
          return {name: this.$t('delayToolboxSmallRelativeDate'), value: type}

        case 'specificDate':
          return {name: this.$t('delayToolboxSmallSpecificDate'), value: type}

        case 'timePeriod':
          return {name: this.$t('delayToolboxSmallTimePeriod'), value: type}
      }
    }
  },

  created() {
    this.$store.dispatch('fetchAttributes')
  },

  methods: {
    optionLabels(option) {
      return `${option.name}`
    },

    saveType(type) {
      let options = {
        type: type.value
      }

      switch (options.type) {
        case 'profileAttribute':
          Object.assign(options, {
            profileAttribute: ''
          })
          break

        case 'relativeDate':
          Object.assign(options, {
            interval: 'P1D',
            beforeOrAfter: 'after',
            relativeTo: 'profileAttribute',
            profileAttribute: '',
            eventAttribute: ''
          })
          break

        case 'specificDate':
          Object.assign(options, {
            useTime: false,
            delayUntil: ''
          })
          break

        case 'timePeriod':
          Object.assign(options, {
            interval: 'P1D'
          })
          break
      }

      this.workflowApi.updateAction(this.action.id, {options})
    }
  }
}
</script>
