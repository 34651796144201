<template>
  <modal
    ref="modal"
    title="Save Template"
    v-bind="{ disabled, closeable }"
    ok-text="Create"
    @ok="createTemplate"
  >
    <input
      v-model="name"
      autofocus
      type="text"
      class="form-control"
      placeholder="enter name"
      @keypress.enter="createTemplate"
    />
  </modal>
</template>
<script>
import Modal from '@/components/Modal'
import * as Toastr from 'toastr'
import XpApi from '@/libs/XpApi'

export default {
  components: {
    Modal
  },
  props: {
    projectId: {
      type: String,
      required: true
    },
    template: {
      type: String,
      required: true
    },
    html: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      closeable: true,
      disabled: false,
      name: ''
    }
  },

  methods: {
    createTemplate() {
      if (this.disabled) return

      this.disabled = true
      this.closeable = false

      XpApi.post(`projects/${this.projectId}/campaign-templates`, {
        title: this.name,
        message: {
          email_template: this.template,
          email_html: this.html,
          message_type: 7
        },
        message_type: 7,
        is_active: 1
      })
        .then(() => {
          this.onSuccess('Template created successfully')
        })
        .catch((error) => {
          this.disabled = false
          this.closeable = true
          Toastr.error(error.data.title)
          console.error(error)
        })
    },

    onSuccess(message) {
      Toastr.info(message)
      this.$store.dispatch('fetchBuilderTemplates')
      this.$refs.modal.close()
    }
  }
}
</script>
