import LoginCallback from '@/views/LoginCallback'
import ProjectLayout from '@/views/ProjectLayout'

import ProjectAdmin from '@/views/ProjectAdmin'
import ProjectAdminProfiles from '@/views/ProjectAdmin/Profiles'
import ProjectAdminAuditLog from '@/views/ProjectAdmin/AuditLog'
import ProjectAdminProfileActivity from '@/views/ProjectAdmin/ProfileActivity.vue'

import WorkflowEdit from '@/views/WorkflowEdit'
import NotFound from '@/views/NotFound'
import PermissionDenied from '@/views/PermissionDenied'

import appConfig from '@/config/config'

const routes = [
  {
    path: '/',
    beforeEnter: () => {
      window.location = appConfig.xpBaseUrl
    }
  },
  {
    path: '/login/callback',
    component: LoginCallback
  },
  {
    name: 'Project',
    path: '/project/:projectId',
    props: true,
    meta: { auth: true },
    component: ProjectLayout,
    redirect: {
      name: 'NotFound'
    },
    children: [
      {
        name: 'ProjectAdmin',
        path: 'admin',
        component: ProjectAdmin,
        props: true,
        redirect: {
          name: 'ProjectAdminProfiles'
        },
        children: [
          {
            name: 'ProjectAdminProfiles',
            path: 'profiles',
            component: ProjectAdminProfiles,
            props: true
          },
          {
            name: 'ProjectAdminAuditLog',
            path: 'log',
            component: ProjectAdminAuditLog,
            props: true
          },
          {
            name: 'ProjectAdminProfileActivity',
            path: 'profiles/:profileId/activity',
            component: ProjectAdminProfileActivity,
            props: true,
          },
          {
            name: 'ProjectAdminProfileActivityByCampaign',
            path: 'profiles/:profileId/activity/c:campaignId',
            component: ProjectAdminProfileActivity,
            props: true,
          },
          {
            name: 'ProjectAdminProfileActivityByWorkflow',
            path: 'profiles/:profileId/activity/wf:workflowId',
            component: ProjectAdminProfileActivity,
            props: true,
          }
        ]
      },
      {
        name: 'WorkflowEdit',
        path: 'workflow/:workflowId',
        component: WorkflowEdit,
        props: true
      },
      {
        name: 'WorkflowPreview',
        path: 'workflow/:workflowId/preview',
        component: WorkflowEdit,
        props: (route) => {
          return {
            projectId: route.params.projectId,
            workflowId: route.params.workflowId,
            isPreview: true
          }
        }
      }
    ]
  },
  {
    name: 'NotFound',
    path: '/not-found',
    component: NotFound
  },
  {
    name: 'PermissionDenied',
    path: '/permission-denied',
    component: PermissionDenied
  },
  {
    path: '*',
    component: NotFound
  }
]

export default routes
