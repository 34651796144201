<template>
  <span>
    {{ $t('toolboxSmallProfileANumberLessThan') }}
    <span v-if="isNumeric(conditionArgs.value)" class="criteria-condition-args">
      '{{ conditionArgs.value }}'
    </span>
    <span v-else class="invalid-text">{{ $t('toolboxSmallProfileMissingNumber') }}</span>
  </span>
</template>
<script>
export default {
  props: ['conditionArgs'],

  methods: {
    isNumeric(value) {
      return !isNaN(parseFloat(value)) && isFinite(value)
    }
  }
}
</script>
