import moment from 'moment'

export default {
  methods: {
    getTotal(statName) {
      if (!this.stats[statName]) {
        return 0
      }

      return Object.values(this.stats[statName]).reduce(
        (accumulator, currentValue) => accumulator + currentValue
      )
    },

    getStats(statName) {
      const stats = []

      this.dateRange.forEach((date) => {
        let count = 0

        const dateString = date.format('YYYY-MM-DD')
        if (this.stats[statName] && this.stats[statName][dateString]) {
          count = this.stats[statName][dateString]
        }

        stats.push(count)
      })

      return stats
    },

    getCombinedStats(...statNames) {
      const statsResult = []

      statNames.forEach((statName) => {
        const stats = this.getStats(statName)

        if (statsResult.length !== stats.length) {
          statsResult.length = stats.length
          statsResult.fill(0)
        }

        stats.forEach((value, idx) => (statsResult[idx] += value))
      })

      return statsResult
    }
  },

  beforeCreate() {
    if (this.$options.totalsMapping) {
      const totalsMapping = this.$options.totalsMapping
      Object.keys(totalsMapping).forEach((key) => {
        const statName = totalsMapping[key]
        this.$options.computed[key] = function () {
          return this.getTotal(statName)
        }
      })
    }

    if (this.$options.statsMapping) {
      const statsMapping = this.$options.statsMapping
      Object.keys(statsMapping).forEach((key) => {
        const statName = statsMapping[key]
        this.$options.computed[key] = function () {
          return this.getStats(statName)
        }
      })
    }
  },

  computed: {
    startDate() {
      let startDate = moment()
      Object.keys(this.stats).forEach((statName) => {
        Object.keys(this.stats[statName]).forEach((statDate) => {
          statDate = moment(statDate, 'YYYY-MM-DD')

          if (statDate < startDate) {
            startDate = statDate
          }
        })
      })

      return startDate
    },

    dateRange() {
      let today = moment(),
        dateCompare = this.startDate.clone()
      const dateRange = []

      while (dateCompare < today) {
        dateRange.push(dateCompare.clone())
        dateCompare.add(1, 'days')
      }

      return dateRange
    }
  }
}
