import $ from 'jquery'
import 'bootstrap'

export default {
  bind(el, binding) {
    $(el)
      .attr('data-toggle', 'tooltip')
      .attr('data-placement', binding.arg)
      .attr('data-container', 'body')
      .attr('data-selector', true)
      .attr('trigger', 'hover')
      .attr('title', binding.value)
      .tooltip()
      .click(function () {
        $(this).tooltip('hide')
      })
  },

  update(el, binding) {
    $(el).attr('title', binding.value).tooltip()
  },

  unbind(el) {
    $(el).tooltip('dispose')
  }
}
