export default {
  update(el, binding) {
    if (!el.hasAttribute('data-original-text')) {
      el.setAttribute('data-original-text', el.textContent)
    }

    if (binding.value) {
      el.textContent = el.getAttribute('data-loading-text')
      el.setAttribute('disabled', 'disabled')
    } else {
      el.textContent = el.getAttribute('data-original-text')
      el.removeAttribute('disabled')
    }
  }
}
