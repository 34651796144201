import loadScript from 'load-script'

const URL = 'https://cdn.ckeditor.com/4.8.0/standard/ckeditor.js'

let loader

export default {
  load: () => {
    if (!loader) {
      loader = new Promise((resolve, reject) => {
        loadScript(URL, (err) => {
          if (err) reject(err)
          resolve(window.CKEDITOR)
        })
      })
    }

    return loader
  }
}
