<template>
  <div :class="device" style="width: 296px">
    <div class="header">
      <img v-show="companyLogo" :src="companyLogo" />
      <div class="company-name">
        {{ companyName }}
      </div>
      <div class="separator">|</div>
      <div class="notification-time" @click="toggleContent">
        <span
          v-if="isContentExpanded && isIos"
          style="font-size: 16px; font-weight: bold"
        >
          ×
        </span>
        <span v-else>{{ $t('nowLabel') }}</span>
      </div>
      <div v-if="!isIos && showExpand">
        <span
          v-show="!isContentExpanded"
          class="expand-arrow"
          @click="toggleContent"
        >
          &#x25BC;
        </span>
        <span
          v-show="isContentExpanded"
          class="expand-arrow"
          @click="toggleContent"
        >
          &#x25B2;
        </span>
      </div>
    </div>
    <div class="content" @click="toggleContent">
      <div class="column" :class="{ contentExpanded: isContentExpanded }">
        <div v-show="title.length > 0" class="title">
          <span>{{ title }}</span>
        </div>
        <div class="message" :class="{ expanded: isContentExpanded }">
          <span id="content" style="white-space: pre">
            {{ message || $t('yourMessageTextLabel') }}
          </span>
        </div>
        <div v-show="isContentExpanded && picture" class="picture">
          <img :src="picture" />
        </div>
        <div v-if="isIos && showExpand && !isContentExpanded" class="see-more">
          {{ $t('pressForMoreLabel') }}
        </div>
      </div>
      <div v-show="picture && !isContentExpanded" class="row">
        <div class="picture-preview">
          <img :src="picture" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    device: {
      type: String,
      required: true,
      validator: (value) => value === 'ios' || value === 'android'
    },
    companyName: {
      type: String
    },
    companyLogo: {
      type: String
    },
    title: {
      type: String
    },
    message: {
      type: String
    },
    picture: {
      type: String
    }
  },

  data() {
    return {
      isContentExpanded: false
    }
  },

  computed: {
    isIos() {
      return this.device === 'ios'
    },

    showExpand() {
      let messageBox = document.getElementById('content')

      if (messageBox && this.message !== '') {
        let messageBoxWidth = messageBox.offsetWidth
        let messageBoxParentScrollWidth = messageBox.parentElement.scrollWidth

        if (
          messageBoxWidth === messageBoxParentScrollWidth ||
          this.isContentExpanded
        ) {
          return true
        }
      }

      return !!this.picture
    }
  },

  methods: {
    toggleContent() {
      if (this.showExpand) {
        this.isContentExpanded = !this.isContentExpanded
      }
    }
  }
}
</script>
<style lang="sass" scoped>
.ios
    margin: 10px
    background: #ccc
    border-radius: 10px
    font-family: Helvetica Neue, sans-serif
    cursor: default
    position: relative
    text-align: left

    .separator
        display: none

    .header
        display: flex
        background: #eaeaea
        justify-content: space-between
        border-radius: 10px 10px 0 0
        padding: 6px
        color: #6e6e6e

        img
            width: 20px
            height: 20px
            margin-right: 5px
            border-radius: 4px
            border: none

        .company-name
            flex: 1
            line-height: 20px
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis
            color: #60596a
            font-size: 12px

        .notification-time
            line-height: 18px
            font-size: 11px
            margin-right: 6px
            color: #6e6e6e

    .content
        display: flex
        padding: 6px
        font-size: 12px
        line-height: 15px

        .column
            display: flex
            flex-direction: column
            width: 245px

            .title
                flex: 1
                font-weight: 700
                overflow: hidden
                text-overflow: ellipsis
                max-height: 15px

            .message
                flex: 1
                overflow: hidden
                text-overflow: ellipsis

            .picture img
                margin-top: 5px
                max-width: 100%
                max-height: 100%


            .expanded
                max-height: 105px
                word-wrap: break-word
                overflow: hidden
                text-overflow: ellipsis

            .see-more
                margin-top: 5px
                font-size: 11px
                color: #4a3d51

        .contentExpanded
            width: 100%

        .row
            display: flex
            flex-direction: row
            width: 34px

            .picture-preview
                flex: 1
                margin-left: 20px

                img
                    height: 32px
                    width: 32px

.android
    margin: 10px
    background: #fff
    border-radius: 2px
    font-family: Open Sans, sans-serif
    cursor: default
    position: relative
    text-align: left

    .header
        display: flex
        color: #757575
        line-height: 20px
        white-space: nowrap
        padding: 6px

        img
            margin-right: 5px
            max-width: 20px
            height: 20px
            border: none

        .company-name
            color: #60596a
            padding-right: 5px

        .separator
            font-size: 15px
            line-height: 20px

        .notification-time
            line-height: 20px
            font-size: 11px
            margin: 0 5px
            color: #6e6e6e

        .expand-arrow
            font-size: 10px

    .content
        display: flex
        flex-wrap: wrap
        padding: 6px
        font-size: 12px
        line-height: 15px

        .column
            display: flex
            flex-direction: column
            width: 245px

            .title
                flex: 1
                font-weight: 700
                overflow: hidden
                text-overflow: ellipsis
                max-height: 15px

            .message
                flex: 1
                max-height: 30px
                overflow: hidden
                text-overflow: ellipsis

            .picture img
                margin-top: 5px
                width: 100%
                height: 100%

            .expanded
                max-height: 105px
                word-wrap: break-word
                overflow: hidden
                text-overflow: ellipsis

        .contentExpanded
            width: 100%

        .row
            display: flex
            flex-direction: row
            width: 34px

            .picture-preview
                flex: 1
                margin-left: 20px

                img
                    height: 32px
                    width: 32px
</style>
