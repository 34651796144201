<template>
  <div>
    <div class="chart-title" :class="titleClass" :style="titleStyle">
      {{ title }}
    </div>
    <canvas ref="canvas" :width="width" :height="height"></canvas>
  </div>
</template>
<script>
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Line,

  mixins: [reactiveProp],
  props: {
    title: {
      type: String,
      default: ''
    },
    titleClass: {
      type: [String, Array, Object]
    },
    titleStyle: {
      type: Object,
      default() {
        return {}
      }
    },
    xLabel: {
      type: String,
      default: ''
    },
    yLabel: {
      type: String,
      default: ''
    },
    yAxesPercent: {
      type: Boolean,
      default: false
    },
    width: {
      default: 1140,
      type: Number
    },
    height: {
      default: 500,
      type: Number
    }
  },

  data() {
    const numberFormatter = new Intl.NumberFormat('en-GB')

    return {
      options: {
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              const label = data.datasets[tooltipItem.datasetIndex].label
              if (this.yAxesPercent) {
                return `${label}: ${tooltipItem.yLabel}%`
              }

              const number = numberFormatter.format(tooltipItem.yLabel)

              return `${label}: ${number}`
            }
          }
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true
              },
              ticks: {
                // moment.js L = Month numeral, day of month, year 09/04/1986 (based on locale)
                callback: (value) => value.format('L')
              },
              scaleLabel: {
                display: !!this.xLabel,
                fontStyle: 600,
                fontSize: 18,
                labelString: this.xLabel
              }
            }
          ],
          yAxes: [
            {
              gridLines: {
                display: true
              },
              ticks: {
                beginAtZero: true,
                callback: (val) => {
                  val = val % 1 === 0 ? val : null

                  if (val === null) {
                    return null
                  }

                  if (this.yAxesPercent) {
                    return `${val}%`
                  }

                  return numberFormatter.format(val)
                }
              },
              scaleLabel: {
                display: true,
                fontStyle: 600,
                fontSize: 18,
                labelString: this.yLabel
              }
            }
          ]
        },
        elements: {
          point: {
            radius: 0,
            hitRadius: 10,
            hoverRadius: 5
          }
        }
      }
    }
  },

  mounted() {
    this.renderChart(this.chartData, this.options)
  }
}
</script>
<style lang="sass" scoped>
.chart-title
    font-weight: 600
    font-size: 20px
</style>
