<template>
  <div class="row">
    <div class="col-md-3">
      <input
        ref="unitValue"
        :value="unitValue"
        class="form-control"
        type="number"
        min="1"
        max="999"
        :disabled="disabled"
        @change="updateUnitValue"
      />
    </div>
    <div class="col-md-9">
      <select2
        ref="unitType"
        :value="unitType"
        class="form-control"
        :disabled="disabled"
        @input="updateUnitType"
      >
        <option value="TM">{{ $t('simpleTimePeriodMinutes') }}</option>
        <option value="TH">{{ $t('simpleTimePeriodHours') }}</option>
        <option value="D">{{ $t('simpleTimePeriodDays') }}</option>
        <option value="M">{{ $t('simpleTimePeriodMonths') }}</option>
        <option value="Y">{{ $t('simpleTimePeriodYears') }}</option>
      </select2>
    </div>
  </div>
</template>
<script>
const regex = /(PT?)(\d*)([MYDH]?)/
export default {
  props: {
    value: {
      type: String,
      require: true
    },

    disabled: {
      type: Boolean
    }
  },

  computed: {
    unitValue() {
      const result = regex.exec(this.value)

      if (result) {
        return result[2]
      }

      return null
    },

    unitType() {
      const result = regex.exec(this.value)

      if (result) {
        if (result[1] === 'PT') {
          return 'T' + result[3]
        }

        return result[3]
      }

      return null
    }
  },

  methods: {
    updateUnitValue(e) {
      const interval = this.createInterval(e.target.value, this.unitType)
      this.$emit('input', interval)
    },

    updateUnitType(unitType) {
      const interval = this.createInterval(this.unitValue, unitType)
      this.$emit('input', interval)
    },

    createInterval(unitValue, unitType) {
      let interval = ''

      switch (unitType) {
        case 'TM':
          interval = 'PT' + unitValue + 'M'
          break
        case 'TH':
          interval = 'PT' + unitValue + 'H'
          break
        case 'D':
        case 'M':
        case 'Y':
          interval = 'P' + unitValue + unitType
          break

        default:
          interval = 'P' + unitValue
      }

      return interval
    }
  }
}
</script>
