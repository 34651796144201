import { render, staticRenderFns } from "./Editable.vue?vue&type=template&id=241736ac&scoped=true&"
import script from "./Editable.vue?vue&type=script&lang=js&"
export * from "./Editable.vue?vue&type=script&lang=js&"
import style0 from "./Editable.vue?vue&type=style&index=0&id=241736ac&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "241736ac",
  null
  
)

export default component.exports