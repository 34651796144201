<template>
  <div>
    <action-icon :action-type="metadata.actionType" />
    Deleted
    <b>{{ metadata.actionType }}</b>
    action
    <b>{{ metadata.actionName | truncate(30) }}</b>
    on workflow
    <b>{{ log.workflowId }}</b>
  </div>
</template>
<script>
export default {
  props: ['log', 'metadata']
}
</script>
