<template>
  <span>
    {{ $t('toolboxSmallProfileADateAfter') }}
    <span v-if="isDate(conditionArgs.value)" class="criteria-condition-args">
      '{{ formatDate(conditionArgs.value) }}'
    </span>
    <span v-else class="invalid-text">{{ $t('toolboxSmallProfileMissingDate') }}</span>
  </span>
</template>
<script>
import moment from 'moment'

export default {
  props: ['conditionArgs'],

  methods: {
    isDate(value) {
      return value && moment(value).isValid()
    },
    formatDate(date) {
      return moment(date).format('L')
    }
  }
}
</script>
