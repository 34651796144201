<template>
  <div style="margin: 20px">
    <div class="form-group">
      <label class="control-label">{{ $t('splitSmallToolboxLabel') }}</label>
      <select
        v-model="successorCount"
        class="select-field"
        :disabled="readOnly"
        @change="updateSuccessors"
      >
        <option :value="2">2</option>
        <option :value="3">3</option>
        <option :value="4">4</option>
        <option :value="5">5</option>
      </select>
    </div>
  </div>
</template>
<script>
export default {
  inject: ['workflowApi'],
  props: ['action', 'readOnly'],

  data() {
    return {
      successorCount: this.getSuccessorCount()
    }
  },

  watch: {
    'action.successors': {
      handler() {
        this.successorCount = this.getSuccessorCount()
      }
    }
  },

  methods: {
    updateSuccessors() {
      let key = 'a'
      const map = {}

      for (let i = 0; i < Math.min(this.successorCount, 5); i++) {
        map[key] = this.action.successors[key] || ''
        key = String.fromCharCode(key.charCodeAt(0) + 1)
      }

      this.workflowApi.updateAction(this.action.id, { successors: map })
    },

    getSuccessorCount() {
      return Object.keys(this.action.successors).length
    }
  }
}
</script>
<style scoped>
.select-field {
  width: 60px;
}
</style>
