<template>
  <div>
    <h5 style="font-weight: bold">
      {{ isDefault ? '(Default)' : '' }} {{ getLanguage }}
    </h5>
    <div class="panel panel-default">
      <div class="panel-body">
        <div class="form-group">
          <label for="fromName">{{ $t('fromNameLabel') }}</label>
          <personalised-input
            id="fromName"
            v-model="value.fromName"
            type="text"
            :emoji-enabled="false"
            :placeholder="$t('fromNameLabel')"
            style="width: 100%"
            :disabled="disabled"
          />
        </div>
        <div class="form-group">
          <label for="message">{{ $t('messageLabel') }}</label>
          <personalised-input
            id="message"
            v-model="value.text"
            type="textarea"
            :profile-attributes="profileAttributes"
            rows="6"
            :placeholder="$t('yourMessageTextLabel')"
            :disabled="disabled"
          />
          <sms-counter
            :content="smsCounter"
            :shorten-url="shortenUrl"
            :force-estimated-label="withVoucherCodes"
            class="pull-right"
            style="margin-right: 25px"
          />
        </div>
      </div>
      <div class="panel-body">
        <div v-if="optOutReplyEnabled" class="form-group">
          <label>Opt-out Phrase</label>
          <sms-opt-out-input v-model="optOutMessage"></sms-opt-out-input>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ISO6391 from 'iso-639-1'
import PersonalisedInput from '@/components/PersonalisedInput'
import SmsCounter from '@/components/SmsCounter'
import SmsOptOutInput from '@/components/SmsOptOutInput'

export default {
  components: {
    PersonalisedInput,
    SmsCounter,
    SmsOptOutInput
  },

  props: [
    'value',
    'isDefault',
    'lang',
    'profileAttributes',
    'disabled',
    'unsubscribeUrl',
    'optOutReplyEnabled',
    'optOutMessage',
    'shortenUrl',
    'withVoucherCodes'
  ],

  computed: {
    smsCounter() {
      let text = this.value.text

      if (this.withVoucherCodes) {
        text += ' X6RgzCXb'
      }

      if (this.optOutReplyEnabled) {
        return text + this.optOutMessage
      }
      return text + this.unsubscribeUrl
    },
    getLanguage() {
      return ISO6391.getName(this.lang)
    }
  }
}
</script>
