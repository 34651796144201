<script>
import Action from './AbstractAction'

export default {
  extends: Action,

  inject: ['eventBus'],

  data() {
    return {
      icon: 'source'
    }
  },
  mounted() {
    this.eventBus.$emit('startActionMounted')
  },
  methods: {
    initDraggable() {
      // Start action isn't movable
    },
    renderInputs() {
      // Start action has no inputs
    },
    onConnectionDrag() {
      this.$el.classList.add('action-nodrop')
    },
    onConnectionDragStop() {
      this.$el.classList.remove('action-nodrop')
    },
    onClick(e) {
      if (
        !(
          this.mouseDownPos.pageX === e.pageX &&
          this.mouseDownPos.pageY === e.pageY
        )
      ) {
        return
      }

      if (!e.shiftKey) {
        this.selectedActions.selectAction(this.action.id)
      }
    }
  }
}
</script>
<style scoped lang="sass">
.action-delete,
.action-copy
    display: none
.action.multi-select-cursor-allowed
    cursor: not-allowed
</style>
