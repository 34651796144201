<template>
  <div style="margin: 20px">
    <button type="button" class="btn btn-default btn-block" @click="openEditor">
      {{ !isAvailable ? $t('previewBtn') : $t('editBtn') }}
    </button>

    <div class="panel panel-default" v-if="isExclusionEnabled">
      <div class="panel-body">
        <div class="sms-prop-name">
          {{ $t('smsSmallToolboxExclusionCategory') }}:
        </div>
        <div
          class="email-prop-value"
          v-if="
            action.config.exclusion && action.config.exclusion.check === false
          "
        >
          {{ $t('smsSmallToolboxExclusionCategoryNotEnabled') }}
        </div>
        <div
          class="sms-prop-value"
          v-if="
            (action.config.exclusion && action.config.exclusion.check) ||
            displayExclusionError
          "
          :class="{
            'invalid-text':
              displayExclusionError ||
              $v.action.config.exclusion.category.$invalid
          }"
        >
          {{ exclusionCategoryName }}
          <template
            v-if="
              displayExclusionError ||
              !$v.action.config.exclusion.category.required
            "
          >
            {{ $t('smsSmallToolboxMissingExclusionCategory') }}
          </template>
        </div>
      </div>
    </div>

    <div class="panel panel-default">
      <div class="panel-body">
        <div class="sms-prop-name">
          {{ $t('smsSmallToolboxIgnoreSubscription') }}:
        </div>
        <div class="sms-prop-value">
          {{ ignoreSubscriptionMap(action.config.ignore_subscription) }}
        </div>
      </div>
    </div>

    <label>
      <icon glyph="sms" />
      {{ $t('smsSmallToolboxSmsPreviewLabel') }}:
    </label>
    <div
      style="
        display: flex;
        justify-content: center;
        background: #083146;
        padding: 30px;
        border-radius: 4px;
      "
    >
      <device-sms-message-preview
        :fromName="fromName"
        :text="text"
        :unsubscribe-url="generateUnsubscribeUrl"
        :optOutMessage="getOptOutMessage"
        :shorten-url="getShortenedLink"
      />
    </div>
  </div>
</template>
<script>
import DeviceSmsMessagePreview from '@/components/DeviceSmsMessagePreview'
import XpApi from '@/libs/XpApi'
import { requiredIf } from 'vuelidate/lib/validators'
import NationalExclusionCategoriesMixin from '@/views/WorkflowEdit/NationalExclusionCategoriesMixin'

export default {
  props: ['action', 'readOnly'],

  inject: ['eventBus'],

  mixins: [NationalExclusionCategoriesMixin],

  components: { DeviceSmsMessagePreview },

  validations: {
    action: {
      config: {
        exclusion: {
          category: {
            required: requiredIf((e) => {
              return e.check || false
            })
          }
        }
      }
    }
  },

  data() {
    return {}
  },

  mounted() {
    this.fetchReplyToMessages()
  },

  computed: {
    isAvailable() {
      return !this.readOnly && this.$store.getters.smsEnabled
    },

    smsShortenURL() {
      return this.$store.getters.isFeatureEnabled('smsShortenURL')
    },

    fromName() {
      let fromName =
        this.action.config.messages[this.action.config.defaultLang].fromName

      return fromName !== '' ? fromName : 'No Content'
    },

    text() {
      let messageText =
        this.action.config.messages[this.action.config.defaultLang].text

      if ((this.action.config.voucher_code_enabled || 0) === 0)
        return messageText

      if (messageText === '') return 'X6RgzCXb'

      if (this.action.config.voucher_code_position === 0) {
        return 'X6RgzCXb\n' + messageText
      }

      return messageText + '\nX6RgzCXb'
    },

    getOptOutMessage() {
      if (!this.action.config.include_opt_out_reply) {
        return ''
      }
      let message = this.action.config.reply_to_messages.find(
        (message) => message.language === this.action.config.defaultLang
      )
      return message ? message['message'] : ''
    },

    getShortDomain() {
      return this.$store.getters.getDomain('shortDomain')
    },

    generateUnsubscribeUrl() {
      if (this.getShortDomain && this.action.config.include_unsubscribe) {
        return `https://${this.getShortDomain}/cfhisu`
      }

      return ''
    },

    getShortenedLink() {
      if (this.getShortDomain && this.smsShortenURL) {
        return `${this.getShortDomain}/cfhisu`
      }

      return ''
    },

    exclusionCategoryName() {
      if (
        !(this.action.config.exclusion && this.action.config.exclusion.category)
      )
        return ''

      const value = this.getNecLabelByValue(this.action.config.exclusion.category)
      if (value === undefined) {
        this.action.config.exclusion.check = false
        this.action.config.exclusion.category = null
      }

      return value
    },
    displayExclusionError() {
      return this.isExclusionEnabled && !this.action.config.exclusion
    }
  },

  methods: {
    openEditor() {
      this.eventBus.$emit('showLargeToolbox', true)
    },

    fetchReplyToMessages() {
      const projectId = this.$store.state.app.project.id
      XpApi.get(`projects/${projectId}/sms-reply-to-messages`).then(
        ({ data }) => {
          this.action.config.reply_to_messages = data.map((record) => ({
            language: record.language,
            message: record.message
          }))
        }
      )
    },

    ignoreSubscriptionMap(type) {
      const map = {
        default: this.$t('smsSmallToolboxDoNotIgnoreSubscription'),
        ignore: this.$t('smsSmallToolboxIgnoreSubscription'),
        doNotIgnore: this.$t('smsSmallToolboxDoNotIgnoreSubscription')
      }

      return map[type] || this.$t('smsSmallToolboxDoNotIgnoreSubscription')
    }
  }
}
</script>
<style lang="sass" scoped>
.btn-block
    margin-bottom: 20px

.sms-prop-name
    font-weight: bold

.sms-prop-value
    margin-bottom: 10px
</style>
