<template>
  <div>
    <icon glyph="workflow" />
    Deleted workflow
    <b>{{ log.workflowId }}</b>
  </div>
</template>
<script>
export default {
  props: ['log', 'metadata']
}
</script>
