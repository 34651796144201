export function numberFilter(number) {
  if (number === 'N/A') {
    return 'N/A'
  }

  return (Number(number) || 0).toLocaleString('en')
}

export function percentageOfFilter(number, total, decimalPlaces = 0) {
  return Number(number / total || 0).toLocaleString('en', {
    style: 'percent',
    maximumFractionDigits: decimalPlaces
  })
}
