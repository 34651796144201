<template>
  <div>
    <el-divider class="short-divider"></el-divider>
    <el-row type="flex" class="variant" justify="space-around">
      <div class="field">
        <label for="productViewedHistoryNumberOfProducts" class="option-label">
          Number of Products
        </label>
        <el-input-number
          id="productViewedHistoryNumberOfProducts"
          v-model="productsCount"
          size="small"
          :min="3"
          :max="4"
        />
      </div>
      <div class="field">
        <label for="productViewedHistoryPage" class="option-label">Page</label>
        <el-input-number
          id="productViewedHistoryPage"
          v-model="page"
          :min="1"
          size="small"
        />
      </div>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'BeeShopboxPluginCrossSell',

  props: {
    value: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      ...this.value
    }
  },

  watch: {
    $data: {
      handler(data) {
        this.$emit('input', data)
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.short-divider {
  width: 50%;
  margin: 5px 0;
}

.variant {
  margin: 10px 0;
  flex-wrap: wrap;
}

.field .el-input-number {
  width: 100%;
}

.option-label {
  font-size: 14px;
}
</style>
