const ActionDefaults = {
  Email: {
    successors: {
      primary: ''
    },
    subject: '',
    fromName: '',
    fromAddress: '',
    replyToName: '',
    replyToAddress: '',
    analyticsCode: '',
    contentId: 0,
    config: {
      ignore_subscription: 'doNotIgnore',
      exclusion: {
        check: false,
        category: null
      }
    }
  },
  Sms: {
    successors: {
      primary: ''
    },
    config: {
      defaultLang: 'en',
      languages: ['en'],
      messages: {
        en: {
          fromName: '',
          text: '',
          optOutMessage: ''
        }
      },
      include_unsubscribe: false,
      include_opt_out_reply: false,
      reply_to_messages: [],
      ignore_subscription: 'default',
      exclusion: {
        check: false,
        category: null
      },
      voucher_code_enabled: 0,
      voucher_code_position: 1
    }
  },
  Push: {
    successors: {
      primary: ''
    },
    config: {
      defaultLang: 'en',
      languages: ['en'],
      exclusion: {
        check: false,
        category: null
      },
      voucher_code_enabled: 0,
      voucher_code_position: 1
    }
  },
  Inbox: {
    successors: {
      primary: ''
    },
    config: {
      exclusion: {
        check: false,
        category: null
      },
      defaultLang: 'en',
      languages: ['en'],
      messages: {
        en: {
          push_title: '',
          push_text: ''
        }
      },
      push_action: {
        action: '',
        url: '',
        deeplink: '',
        url_blank: false
      },
      push_icon: null,
      payload_add: [],
      inbox_style: {
        bg: '',
        title_bg: ''
      },
      voucher_code_enabled: 0,
      voucher_code_position: 1
    }
  },
  Delay: {
    successors: {
      primary: ''
    },
    options: {
      type: 'timePeriod',
      interval: ''
    }
  },
  Decision: {
    successors: {
      yes: '',
      no: ''
    },
    validatorType: 'allOf',
    validator: []
  },
  Split: {
    successors: {
      a: '',
      b: ''
    }
  },
  MultiDecision: {
    successors: {
      a: '',
      else: ''
    },
    validators: {
      a: {
        validatorType: 'allOf',
        validator: [
          [
            {
              type: 'profile',
              field: '',
              condition: 'TextEquals',
              negator: false,
              conditionArgs: {
                value: ''
              }
            }
          ]
        ]
      },
      b: {
        validatorType: 'allOf',
        validator: []
      },
      c: {
        validatorType: 'allOf',
        validator: []
      },
      d: {
        validatorType: 'allOf',
        validator: []
      },
      e: {
        validatorType: 'allOf',
        validator: []
      }
    },
    config: {
      successorTitles: {
        a: 'Untitled'
      }
    }
  },
  AdRetarget: {
    successors: {
      primary: ''
    },
    external_audience_id: 0,
    event: 0
  },
  Promotion: {
    successors: {
      primary: ''
    },
    promotion_id: '',
    event: 0,
    promotion_amount: 0,
    promotion_amount_attribute_title: null,
    promotion_amount_required: 0,
    promotion_duration: 1,
    promotion_duration_required: 0
  },
  End: {
    successors: {}
  }
}

Object.freeze(ActionDefaults)

export default ActionDefaults
