import loadScript from 'load-script'
import shortid from 'shortid'

const URL = `https://loader.getbee.io/v1/api/loader?v=${shortid.generate()}`

let loader

class BeePlugin {
  static load() {
    if (!loader) {
      loader = new Promise((resolve, reject) => {
        loadScript(URL, (err) => {
          if (err) reject(err)
          resolve(window.BeePlugin)
        })
      })
    }

    return loader
  }

  constructor(token, config) {
    this.promise = new Promise((resolve, reject) => {
      BeePlugin.load()
        .then((BeePlugin) => {
          BeePlugin.create(token, config, (instance) => {
            resolve(instance)
          })
        })
        .catch(reject)
    })
  }

  start(template) {
    this.promise.then((instance) => instance.start(template))
  }

  load(template) {
    this.promise.then((instance) => instance.load(template))
  }

  save() {
    this.promise.then((instance) => instance.save())
  }

  saveAsTemplate() {
    this.promise.then((instance) => instance.saveAsTemplate())
  }

  send() {
    this.promise.then((instance) => instance.send())
  }

  preview() {
    this.promise.then((instance) => instance.preview())
  }

  toggleStructure() {
    this.promise.then((instance) => instance.toggleStructure())
  }
}

export default BeePlugin
