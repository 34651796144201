<template>
  <div class="sidebar sidebar-lg">
    <div
      class="sidebar-header"
      :class="'action-style-' + action.type.toLowerCase()"
    >
      <action-icon class="sidebar-icon" :actionType="action.type" />
      <div class="sidebar-title">{{ action.name }}</div>
      <button
        type="button"
        class="close"
        :aria-label="$t('closeButton')"
        @click="$emit('close')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="sidebar-body">
      <component
        :key="action.id"
        :is="component"
        :action="action"
        v-bind="actionStatExtendedProps(action)"
      ></component>
    </div>
  </div>
</template>
<script>
export default {
  props: ['workflow', 'action'],

  computed: {
    component() {
      return require(`./ActionReporting/${this.action.type}`).default
    }
  },

  methods: {
    actionStatExtendedProps(action) {
      switch (action.type) {
        case 'Email':
          return {
            'track-email-open':
              this.workflow.features.email_open_tracking != undefined
                ? this.workflow.features.email_open_tracking
                : true,
            'track-email-click':
              this.workflow.features.email_click_tracking != undefined
                ? this.workflow.features.email_click_tracking
                : true
          }
        default:
          return {}
      }
    }
  }
}
</script>
