<template>
  <div style="padding: 20px">
    <label>{{ $t('actionReportingPushStats') }}</label>
    <div class="stats-bar">
      <div class="stats-bar-item">
        <icon class="stats-bar-item-icon" glyph="push" />
        <div class="stats-bar-item-title">{{ $t('actionReportingSent') }}</div>
        <div class="stats-bar-item-number">
          {{ numberFilter(pushSentTotal) }}
          ({{ percentageOfFilter(pushSentTotal, pushArrivedTotal) }})
        </div>
      </div>
      <div class="stats-bar-item">
        <icon class="stats-bar-item-icon" glyph="mobile" />
        <div class="stats-bar-item-title">{{ $t('actionReportingDelivered') }}</div>
        <div class="stats-bar-item-number">
          {{ numberFilter(pushDeliveredTotal) }}
          ({{ percentageOfFilter(pushDeliveredTotal, pushSentTotal) }})
        </div>
      </div>
      <div class="stats-bar-item">
        <icon class="stats-bar-item-icon" glyph="mouse" />
        <div class="stats-bar-item-title">{{ $t('actionReportingClicks') }}</div>
        <div class="stats-bar-item-number">
          {{ numberFilter(pushClickedTotal) }}
          ({{ percentageOfFilter(pushClickedTotal, pushDeliveredTotal) }})
        </div>
      </div>
    </div>
    <hr />
    <date-line-chart
      v-if="viewMode === 'numbers'"
      key="numbersChart"
      :chart-data="numbersChartData"
      :title="$t('actionReportingSentClicks')"
      title-class="action-color-email"
      :yLabel="$t('actionReportingNumberOfProfiles')"
    />
  </div>
</template>
<script>
import TrendLineMixin from '@/libs/TrendLineMixin'
import DateLineChart from '@/components/DateLineChart'
import { numberFilter, percentageOfFilter } from '@/helpers/filters'

export default {
  props: [
    'dateRange',

    'pushArrivedTotal',
    'pushSentTotal',
    'pushDeliveredTotal',
    'pushClickedTotal',

    'pushSentStats',
    'pushClickedStats'
  ],

  mixins: [TrendLineMixin],

  components: {
    DateLineChart
  },

  data() {
    return {
      viewMode: 'numbers',
      numberFilter,
      percentageOfFilter
    }
  },

  computed: {
    numbersChartData() {
      return {
        labels: this.dateRange,
        datasets: [
          {
            label: this.$t('actionReportingChartSent'),
            backgroundColor: 'RGBA(77, 140, 192, 0.2)',
            borderColor: 'RGBA(77, 140, 192, 1)',
            borderWidth: 1,
            data: this.pushSentStats
          },
          {
            tooltip: false,
            fill: false,
            label: this.$t('actionReportingChartTrendLine'),
            data: this.getTrendLine(this.pushSentStats),
            backgroundColor: 'RGBA(77, 140, 192, 1)',
            borderColor: 'RGBA(77, 140, 192, 1)',
            spanGaps: true
          },
          {
            label: this.$t('actionReportingChartClicks'),
            backgroundColor: 'RGBA(255, 195, 113, 0.2)',
            borderColor: 'RGBA(255, 195, 113, 1)',
            borderWidth: 1,
            data: this.pushClickedStats
          },
          {
            tooltip: false,
            fill: false,
            label: this.$t('actionReportingChartTrendLine'),
            data: this.getTrendLine(this.pushClickedStats),
            backgroundColor: 'RGBA(255, 195, 113, 1)',
            borderColor: 'RGBA(255, 195, 113, 1)',
            spanGaps: true
          }
        ]
      }
    },

    percentChartData() {
      return {
        labels: this.dateRange,
        datasets: [
          {
            label: this.$t('actionReportingChartClicks'),
            backgroundColor: 'RGBA(77, 140, 192, 0.2)',
            borderColor: 'RGBA(77, 140, 192, 1)',
            borderWidth: 1,
            data: this.clickedPercentStats
          },
          {
            tooltip: false,
            fill: false,
            label: this.$t('actionReportingChartTrendLine'),
            data: this.getTrendLine(this.clickedPercentStats),
            backgroundColor: 'RGBA(77, 140, 192, 1)',
            borderColor: 'RGBA(77, 140, 192, 1)',
            spanGaps: true
          }
        ]
      }
    }
  }
}
</script>
