<template>
  <div>
    <icon glyph="workflow" />
    <b>{{ metadata.eventData.isRunning ? 'Started' : 'Stopped' }}</b>
    workflow
    <b>{{ log.workflowId }}</b>
  </div>
</template>
<script>
export default {
  props: ['log', 'metadata']
}
</script>
