import { render, staticRenderFns } from "./IgnoreSubscription.vue?vue&type=template&id=3430d3a8&scoped=true&"
import script from "./IgnoreSubscription.vue?vue&type=script&lang=js&"
export * from "./IgnoreSubscription.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3430d3a8",
  null
  
)

export default component.exports