<template>
  <div class="input-group">
    <input
      ref="dateTimePicker"
      type="text"
      class="form-control"
      :placeholder="placeholder"
      :disabled="disabled"
      :value="value"
    />
    <span class="input-group-addon">
      <span class="glyphicon glyphicon-calendar"></span>
    </span>
  </div>
</template>
<script>
import $ from 'jquery'
import '@/libs/bootstrap-datepicker/bootstrap-datetimepicker'
import moment from 'moment'

export default {
  props: {
    value: {
      required: true
    },
    placeholder: {
      required: false,
      type: String
    },
    format: {
      required: false,
      type: String,
      default: 'YYYY-MM-DD'
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
    isMaxRange: {
      required: false,
      type: Boolean,
      default: false
    },
    isFutureDateOnly: {
      required: false,
      type: Boolean,
      default: false
    }
  },

  mounted() {
    this.dateTimeInput = $(this.$refs.dateTimePicker)
    this.dateTimeInput
      .datetimepicker({
        useCurrent: false,
        format: this.format
      })
      .on('dp.change', this.onValueChange)
    this.dateTimePicker = this.dateTimeInput.data('DateTimePicker')

    if (this.isMaxRange) {
      this.siblingInput = this.dateTimeInput
        .parent()
        .prevAll('div.input-group')
        .children('input')
      this.siblingPicker = this.siblingInput.data('DateTimePicker')
      if (this.siblingPicker) {
        this.siblingInput.on('dp.change', this.onSiblingValueChange)
      }
    }

    if (this.isFutureDateOnly) {
      this.dateTimePicker.minDate(moment())
    }
  },

  beforeDestroy() {
    this.dateTimePicker.destroy()
    this.dateTimeInput.off()

    if (this.siblingPicker) {
      delete this.siblingInput
      delete this.siblingPicker
    }
  },

  methods: {
    onValueChange(event) {
      this.$emit('input', this.dateTimeInput.val())
      if (this.siblingPicker) {
        this.siblingPicker.maxDate(event.date)
      }
    },

    onSiblingValueChange(event) {
      if (this.isFutureDateOnly) {
        const isFutureDate = event.date > moment()
        isFutureDate
          ? this.dateTimePicker.minDate(event.date)
          : this.dateTimePicker.minDate(moment())
      } else {
        this.dateTimePicker.minDate(event.date)
      }
    }
  }
}
</script>
