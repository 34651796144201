<template>
  <div>
    <div class="previews-container">
      <div class="side-icon">
        <img src="@/images/chrome.svg"/>
      </div>
      <div class="preview-window">
        <div class="preview-column">
          <div class="preview-row" style="min-height: 100px">
            <div class="icon">
              <img v-if="iconUrl" :src="iconUrl"/>
              <img v-else :src="companyLogo"/>
            </div>
            <div class="preview-column text-content">
              <div v-if="title" class="title">
                {{ title }}
              </div>
              <div class="message">
                <span style="white-space: pre;">{{
                    message || $t('webPushYourMessageHere')
                  }}</span>
              </div>
              <div class="url">
                <span>{{ filteredUrl }}</span>
              </div>
            </div>
            <div class="menu">
              <span class="glyphicon glyphicon-cog"/>
              <span style="font-size: 1.5em">×</span>
            </div>
          </div>
          <div v-if="pictureUrl" class="picture">
            <img :src="pictureUrl"/>
          </div>
        </div>
      </div>
    </div>
    <div class="previews-container">
      <div class="side-icon">
        <img src="@/images/firefox.svg"/>
      </div>
      <div class="preview-window">
        <div class="preview-column">
          <div class="preview-row">
            <div class="preview-column">
              <div v-if="title" class="firefox-title">
                {{ title }}
              </div>
              <div class="preview-row" style="min-height: 100px">
                <div class="icon">
                  <img v-if="iconUrl" :src="iconUrl"/>
                  <img v-else :src="companyLogo"/>
                </div>
                <div class="preview-column text-content">
                  <div class="message">
                    <span style="white-space: pre;">{{
                        message || $t('webPushYourMessageHere')
                      }}</span>
                  </div>
                  <div class="url">
                    <span>
                    {{ $t('webPushVia', {filteredUrl: filteredUrl}) }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="firefox-menu">
              <span style="font-size: 1.5em">×</span>
              <span class="glyphicon glyphicon-cog"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    companyLogo: {
      type: String
    },

    iconUrl: {
      type: String
    },

    title: {
      type: String
    },

    message: {
      type: String
    },

    pictureUrl: {
      type: String
    },

    url: {
      type: String,
      default: ''
    }
  },

  computed: {
    filteredUrl() {
      if (this.url.length < 0) {
        return
      }

      return this.url.replace(/(^\w+:|^)\/\//, '')
    }
  }
}
</script>

<style lang="sass" scoped>
.previews-container
  display: flex
  flex-direction: row
  margin: 20px 0
  min-height: 50px

  .side-icon
    height: 50px
    width: 55px
    margin: auto 0

    img
      height: 100%
      width: 100%

  .preview-window
    width: 100%
    border: 0.5px solid #ddd
    overflow: hidden
    margin-left: 10px

    .preview-column
      display: flex
      flex-direction: column
      overflow: hidden
      text-overflow: ellipsis

      .firefox-title
        flex: 1
        height: 20px
        font-weight: 500
        padding: 2px 10px
        overflow: hidden
        white-space: nowrap
        text-overflow: ellipsis

      .preview-row
        display: flex
        flex-direction: row

        .icon
          height: 65px
          width: 65px
          margin: 10px 10px

          img
            width: 100%
            height: 100%

        .text-content
          display: flex
          flex-direction: column
          margin: 5px 0
          flex: 6

          .title
            flex: 1
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis

          .message
            height: 60px
            word-wrap: break-word
            overflow: hidden
            text-overflow: ellipsis

          .url
            height: 20px
            max-width: 250px
            overflow: hidden
            text-overflow: ellipsis
            color: #707070

        .menu
          flex: 1

        .firefox-menu
          display: flex
          flex-direction: column
          justify-content: space-between
          margin: 0 5px 10px auto


      .picture
        text-align: center
        padding-bottom: 2px

        img
          max-height: 240px
          max-width: 100%
</style>
