<template>
  <div>
    <div class="form-group">
      <div class="col-md-12">
        <label @click="updateCheck(!value.check)">
          {{ $t('nationalExclusionSettingsCheck') }}
        </label>
        <toggle-button
          :disabled="disabled"
          color="#1d87c8"
          :value="value.check"
          :sync="true"
          @change="updateCheck($event.value)"
        />
      </div>
    </div>
    <div v-if="value.check" class="form-group">
      <div class="col-md-6 category-selection">
        <select2
          :disabled="disabled"
          :value="value.category"
          :placeholder="$t('nationalExclusionSettingsSelectCategory')"
          @input="updateCategory($event)"
        >
          <template v-if="isOasisAvailable && isBetssonAvailable">
            <optgroup
              :label="group.title"
              :key="group.title"
              v-for="group in groupedOptions"
            >
              <option
                :value="option.value"
                v-for="option in group.options"
                :title="option.titleSelected"
              >
                {{ option.title }}
              </option>
            </optgroup>
          </template>

          <template v-else-if="isBetssonAvailable">
            <option
              :value="option.value"
              v-for="option in betssonOptions"
              :key="option.value"
            >
              {{ option.title }}
            </option>
          </template>
          <template v-else-if="isOasisAvailable">
            <option
              :value="option.value"
              v-for="option in oasisOptions"
              :key="option.value"
            >
              {{ option.title }}
            </option>
          </template>
        </select2>
      </div>
    </div>
  </div>
</template>

<script>
import NationalExclusionCategoriesMixin from '@/views/WorkflowEdit/NationalExclusionCategoriesMixin'

export default {
  name: 'NationalExclusionCheckSettings',
  mixins: [NationalExclusionCategoriesMixin],
  props: {
    value: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    updateCheck(value) {
      if (this.disabled) {
        return
      }

      this.value.check = value

      // unset the category
      if (!value) {
        this.value.category = null
      }
    },

    updateCategory(value) {
      this.value.category = value
    }
  },
  computed: {
    options() {
      if (this.isBetssonAvailable) {
        return this.betssonOptions
      }
      if (this.isOasisAvailable) {
        return this.oasisOptions
      }
      return []
    },
    groupedOptions() {
      return [
        {
          title: this.$t('nationalExclusionTechssonPlayerCheck'),
          options: this.betssonOptions
        },
        {
          title: this.$t('nationalExclusionOasisPlayerCheck'),
          options: this.oasisOptions
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.category-selection {
  width: 100%;

  ::v-deep select {
    width: 50%;
  }
}

::v-deep .vue-js-switch {
  z-index: 0;
}
</style>
