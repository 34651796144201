class Counter {
  constructor(start = 0) {
    this.counter = parseInt(start)
  }

  increment(amount = 1) {
    this.counter += parseInt(amount)

    return this.counter
  }
}

export default Counter
