import { render, staticRenderFns } from "./ImportMessages.vue?vue&type=template&id=2201b847&scoped=true&"
import script from "./ImportMessages.vue?vue&type=script&lang=js&"
export * from "./ImportMessages.vue?vue&type=script&lang=js&"
import style0 from "./ImportMessages.vue?vue&type=style&index=0&id=2201b847&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2201b847",
  null
  
)

export default component.exports