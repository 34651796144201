import Vue from 'vue'
import VueRouter from 'vue-router'

import routes from './Routes'
import Auth from '@/providers/Auth'

Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.auth)) {
    Auth.authorize(to.params.projectId, to.fullPath).then(() => next())
  } else {
    next()
  }
})

export default router
