<template>
  <ul>
    <li>
      <icon v-tooltip:left="$t('delayStatTotalDelayed')" glyph="hand" />
      {{ numberFilter(stats['Delay:delayed']) }}
    </li>
    <li>
      <icon v-tooltip:left="$t('delayStatCurrentlyWaiting')" glyph="clock" />
      {{ numberFilter(waiting) }}
    </li>
    <li>
      <icon
        v-tooltip:left="$t('delayStatTotalEndedEarly')"
        glyph="cross-circle"
      />
      {{ numberFilter(stats['Delay:earlyCancel']) }}
    </li>
  </ul>
</template>
<script>
import { numberFilter } from '@/helpers/filters'

export default {
  props: ['stats'],

  data() {
    return {
      numberFilter
    }
  },

  computed: {
    waiting() {
      return (
        (Number(this.stats['Delay:delayed']) || 0) -
        (Number(this.stats['Delay:resumed']) || 0) -
        (Number(this.stats['Delay:cancelled']) || 0) -
        (Number(this.stats['Delay:earlyCancel']) || 0)
      )
    }
  }
}
</script>
