<template>
  <div class="email-upload">
    <div>
      <h3>{{ $t('uploadProvideHtml') }}</h3>

      <label>{{ $t('uploadFromComputer') }}:</label>
      <div
        class="dropzone"
        @dragover="onDragOver"
        @dragleave="onDragLeave"
        @drop="onDragDrop"
      >
        <p>{{ $t('uploadDragToUpload') }}</p>

        <label class="btn btn-file btn-default">
          <icon glyph="cloud-upload" />
          {{ $t('uploadBrowseLabel') }}
          <input type="file" accept=".htm,.html" @change="importFile" />
        </label>
      </div>

      <br />

      <div class="form-group">
        <label for="fromUrl" class="control-label">{{ $t('uploadFromUrlLabel') }}:</label>
        <div class="input-group">
          <input
            id="fromUrl"
            v-model="url"
            :disabled="importing"
            type="url"
            class="form-control"
            :placeholder="$t('uploadEnterUrlPlaceholder')"
            @keypress.enter="importUrl"
          />
          <div class="input-group-btn">
            <button
              ref="importUrlBtn"
              v-btn-loading="importing"
              class="btn btn-default"
              type="button"
              :data-loading-text="$t('uploadImportLoadingText')"
              @click="importUrl"
            >
              {{ $t('uploadImportLabel') }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <h3>{{ $t('uploadUseEmailBuilder') }}</h3>

    <div class="form-group">
      <label for="templateSearch" class="control-label">
        {{ $t('uploadSearchTemplateLabel') }}:
      </label>
      <input
        id="templateSearch"
        v-model="search"
        class="form-control"
        type="text"
        :placeholder="$t('searchInputPlaceholder')"
        aria-label="Search"
      />
    </div>

    <h4>{{ $t('uploadSelectATemplateHeader') }}:</h4>
    <div
      v-if="action.config.templateId || action.config.templateId === 0"
      class="template-text"
    >
      <span v-tooltip:top="formatTemplateTooltip">
        {{ formatTemplateInfo }}
      </span>
    </div>
    <div class="row">
      <div v-for="template in builderTemplates" class="col-md-2">
        <div
          v-tooltip:top="template.title"
          class="thumbnail"
          @click="importTemplate(template)"
        >
          <img v-if="template.previewImage" :src="template.previewImage" />
          <img
            v-else
            src="@/images/custom-template.svg"
            class="custom-template"
          />
          <div class="caption text-center">{{ template.title }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Templates from './BuilderTemplates/Templates'
import * as Toastr from 'toastr'
import moment from 'moment'

import alert from '@/libs/Alert'
import VueDialog from '@/libs/VueDialog'
import ImportMessages from './Dialog/ImportMessages'
import XpApi from '@/libs/XpApi'

export default {
  inject: ['workflowApi', 'contentApi'],
  props: ['action'],

  data() {
    return {
      importing: false,
      url: '',
      standardTemplates: Templates,
      customTemplates: [],
      search: ''
    }
  },

  computed: {
    builderTemplates() {
      let templates = JSON.parse(JSON.stringify(this.standardTemplates))

      this.$store.state.project.builderTemplates.forEach((template) => {
        templates.push(template)
      })

      return templates.filter((template) => {
        return (
          template.title.toLowerCase().indexOf(this.search.toLowerCase()) != -1
        )
      })
    },

    formatTemplateTooltip() {
      const whoApplied = this.action.config.whoApplied
      const lastApplied = moment(this.action.config.lastApplied).format(
        'DD/MMM/YYYY HH:mm'
      )
      return `Applied by ${whoApplied} on ${lastApplied}`
    },

    formatTemplateInfo() {
      if (this.action.config.templateId === 0) {
        return `Created from template: ${this.action.config.templateTitle}`
      }

      for (let template of this.builderTemplates) {
        if (this.action.config.templateId === template.id) {
          return `Created from template: ${template.title}`
        }
      }

      return 'Applied template has been deleted'
    }
  },

  created() {
    this.$store.dispatch('fetchBuilderTemplates')
  },

  methods: {
    displayErrorDialog(msg) {
      console.error(msg)
      Toastr.error(msg)
    },

    onDragOver(e) {
      e.stopPropagation()
      e.preventDefault()
      e.currentTarget.classList.add('dropzone-active')
    },

    onDragLeave(e) {
      e.stopPropagation()
      e.preventDefault()
      e.currentTarget.classList.remove('dropzone-active')
    },

    onDragDrop(e) {
      e.stopPropagation()
      e.preventDefault()
      e.currentTarget.classList.remove('dropzone-active')
      this.processFileImport(e.dataTransfer.files[0])
    },

    importUrl() {
      this.importing = true
      this.contentApi
        .importUrl(this.url)
        .then((contentModel) => {
          if (contentModel.meta.messages.length) {
            VueDialog.show(ImportMessages, {
              messages: contentModel.meta.messages
            })
          }

          this.workflowApi
            .updateAction(this.action.id, { contentId: contentModel.contentId })
            .then(() => {
              this.$nextTick(() => {
                this.$parent.$parent.activeTab = 'editor'
                this.importing = false
              })
            })
        })
        .catch(({ data }) => {
          this.importing = false
          alert.error(data.title)
        })
    },

    importFile(e) {
      this.processFileImport(e.target.files[0])
      e.target.value = ''
    },

    processFileImport(file) {
      if (!file) {
        this.displayErrorDialog('Failed to handle file upload.')
        return
      } else if (file.type !== 'text/html') {
        this.displayErrorDialog(
          `Wrong File Format '${file.type}'. Please provide a HTML file.`
        )
        return
      } else if (file.size > 250000) {
        this.displayErrorDialog('Specified file is too large.')
        return
      }

      const reader = new FileReader()
      reader.onloadend = (evt) => {
        if (evt.target.readyState !== FileReader.DONE) {
          return
        }

        const content = evt.target.result
        this.applyTemplate(content)
      }

      reader.readAsBinaryString(file.slice(0, file.size))
    },

    importTemplate(template) {
      if (!template.id && template.template) {
        this.applyTemplate('', template.template, 0, template.title)
        return
      }

      const projectId = this.$store.state.app.project.id
      XpApi.get(`projects/${projectId}/campaign-templates/${template.id}`, {
        params: { fields: 'id,title', expand: 'message' }
      }).then(({ data: template }) => {
        this.applyTemplate(
          template.message.email_html,
          template.message.email_template,
          template.id,
          template.title
        )
      })
    },

    applyTemplate(html, template, id, title) {
      let doApply = function (html, template) {
        let saveContent = template
          ? this.contentApi.saveBuilder(html, JSON.parse(template))
          : this.contentApi.saveEditor(html)

        saveContent.then(({ meta, contentId }) => {
          if (meta.messages.length) {
            VueDialog.show(ImportMessages, { messages: meta.messages })
          }
          this.workflowApi
            .updateAction(this.action.id, {
              contentId: contentId,
              config: Object.assign({}, this.action.config, {
                templateId: id,
                templateTitle: title,
                whoApplied: this.$store.state.app.user.email,
                lastApplied: new Date().getTime()
              })
            })
            .then(() => {
              document.body.style.pointerEvents = 'none'
              if (id === undefined) {
                Toastr.success('HTML loaded successfully')
              }
              this.$nextTick(() => (this.$parent.$parent.activeTab = 'editor'))
            })
        })
      }.bind(this)

      if (this.action.contentId === 0) {
        // If there is no existing content - apply the template
        doApply(html, template)
        return
      }

      // If there is content, show a modal and only apply template if user clicks continue.
      alert.show({
        confirm: true,
        type: 'warning',
        okText: 'Continue',
        title: 'Apply Template',
        message:
          'This will replace the existing content. Do you wish to continue?',
        onOk: (resolve) => {
          doApply(html, template)
          resolve()
        }
      })
    }
  }
}
</script>
<style lang="sass" scoped>
.template-text
    margin-bottom: 10px

.dropzone
    text-align: center
    border: 2px dashed #ddd
    padding: 15px
    border-radius: 5px

.dropzone-active
    border: 2px dashed #888

.email-upload
    padding: 15px
    margin-bottom: 50px
    height: 100%
    overflow: auto

    h3
        margin-top: 0
        color: #628DBF
        font-size: 18px

    h4
        font-weight: bold
        font-size: 14px

.thumbnail
    cursor: pointer
    padding: 0
    overflow: hidden

    .caption
        background-color: #ddd
        white-space: nowrap
        overflow: hidden
        display: block
        text-overflow: ellipsis

    &:hover
        border-color: #9B9B9B

    .custom-template
        width: 100%
        height: 134px
</style>
