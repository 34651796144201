export default Object.freeze({
  pusher: {
    appKey: '',
    cluster: 'eu'
  },

  xpBaseUrl: '',
  xpApiBaseUrl: '',

  beeSaveRows: false
})
