<template>
  <ul>
    <li>
      <icon v-tooltip:left="$t('actionTooltipSent')" glyph="sms" />
      {{ numberFilter(stats['Sms:sent']) }}
    </li>
  </ul>
</template>
<script>
import { numberFilter } from '@/helpers/filters'

export default {
  props: ['stats'],

  data() {
    return {
      numberFilter
    }
  }
}
</script>
