<script>
import Action from './AbstractAction'

export default {
  extends: Action,
  data() {
    return {
      icon: 'sms'
    }
  }
}
</script>
