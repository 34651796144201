export default {
  computed: {
    isBetssonAvailable() {
      return this.$store.getters.isFeatureEnabled(
        'nationalExclusionCheckBetsson'
      )
    },
    isOasisAvailable() {
      return this.$store.getters.isFeatureEnabled('nationalExclusionCheckOasis')
    },
    betssonOptions() {
      return [
        {
          value: 0,
          title: this.$t('nationalExclusionCategoriesMarketing'),
          titleSelected: this.$t('nationalExclusionCategoriesMarketingTechsson')
        },
        {
          value: 1,
          title: this.$t('nationalExclusionCategoriesService'),
          titleSelected: this.$t('nationalExclusionCategoriesServiceTechsson')
        },
        {
          value: 2,
          title: this.$t('nationalExclusionCategoriesGeneral'),
          titleSelected: this.$t('nationalExclusionCategoriesGeneralTechsson')
        }
      ]
    },
    oasisOptions() {
      return [
        {
          value: 10,
          title: this.$t('nationalExclusionCategoriesMarketing'),
          titleSelected: this.$t('nationalExclusionCategoriesMarketingOasis')
        },
        {
          value: 11,
          title: this.$t('nationalExclusionCategoriesMarketingBonus'),
          titleSelected: this.$t('nationalExclusionCategoriesMarketingBonusOasis')
        }
      ]
    },
    isExclusionEnabled() {
      return (
        this.$store.getters.isFeatureEnabled('nationalExclusionCheckBetsson') ||
        this.$store.getters.isFeatureEnabled('nationalExclusionCheckOasis')
      )
    }
  },
  methods: {
    getNecLabelByValue(value) {
      let categories = []
      if (this.isBetssonAvailable) {
        categories = this.betssonOptions
      }
      if (this.isOasisAvailable) {
        categories = [...categories, ...this.oasisOptions]
      }
      let category = categories.filter((c) => {
        return c.value === parseInt(value)
      })
      if (category[0]) {
        if (this.isBetssonAvailable && this.isOasisAvailable) {
          return category[0].titleSelected
        } else {
          return category[0].title
        }
      }
    }
  }
}
