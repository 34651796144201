<template>
  <div class="page">
    <div class="page-title">Project Admin</div>
    <ul class="page-nav">
      <li>
        <router-link
          :to="{ name: 'ProjectAdminProfiles', params: { projectId } }"
        >
          Profiles
        </router-link>
      </li>
      <li>
        <router-link
          :to="{ name: 'ProjectAdminAuditLog', params: { projectId } }"
        >
          Audit Log
        </router-link>
      </li>
    </ul>
    <div class="page-content">
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </div>
  </div>
</template>
<script>
export default {
  props: ['projectId']
}
</script>
