<template>
  <ul>
    <li>
      <icon v-tooltip:left="$t('actionTooltipArrived')" glyph="user" />
      {{ numberFilter(stats['Push:trigger']) }}
    </li>
    <li>
      <icon v-tooltip:left="$t('actionTooltipSent')" glyph="push" />
      {{ numberFilter(stats['Push:sent']) }}
    </li>
    <li>
      <icon v-tooltip:left="$t('actionTooltipOpened')" glyph="mouse" />
      {{ numberFilter(stats['Push:openedUnique']) }}
    </li>
  </ul>
</template>
<script>
import { numberFilter } from '@/helpers/filters'

export default {
  props: ['stats'],

  data() {
    return {
      numberFilter
    }
  }
}
</script>
