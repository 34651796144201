import TrendLineGenerator from '@/libs/TrendLineGenerator'

export default {
  methods: {
    getTrendLine(data) {
      const fitter = new TrendLineGenerator()
      data.forEach((val, idx) => fitter.add(idx, val))

      return fitter.getLine()
    }
  }
}
