import Pusher from 'pusher-js'
import MxaApi from '@/libs/MxaApi'

import config from '@/config/config'

const pusher = new Pusher(config.pusher.appKey, {
  cluster: config.pusher.cluster,
  encrypted: true,
  authorizer: (channel) => {
    return {
      authorize: (socketId, callback) => {
        MxaApi.post('pusher/auth', { socketId, channelName: channel.name })
          .then(({ data }) => {
            callback(false, data)
          })
          .catch((error) => {
            callback(true, error)
          })
      }
    }
  }
})

export default pusher
