<template>
  <multiselect
    :value="selectedValue"
    :options="promotions"
    track-by="id"
    label="name"
    :disabled="disabled"
    :placeholder="placeholder"
    @input="updateValue"
  >
    <template #option="{ option }">
      <span>
        <span
          v-if="showExternalSystem && option.external_system != ''"
          class="external-system-label"
        >
          {{ option.external_system }} -
        </span>
        {{ option.name }}
      </span>
    </template>
    <template #singleLabel="{ option }">
      <span>
        <span
          v-if="showExternalSystem && option.external_system != ''"
          class="external-system-label"
        >
          {{ option.external_system }} -
        </span>
        {{ option.name }}
      </span>
    </template>
  </multiselect>
</template>

<script>
export default {
  props: {
    value: {
      type: Number
    },
    promotions: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      selectedValue: null
    }
  },

  computed: {
    placeholder() {
      return this.isLoading
        ? this.$t('loadingPlaceholder')
        : this.$t('selectOptionPlaceholder')
    },

    showExternalSystem() {
      return (
        [
          ...new Set(
            this.promotions.map((promotion) => promotion.external_system)
          )
        ].length > 1
      )
    }
  },

  watch: {
    value() {
      this.setValue()
    }
  },

  mounted() {
    this.setValue()
  },

  methods: {
    promotionLabel(promotionId) {
      const promo = this.promotions.find(
        (promotion) => promotion.id === promotionId
      )
      let externalSystem = ''
      if (this.showExternalSystem && promo !== undefined) {
        externalSystem = promo.external_system
      }
      return {
        name: promo === undefined ? promotionId : promo.name,
        external_system: externalSystem
      }
    },

    updateValue: function (value) {
      if (value) {
        this.$emit('input', value.id)
      } else {
        this.$emit('input', null)
      }
    },

    setValue() {
      if (this.value) {
        this.selectedValue = this.promotions.find(
          (promotion) => promotion.id === this.value
        )
      } else {
        this.selectedValue = null
      }
    }
  }
}
</script>
<style lang="sass">
.external-system-label
    text-transform: capitalize
</style>
