import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import app from './App'
import project from './Project'

const store = new Vuex.Store({
  modules: {
    app,
    project
  }
})

export default store
