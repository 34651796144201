<template>
  <div class="redactor-wrapper">
    <textarea
      :ref="refId"
      v-model="value"
      :name="name"
      :placeholder="placeholder"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import 'xp-fork-redactor2/redactor.js'
import { uuid } from '@/libs/Helpers'
export default {
  name: 'Redactor',
  props: {
    value: {
      default: '',
      type: String
    },
    placeholder: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    customConfig: {
      default: () => {},
      type: Object
    },
    plugins: {
      type: Array,
      default: () => [],
      required: false
    },
    buttons: {
      type: Array,
      default: () => [],
      required: false
    },
    useDefaultButtons: {
      type: Boolean,
      default: true
    },
    useDefaultPlugins: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      config: {},
      redactor: false,
      defaultPlugins: ['cleanformat', 'fontsize', 'fontfamily'],
      defaultButtons: ['bold', 'italic', 'underline']
    }
  },
  computed: {
    refId() {
      return `redactor-${uuid()}`
    }
  },
  watch: {
    value: {
      handler(newVal) {
        if (!$(this.$refs[this.refId]).redactor('core.editor').is(':focus')) {
          $(this.$refs[this.refId]).redactor('code.set', newVal)
        }
      },
      deep: true
    }
  },
  created() {
    this.config = { ...this.customConfig }
    this.config.toolbar = !this.disabled
    this.config.buttons = [
      ...(this.useDefaultButtons ? this.defaultButtons : []),
      ...this.buttons
    ]
    this.config.plugins = [
      ...(this.useDefaultPlugins ? this.defaultPlugins : []),
      ...this.plugins
    ]
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
    this.destroy()
  },
  methods: {
    init() {
      const callbacks = {
        change: (html) => {
          this.handleInput(html)
          return html
        }
      }

      // extend config
      if (typeof this.config.callbacks === 'undefined') {
        this.$set(this.config, 'callbacks', callbacks)
      } else {
        this.config.callbacks.change = callbacks.change
      }

      // call Redactor
      const app = $(this.$refs[this.refId]).redactor(this.config)
      // set instance
      this.redactor = app
      this.$parent.redactor = app
      if (this.disabled) {
        $(this.$refs[this.refId]).redactor('core.disableEditor')
      }
    },
    destroy() {
      $(this.$refs[this.refId]).redactor('core.destroy')
      this.redactor = null
      this.$parent.redactor = null
    },
    handleInput(val) {
      this.$emit('input', val)
    }
  }
}
</script>

<style scoped lang="scss">
.redactor-wrapper {
  ::v-deep .redactor-toolbar {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: 1px solid #dcdfe6;
  }

  ::v-deep .redactor-layer {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid #dcdfe6;
    border-top: none;
  }
}
::v-deep .personalizationPopup,
::v-deep .snippetPopup {
  display: none;
  position: absolute;
  width: 300px;
  left: auto !important;
  top: 35px;
  overflow: hidden;
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.25);
  margin: 2px;
  background: #fff;
  min-width: 150px;
  border-radius: 4px;
  border: 1px solid #ebeef5;
  padding: 12px;
  //z-index: 2000;
  color: #606266;
  line-height: 1.4;
  text-align: justify;
  font-size: 14px;
  word-break: break-all;
}

::v-deep .emojisEditor .emojisPopup {
  display: none;
  position: absolute;
  z-index: 2002;
  width: 310px;
  right: -1px;
  top: 35px;
  overflow: hidden;
  background: white;
}

::v-deep .snippet-text-editor textarea {
  margin: 0 !important;
  border: 1px solid #c2c4c6;
}

::v-deep .contentEditor {
  position: relative;
}

::v-deep .emojisEditor .emojisPopup {
  display: none;
  position: absolute;
  z-index: 2002;
  width: 310px;
  right: -1px;
  top: 35px;
  overflow: hidden;
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.25);
  background: #fff;
}

::v-deep .emojisEditor .emojisPopup .emojisMenu {
  background: #eaeaea;
}

::v-deep .emojisEditor .emojisPopup .emojisItem {
  display: inline-block;
  font-size: 16px;
  text-align: center;
  width: 30px;
  margin: 4px;
  cursor: pointer;
}

::v-deep .emojisEditor .emojisPopup .emojisCategory {
  height: 160px;
  overflow-x: hidden;
  overflow-y: auto;
}

::v-deep .emojisEditor .emojisPopup .emojisTitle {
  margin: 4px;
  font-weight: 700;
  color: #666;
}

::v-deep .emojisEditor .emojisPopup .emojisItem {
  display: inline-block;
  font-size: 16px;
  text-align: center;
  width: 30px;
  margin: 4px;
  cursor: pointer;
}

::v-deep .personalizationEditor .personalizationPopup {
  display: none;
  position: absolute;
  z-index: 2002;
  width: fit-content;
  right: -1px;
  top: 35px;
  overflow: hidden;
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.25);
  margin: 2px;
  background: #fff;
}

::v-deep .personalizationEditor .personalizationPopupRemoveButton {
  position: absolute;
  top: 6px;
  right: 6px;
  font-size: 16px;
  cursor: pointer;
}
::v-deep .personalizationEditor .personalizationPopupRemoveButton {
  position: absolute;
  top: 6px;
  right: 6px;
  font-size: 16px;
  cursor: pointer;
}

::v-deep .contentEditor.snippetEditor textarea {
  height: 200px;
  width: 100%;
  box-sizing: border-box;
}

::v-deep input[type='email'],
::v-deep input[type='number'],
::v-deep input[type='password'],
::v-deep input[type='search'],
::v-deep input[type='text'],
::v-deep input[type='url'] {
  background: #fff;
  cursor: text;
  outline: none;
  min-width: 250px;
  -webkit-appearance: none;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266 !important;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  padding: 0 15px !important;
}

::v-deep .snippetEditor .snippetPopup {
  display: none;
  position: absolute;
  z-index: 2002;
  width: fit-content;
  right: -1px;
  top: 35px;
  overflow: hidden;
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.25);
  padding: 12px;
  background: #fff;
}

::v-deep .contentEditor .contentEditorToolbar {
  position: absolute;
  z-index: 1100;
  top: 1px;
  right: 15px;
  height: 50px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 0 !important;
}
::v-deep .contentEditor .contentEditorToolbar i {
  color: #111;
  cursor: pointer;
  font-size: 12px;
}

::v-deep .contentEditor .contentEditorToolbar span {
  color: #111;
  cursor: pointer;
  font-size: 15px;
}

::v-deep .icon {
  ::v-deep .contenteditor.contented {
    display: inline-block;
  }
  overflow: hidden;
}

::v-deep .snippetEditor .snippetPopupRemoveButton {
  position: absolute;
  top: 6px;
  right: 6px;
  font-size: 16px;
  cursor: pointer;
}

::v-deep input:hover[type='password'],
::v-deep input:hover[type='text'],
::v-deep select:hover,
::v-deep textarea:hover {
  border-color: #1d87c8 !important;
}

::v-deep .stdTextarea {
  display: block;
  margin: 0;
  background: #fff;
  border: 1px solid #ccc;
  outline: none;
  color: #717782;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
}
::v-deep textarea {
  width: 100%;
  resize: vertical;
  max-height: 300px;
  min-height: 200px;
  background: rgba(0, 0, 0, 0.7);
  border: none;
  padding: 10px;
  margin: 10px 0;
  font: 12pt/12pt Open Sans, sans-serif;
}
::v-deep .label1 {
  color: #5c6374;
  font-size: 14px;
  margin-bottom: 5px;
  display: inline-block;
}
</style>
