<template>
  <el-select
    v-model="selectValue"
    :placeholder="placeholder"
    :disabled="disabled"
    :filterable="filterable"
  >
    <el-option v-if="nullValue" :value="null" label="" />
    <el-option
      v-for="(optionLabel, optionValue) in options"
      :key="optionValue"
      :value="formatOptionValue(optionValue)"
      :label="formatOptionLabel(optionLabel)"
      :title="title ? formatOptionLabel(optionLabel) : ''"
    />
  </el-select>
</template>

<script>
export default {
  name: 'EnumSelect',

  props: {
    value: {
      required: false
    },

    number: {
      type: Boolean,
      default: false
    },

    options: {
      type: Object,
      required: true
    },

    placeholder: {
      type: String,
      required: false
    },

    translate: {
      type: Boolean,
      required: false,
      default: true
    },

    nullValue: {
      type: Boolean,
      required: false,
      default: false
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false
    },

    title: {
      type: Boolean,
      default: false
    },

    filterable: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    selectValue: {
      get() {
        return this.value
      },

      set(selectValue) {
        this.$emit('input', selectValue)
      }
    }
  },

  methods: {
    formatOptionValue(optionValue) {
      if (this.number) {
        return Number(optionValue)
      } else {
        return optionValue
      }
    },

    formatOptionLabel(optionLabel) {
      return this.translate ? this.$t(optionLabel) : optionLabel
    }
  }
}
</script>
