/*global require */
import { merge } from 'lodash'
import defaultConfig from './default'
import localConfig from './local'

let config = {}
merge(config, defaultConfig)
merge(config, localConfig)

export default Object.freeze(config)
