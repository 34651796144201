import MxaApi from '@/libs/MxaApi'

const MAX_ENTRIES = 20

class ContentApi {
  constructor(projectId) {
    this.requestCache = []
    this.projectId = projectId
  }

  cacheRequest(request) {
    this.requestCache.push(request)
    if (this.requestCache.length > MAX_ENTRIES) {
      this.requestCache.unshift()
    }
  }

  load(contentId) {
    let request = this.requestCache.find(
      (cache) => cache.contentId === contentId
    )

    if (request) return request

    request = MxaApi.get(
      `projects/${this.projectId}/content/${contentId}`
    ).then(({ data: contentModel }) => {
      request.contentId = contentModel.contentId

      return contentModel
    })
    this.cacheRequest(request)

    return request
  }

  save(content, meta = {}) {
    const data = {
      content,
      meta
    }

    const request = MxaApi.post(
      `projects/${this.projectId}/content`,
      data
    ).then(({ data: contentModel }) => {
      request.contentId = contentModel.contentId

      return contentModel
    })

    this.cacheRequest(request)

    return request
  }

  saveEditor(html) {
    return this.save(html, { editor: 'editor' })
  }

  saveBuilder(html, template) {
    return this.save(html, { editor: 'builder', template: template })
  }

  builderAuth() {
    return MxaApi.post(`projects/${this.projectId}/content/builder-auth`).then(
      ({ data: token }) => token
    )
  }

  importUrl(url) {
    const request = MxaApi.post(
      `projects/${this.projectId}/content/import-url`,
      { url }
    ).then(({ data: contentModel }) => {
      request.contentId = contentModel.contentId

      return contentModel
    })

    this.cacheRequest(request)

    return request
  }
}

export default ContentApi
