/*global require */
function makeTemplate(name) {
  const file = name.replace(/\s/g, '-')
  return {
    title: name,
    previewImage: require(`./files/${file}.png`),
    html: '',
    template: JSON.stringify(require(`./files/${file}.json`))
  }
}

const Templates = [
  makeTemplate('Blank'),
  makeTemplate('Simple'),
  makeTemplate('Basic'),
  makeTemplate('Promo'),
  makeTemplate('Basic Newsletter'),
  makeTemplate('Newsletter'),
  makeTemplate('Basic e-commerce')
]

export default Templates
