export default (literals, ...values) => {
  return literals.reduce((accumulator, literal, index) => {
    accumulator += literal

    if (index in values) {
      accumulator += encodeURIComponent(values[index])
    }

    return accumulator
  }, '')
}
