<template>
  <div>
    <action-icon :action-type="metadata.actionType" />
    Changed
    <b>{{ fields }}</b>
    for {{ metadata.actionType }} action
    <b>{{ metadata.actionName | truncate(30) }}</b>
    on workflow
    <b>{{ log.workflowId }}</b>
  </div>
</template>
<script>
export default {
  props: ['log', 'metadata'],

  computed: {
    fields() {
      const filter = ['validatorType']

      const mapping = {
        successors: 'connections',
        pos: 'position',
        contentId: 'content',
        validator: 'conditions',
        fromName: 'from name',
        fromAddress: 'from address',
        replyToName: 'reply to name',
        replyToAddress: 'reply to address',
        analyticsCode: 'analytics code'
      }

      return this.metadata.eventData
        .filter((val) => !filter.includes(val))
        .map((val) => mapping[val] || val)
        .join(', ')
    }
  }
}
</script>
