<template>
  <multiselect
    :value="selectedOption"
    :options="options"
    :disabled="disabled"
    :placeholder="$t('selectOptionPlaceholder')"
    track-by="id"
    label="label"
    select-label=""
    selected-label=""
    deselect-label=""
    :option-height="36"
    :searchable="false"
    :allow-empty="false"
    @input="updateValue"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: Number
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      options: [
        { id: 1, label: this.$t('selectEncryptionTypeUseWherePossible') },
        { id: 2, label: this.$t('selectEncryptionTypeEnforceEncryption') }
      ]
    }
  },

  computed: {
    selectedOption: function () {
      return this.options.find(({ id }) => id === this.value)
    }
  },
  methods: {
    updateValue: function (value) {
      this.$emit('input', value.id)
    }
  }
}
</script>
