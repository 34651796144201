<template>
  <div>
    <label>{{ $t('delayToolboxSmallRelativeDateLabel') }}</label>
    <div class="form-group">
      <time-period
        :value="action.options.interval"
        :disabled="readOnly"
        @input="updateInterval"
      />
    </div>
    <div class="form-group">
      <select2
        :value="action.options.beforeOrAfter"
        :disabled="readOnly"
        class="form-control"
        @input="updateBeforeAfter"
      >
        <option value="before">{{ $t('delayToolboxSmallRelativeDateSelectOptionBefore') }}</option>
        <option value="after">{{ $t('delayToolboxSmallRelativeDateSelectOptionAfter') }}</option>
      </select2>
    </div>
    <div class="form-group">
      <select2
        :value="action.options.relativeTo || 'profileAttribute'"
        :disabled="readOnly"
        class="form-control"
        @input="updateRelativeTo"
      >
        <option value="profileAttribute">{{ $t('delayToolboxSmallRelativeDateSelectOptionProfileAttribute') }}</option>
        <option value="eventAttribute">{{ $t('delayToolboxSmallRelativeDateSelectOptionEventProperty') }}</option>
      </select2>
    </div>
    <div
      v-if="action.options.relativeTo === 'eventAttribute'"
      class="form-group"
    >
      <input
        type="text"
        class="form-control"
        :placeholder="$t('delayToolboxSmallRelativeDateEventPropertyInputPlaceholder')"
        :value="action.options.eventAttribute"
        :disabled="readOnly"
        @change="updateEventAttribute"
      />
    </div>
    <div v-else class="form-group">
      <profile-attributes
        :value="action.options.profileAttribute"
        :options="profileAttributes"
        :disabled="readOnly"
        @input="updateProfileAttribute"
      />
    </div>
    <allowed-resume-window-section
      :action="action"
      :read-only="readOnly"
      @saveSafeTime="updateAllowedResumeWindow"
    />
  </div>
</template>
<script>
import TimePeriod from '@/components/SimpleTimePeriod'
import ProfileAttributes from '@/components/ProfileAttributes'
import AllowedResumeWindowSection from './AllowedResumeWindowSection'

export default {
  components: {
    AllowedResumeWindowSection,
    TimePeriod,
    ProfileAttributes
  },
  inject: ['workflowApi'],
  props: ['action', 'readOnly'],

  computed: {
    profileAttributes() {
      return this.$store.state.project.attributes
    }
  },

  methods: {
    saveChanges(changes) {
      this.workflowApi.updateAction(this.action.id, {
        options: Object.assign({}, this.action.options, changes)
      })
    },

    updateAllowedResumeWindow(startTime, endTime) {
      this.saveChanges({
        startSafeTime: startTime,
        endSafeTime: endTime
      })
    },

    updateInterval(interval) {
      this.saveChanges({
        interval
      })
    },

    updateBeforeAfter(value) {
      this.saveChanges({
        beforeOrAfter: value
      })
    },

    updateProfileAttribute(profileAttribute) {
      this.saveChanges({
        profileAttribute
      })
    },

    updateRelativeTo(relativeTo) {
      this.saveChanges({
        relativeTo
      })
    },

    updateEventAttribute(event) {
      this.saveChanges({
        eventAttribute: event.target.value
      })
    }
  }
}
</script>
