import MxaApi from '@/libs/MxaApi'
import Vue from 'vue'
import XpApi from '@/libs/XpApi'
import XpApiPaginator from '@/libs/XpApiPaginator'
import memoize from 'memoizee'

const fetchPages = memoize(
  (uid, start, limit, paginator) =>
    paginator.fetchPages({ start: start, limit: limit }),
  { promise: true, length: 3, maxAge: 10000 }
)

const fetchAttributes = memoize(
  (projectId) =>
    XpApi.get(`projects/${projectId}/attributes`).then(({ data }) => data),
  {
    promise: true,
    maxAge: 10000
  }
)

const fetchProfileAttributes = memoize(
  (projectId) =>
    XpApi.get(`projects/${projectId}/attributes`, {
      params: { attribute_profile_or_aggregate: 1 }
    }).then(({ data }) => data),
  {
    promise: true,
    maxAge: 10000
  }
)

const fetchNumberProfileAttributes = memoize(
  (projectId) =>
    XpApi.get(`projects/${projectId}/tags`, {
      params: { sort: 'title', attribute_profile: 1, type: '1,5' }
    }).then(({ data }) => data),
  {
    promise: true,
    maxAge: 10000
  }
)

const fetchSegments = memoize(
  (projectId) =>
    XpApi.get(`projects/${projectId}/segments`).then(({ data }) => data),
  {
    promise: true,
    maxAge: 10000
  }
)

const fetchSenderDetails = memoize(
  (projectId) =>
    XpApi.get(`projects/${projectId}/email-addresses`).then(({ data }) => data),
  {
    promise: true,
    maxAge: 10000
  }
)

const fetchPromotions = memoize(
  (projectId) =>
    XpApi.get(`projects/${projectId}/promotions`).then(({ data }) => data),
  {
    promise: true,
    maxAge: 10000
  }
)

const fetchExternalAudience = memoize(
  (projectId) =>
    XpApi.get(`projects/${projectId}/externalaudience`, {
      params: { is_deleted: 0 }
    }).then(({ data }) => data),
  {
    promise: true,
    maxAge: 10000
  }
)

const fetchWorkflowPromotions = memoize(
  (projectId) =>
    XpApi.get(`projects/${projectId}/promotions/workflow`).then(
      ({ data }) => data
    ),
  {
    promise: true,
    maxAge: 10000
  }
)

const fetchProjects = memoize(
  () => XpApi.get(`projects`).then(({ data }) => data),
  {
    promise: true,
    maxAge: 10000
  }
)

export default {
  namespace: true,

  state: {
    attributes: [],
    profileAttributes: [],
    numberProfileAttributes: [],
    segments: [],
    sharedSegments: [],
    events: [],
    builderTemplates: [],
    templates: {},
    senderDetails: [],
    promotions: [],
    externalAudience: [],
    workflowPromotions: [],
    projects: []
  },

  getters: {
    getTemplates: (state) => (messageType) => {
      return state.templates[messageType] || []
    }
  },
  mutations: {
    setAttributes(state, attributes) {
      state.attributes = attributes
    },

    setProfileAttributes(state, profileAttributes) {
      state.profileAttributes = profileAttributes
    },

    setNumberProfileAttributes(state, numberProfileAttributes) {
      state.numberProfileAttributes = numberProfileAttributes
    },

    setSegments(state, segments) {
      state.segments = segments
    },

    setSharedSegments(state, sharedSegments) {
      state.sharedSegments = sharedSegments
    },

    setSenderDetails(state, senderDetails) {
      state.senderDetails = senderDetails
    },

    setPromotions(state, promotions) {
      state.promotions = promotions
    },

    setExternalAudience(state, externalaudience) {
      state.externalAudience = externalaudience
    },

    setWorkflowPromotions(state, workflowPromotions) {
      state.workflowPromotions = workflowPromotions
    },

    setBuilderTemplates(state, builderTemplates) {
      state.builderTemplates = builderTemplates
    },

    setTemplates(state, { templates, message_type }) {
      Vue.set(state.templates, message_type, templates)
    },

    setProjects(state, projects) {
      state.projects = projects
    }
  },

  actions: {
    fetchProjects: async ({ commit, rootState }) => {
      const projects = await fetchProjects()
      commit('setProjects', projects)

      return projects
    },

    fetchAttributes: async ({ commit, rootState }) => {
      const attributes = await fetchAttributes(rootState.app.project.id)
      commit('setAttributes', attributes)

      return attributes
    },

    fetchProfileAttributes: async ({ commit, rootState }) => {
      const profileAttributes = await fetchProfileAttributes(
        rootState.app.project.id
      )
      commit('setProfileAttributes', profileAttributes)

      return profileAttributes
    },

    fetchNumberProfileAttributes: async ({ commit, rootState }) => {
      const numberProfileAttributes = await fetchNumberProfileAttributes(
        rootState.app.project.id
      )
      commit('setNumberProfileAttributes', numberProfileAttributes)

      return numberProfileAttributes
    },

    fetchSegments: async ({ commit, rootState }) => {
      let paginator = new XpApiPaginator({
        url: `projects/${rootState.app.project.id}/segments`
      })
      let segments = await fetchPages(
        `segments-${rootState.app.project.id}`,
        1,
        null,
        paginator
      )
      if (segments == null) {
        return
      }
      commit('setSegments', segments)
      return segments
    },

    fetchSharedSegments: async ({ commit, rootState }) => {
      let paginator = new XpApiPaginator({
        url: `projects/${rootState.app.project.id}/shared-segments`
      })
      let sharedSegments = await fetchPages(
        `shared-segments-${rootState.app.project.id}`,
        1,
        null,
        paginator
      )
      if (sharedSegments == null) {
        return
      }
      commit('setSharedSegments', sharedSegments)
      return sharedSegments
    },

    fetchSenderDetails: async ({ commit, rootState }) => {
      const senderDetails = await fetchSenderDetails(rootState.app.project.id)
      commit('setSenderDetails', senderDetails)

      return senderDetails
    },

    fetchPromotions: async ({ commit, rootState }) => {
      const promotions = await fetchPromotions(rootState.app.project.id)
      commit('setPromotions', promotions)

      return promotions
    },

    fetchExternalAudience: async ({ commit, rootState }) => {
      const externalaudience = await fetchExternalAudience(
        rootState.app.project.id
      )
      commit('setExternalAudience', externalaudience)

      return externalaudience
    },

    fetchWorkflowPromotions: async ({ commit, rootState }) => {
      const workflowPromotions = await fetchWorkflowPromotions(
        rootState.app.project.id
      )
      commit('setWorkflowPromotions', workflowPromotions)

      return workflowPromotions
    },

    fetchTemplates: async ({ commit, rootState }, message_type) => {
      if (!message_type) {
        return []
      }
      const projectId = rootState.app.project.id
      let paginator = new XpApiPaginator({
        url: `projects/${projectId}/campaign-templates`,
        params: { fields: 'id,title', message_type: message_type, is_active: 1 }
      })
      let templates = await fetchPages(
        `campaign-templates-${rootState.app.project.id}-${message_type}`,
        1,
        null,
        paginator
      )

      commit('setTemplates', { templates, message_type })

      return templates
    },

    fetchBuilderTemplates: async ({ commit, rootState }) => {
      const projectId = rootState.app.project.id

      let paginator = new XpApiPaginator({
        url: `projects/${projectId}/campaign-templates`,
        params: { fields: 'id,title', message_type: 7, is_active: 1 }
      })
      let templates = await fetchPages(
        `campaign-templates-${rootState.app.project.id}`,
        1,
        null,
        paginator
      )

      commit('setBuilderTemplates', templates)

      return templates
    }
  }
}
