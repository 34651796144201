<template>
  <div style="height: 100%; overflow-y: scroll; padding: 30px">
    <label>{{ $t('actionReportingSplitLabel') }}</label>
    <div class="stats-bar">
      <div class="stats-bar-item">
        <icon class="stats-bar-item-icon" glyph="user" />
        <div class="stats-bar-item-title">
          {{ $t('actionReportingLineChartArrived') }}
        </div>
        <div class="stats-bar-item-number">
          {{ numberFilter(arrivedTotal) }}
        </div>
      </div>
      <div class="stats-bar-item">
        <div
          class="stats-bar-item-icon"
          style="background-color: #76a9ca; color: #fff"
        >
          {{ $t('actionReportingSplitA') }}
        </div>
        <div class="stats-bar-item-title">
          {{ $t('actionReportingSplitNumberToA') }}
        </div>
        <div class="stats-bar-item-number">
          {{ numberFilter(aTotal) }}
          ({{ percentageOfFilter(aTotal, arrivedTotal) }})
        </div>
      </div>
      <div class="stats-bar-item">
        <div
          class="stats-bar-item-icon"
          style="background-color: #b4d688; color: #fff"
        >
          {{ $t('actionReportingSplitB') }}
        </div>
        <div class="stats-bar-item-title">
          {{ $t('actionReportingSplitNumberToB') }}
        </div>
        <div class="stats-bar-item-number">
          {{ numberFilter(bTotal) }}
          ({{ percentageOfFilter(bTotal, arrivedTotal) }})
        </div>
      </div>
      <div v-if="cTotal" class="stats-bar-item">
        <div
          class="stats-bar-item-icon"
          style="background-color: #ee78bf; color: #fff"
        >
          {{ $t('actionReportingSplitC') }}
        </div>
        <div class="stats-bar-item-title">
          {{ $t('actionReportingSplitNumberToC') }}
        </div>
        <div class="stats-bar-item-number">
          {{ numberFilter(cTotal) }}
          ({{ percentageOfFilter(cTotal, arrivedTotal) }})
        </div>
      </div>
      <div v-if="dTotal" class="stats-bar-item">
        <div
          class="stats-bar-item-icon"
          style="background-color: #696d8d; color: #fff"
        >
          {{ $t('actionReportingSplitD') }}
        </div>
        <div class="stats-bar-item-title">
          {{ $t('actionReportingSplitNumberToD') }}
        </div>
        <div class="stats-bar-item-number">
          {{ numberFilter(dTotal) }}
          ({{ percentageOfFilter(dTotal, arrivedTotal) }})
        </div>
      </div>
      <div v-if="eTotal" class="stats-bar-item">
        <div
          class="stats-bar-item-icon"
          style="background-color: #f4777c; color: #fff"
        >
          {{ $t('actionReportingSplitE') }}
        </div>
        <div class="stats-bar-item-title">
          {{ $t('actionReportingSplitNumberToE') }}
        </div>
        <div class="stats-bar-item-number">
          {{ numberFilter(eTotal) }}
          ({{ percentageOfFilter(eTotal, arrivedTotal) }})
        </div>
      </div>
    </div>
    <hr />
    <line-chart
      :chart-data="chartData"
      :title="$t('actionReportingHowManyProfilesArrived')"
      title-class="action-color-decision"
      :y-label="$t('actionReportingNumberOfProfiles')"
      style="margin-bottom: 30px"
    />
    <bar-chart
      :title="$t('actionReportingBarChartTitle')"
      :chart-data="decisionChartData"
      title-class="action-color-decision"
    />
  </div>
</template>
<script>
import ReportingMixin from '@/libs/ReportingMixin'
import TrendLineMixin from '@/libs/TrendLineMixin'

import ActionStatsLoaderMixin from './Charts/ActionStatsLoaderMixin'
import LineChart from '@/components/DateLineChart'
import BarChart from './Charts/StackedBar'
import { numberFilter, percentageOfFilter } from '@/helpers/filters'

export default {
  components: {
    LineChart,
    BarChart
  },
  mixins: [ActionStatsLoaderMixin, ReportingMixin, TrendLineMixin],

  data() {
    return {
      numberFilter,
      percentageOfFilter
    }
  },

  computed: {
    arrivedTotal() {
      return this.aTotal + this.bTotal + this.cTotal + this.dTotal + this.eTotal
    },

    aTotal() {
      return this.getTotal('Split:a')
    },

    bTotal() {
      return this.getTotal('Split:b')
    },

    cTotal() {
      return this.getTotal('Split:c')
    },

    dTotal() {
      return this.getTotal('Split:d')
    },

    eTotal() {
      return this.getTotal('Split:e')
    },

    aStats() {
      return this.getStats('Split:a')
    },

    bStats() {
      return this.getStats('Split:b')
    },

    cStats() {
      return this.getStats('Split:c')
    },

    dStats() {
      return this.getStats('Split:d')
    },

    eStats() {
      return this.getStats('Split:e')
    },

    arrivedStats() {
      return this.getCombinedStats(
        'Split:a',
        'Split:b',
        'Split:c',
        'Split:d',
        'Split:e'
      )
    },

    aPercentStats() {
      const total = [],
        enteredData = this.arrivedStats,
        splitA = this.aStats

      enteredData.forEach((entered, idx) =>
        total.push(Math.round((splitA[idx] / entered) * 100) || 0)
      )

      return total
    },

    bPercentStats() {
      const total = [],
        enteredData = this.arrivedStats,
        splitB = this.bStats

      enteredData.forEach((entered, idx) =>
        total.push(Math.round((splitB[idx] / entered) * 100) || 0)
      )

      return total
    },

    cPercentStats() {
      const total = [],
        enteredData = this.arrivedStats,
        splitC = this.cStats

      enteredData.forEach((entered, idx) =>
        total.push(Math.round((splitC[idx] / entered) * 100) || 0)
      )

      return total
    },

    dPercentStats() {
      const total = [],
        enteredData = this.arrivedStats,
        splitD = this.dStats

      enteredData.forEach((entered, idx) =>
        total.push(Math.round((splitD[idx] / entered) * 100) || 0)
      )

      return total
    },

    ePercentStats() {
      const total = [],
        enteredData = this.arrivedStats,
        splitE = this.eStats

      enteredData.forEach((entered, idx) =>
        total.push(Math.round((splitE[idx] / entered) * 100) || 0)
      )

      return total
    },

    chartData() {
      return {
        labels: this.dateRange,
        datasets: [
          {
            label: this.$t('actionReportingLineChartArrived'),
            backgroundColor: 'RGBA(245, 177, 95, .2)',
            borderColor: 'RGBA(245, 177, 95, 1)',
            borderWidth: 1,
            data: this.arrivedStats
          },
          {
            tooltip: false,
            fill: false,
            label: this.$t('actionReportingLineChartTrendLine'),
            data: this.getTrendLine(this.arrivedStats),
            backgroundColor: 'RGBA(245, 177, 95, 1)',
            borderColor: 'RGBA(245, 177, 95, 1)',
            spanGaps: true
          }
        ]
      }
    },

    decisionChartData() {
      const dataSet = [
        {
          label: this.$t('actionReportingSplitA'),
          backgroundColor: 'RGBA(118, 169, 202, .2)',
          borderColor: 'RGBA(118, 169, 202, 1)',
          borderWidth: 1,
          data: this.aPercentStats
        },
        {
          label: this.$t('actionReportingSplitB'),
          backgroundColor: 'RGBA(180, 214, 136, .2)',
          borderColor: 'RGBA(180, 214, 136, 1)',
          borderWidth: 1,
          data: this.bPercentStats
        }
      ]

      if (this.cTotal) {
        dataSet.push({
          label: this.$t('actionReportingSplitC'),
          backgroundColor: 'RGBA(238, 120, 191, .2)',
          borderColor: 'RGBA(238, 120, 191, 1))',
          borderWidth: 1,
          data: this.cPercentStats
        })
      }

      if (this.dTotal) {
        dataSet.push({
          label: this.$t('actionReportingSplitD'),
          backgroundColor: 'RGBA(105, 109, 141, .2)',
          borderColor: 'RGBA(105, 109, 141, 1)',
          borderWidth: 1,
          data: this.dPercentStats
        })
      }

      if (this.eTotal) {
        dataSet.push({
          label: this.$t('actionReportingSplitE'),
          backgroundColor: 'RGBA(244, 119, 124, .2)',
          borderColor: 'RGBA(244, 119, 124, 1)',
          borderWidth: 1,
          data: this.ePercentStats
        })
      }

      return {
        labels: this.dateRange,
        datasets: dataSet
      }
    }
  }
}
</script>
