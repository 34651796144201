<template>
  <tabs nav-class="nav-side" starting-tab="setup" :full-height="true">
    <tab
      :position="1"
      icon="pencil"
      id="setup"
      class="form-horizontal setup-tab"
      style="padding: 20px"
    >
      <div class="form-group">
        <div class="col-md-12">
          <label>{{ $t('defaultLanguageLabel') }}:</label>
          <language-selector
            class="sms-select"
            :value="config.defaultLang"
            @input="setDefaultLang"
            :disabled="!isAvailable"
          />
        </div>
      </div>

      <div class="form-group">
        <div class="col-md-12">
          <label>{{ $t('additionalLanguagesLabel') }}:</label>
          <multi-language-selector
            :value="additionalLanguages"
            @input="setLanguages"
            :disabled="!isAvailable"
          />
        </div>
      </div>
      <national-exclusion-check-settings
        v-if="
          $store.getters.isFeatureEnabled('nationalExclusionCheckBetsson') ||
          $store.getters.isFeatureEnabled('nationalExclusionCheckOasis')
        "
        v-model="config.exclusion"
        :disabled="readOnly"
      />
    </tab>
    <tab :position="2" icon="channel-inbox" id="inbox">
      <!-- Content Section -->
      <div class="content" ref="parent">
        <div class="form-group">
          <label for="inbox-type">
            {{ $t('inboxToolboxLargeTypeLabel') }}:
          </label>
          <select2
            id="inbox-type"
            v-model="config.inbox_type"
            class="form-control"
            :disabled="!isAvailable"
            @input="updateInboxType($event)"
          >
            <option value="alert">
              {{ $t('inboxLargeToolboxInboxTypeAlertLabel') }}
            </option>
            <option value="card">
              {{ $t('inboxToolboxLargeInboxTypeCardLabel') }}
            </option>
          </select2>
        </div>
        <div v-for="lang in sortedLanguages" :key="lang">
          <div class="language-header">
            {{
              lang === config.defaultLang
                ? $t('inboxToolboxLargeLanguagesDefault')
                : ''
            }}
            {{ getLanguage(lang) }}
          </div>
          <div class="language-content">
            <div class="form-group">
              <label for="push_title">{{ $t('messageTitleLabel') }}:</label>
              <redactor
                id="push_title"
                v-model="config.messages[lang].push_title"
                :plugins="redactorPlugins"
                :buttons="redactorButtons"
                :custom-config="redactorConfig"
                :use-default-plugins="false"
                :use-default-buttons="false"
                :disabled="!isAvailable"
              />
            </div>

            <div class="form-group">
              <label for="push_text">{{ $t('messageTextLabel') }}:</label>
              <redactor
                id="push_text"
                v-model="config.messages[lang].push_text"
                :plugins="redactorPlugins"
                :buttons="redactorButtons"
                :custom-config="redactorConfig"
                :use-default-plugins="false"
                :use-default-buttons="false"
                :disabled="!isAvailable"
              />
            </div>
          </div>
        </div>

        <div
          v-if="config.inbox_type === 'card'"
          class="form-group colorpicker-container"
        >
          <div>
            <label class="option-label">
              {{ $t('inboxToolboxLargeTitleBackgroundColour') }}:
            </label>
            <color-picker-field
              :is-form-element="false"
              label-text=""
              v-model="config.inbox_style.title_bg"
              :append-to-body="false"
              :boundaries-element="$refs.parent"
              :read-only="!isAvailable"
            />
          </div>

          <div>
            <label class="option-label">
              {{ $t('inboxToolboxLargeTitleBackgroundColour') }}:
            </label>
            <color-picker-field
              :is-form-element="false"
              label-text=""
              v-model="config.inbox_style.bg"
              :append-to-body="false"
              :boundaries-element="$refs.parent"
              :read-only="!isAvailable"
            />
          </div>
        </div>

        <div class="form-group" v-if="voucherCodesEnabled">
          <div class="col-md-12 VoucherOption">
            <label>{{ $t('voucherCodeTypeLabel') }}:</label>
            <select-barcode-type
              :disabled="!isAvailable"
              v-model="config.voucher_code_enabled"
            />
          </div>
          <div
            v-if="(config.voucher_code_enabled || 0) === 1"
            class="col-md-12 VoucherOption"
          >
            <label>{{ $t('voucherCodePositionLabel') }}:</label>
            <select-barcode-position
              :disabled="!isAvailable"
              v-model="config.voucher_code_position"
            />
          </div>
        </div>

        <div class="form-group">
          <label for="default_action">{{ $t('defaultActionLabel') }}:</label>
          <select2
            id="default_action"
            v-model="config.push_action.action"
            class="form-control"
            @input="setDefaultAction($event)"
            :disabled="!isAvailable"
          >
            <option value="">{{ $t('inboxDefaultActionNoneLabel') }}</option>
            <option value="url">
              {{ $t('inboxDefaultActionOpenUrlLabel') }}
            </option>
            <option value="deeplink">
              {{ $t('inboxDefaultActionGoToDeeplink') }}
            </option>
          </select2>
        </div>

        <div v-if="config.push_action.action === 'url'" class="form-group">
          <label for="url">{{ $t('inboxDefaultActionOpenUrlLabel') }}:</label>
          <input
            id="url"
            v-model="config.push_action.url"
            type="text"
            class="form-control"
            style="margin-bottom: 20px"
            :disabled="!isAvailable"
          />

          <toggle-button
            color="#1d87c8"
            :value="config.push_action.url_blank"
            :sync="true"
            @change="config.push_action.url_blank = $event.value"
            :disabled="!isAvailable"
          />
          <span style="margin-left: 10px">
            {{ $t('inboxPushActionOpenInNewTab') }}
          </span>
        </div>

        <div v-if="config.push_action.action === 'deeplink'" class="form-group">
          <label for="deeplink">{{ $t('inboxPushActionGoToDeepLink') }}:</label>
          <input
            id="deeplink"
            v-model="config.push_action.deeplink"
            type="text"
            class="form-control"
            :disabled="!isAvailable"
          />
        </div>

        <div v-if="isAvailable" class="form-group">
          <label>{{ $t('inboxUploadImageLabel') }}:</label>
          <div style="display: flex">
            <label
              style="flex: 1; margin-right: 10px"
              class="btn btn-default btn-file"
              :class="{ disabled: processingImageUpload }"
            >
              {{ $t('uploadLabel') }}
              <input
                type="file"
                accept="image/png, image/jpeg, image/gif"
                style="display: none"
                @change="uploadImage($event)"
                :disabled="processingImageUpload"
              />
            </label>
            <button
              style="flex: 1; margin-left: 10px"
              class="btn btn-default"
              :disabled="!config.push_icon"
              @click="removeImage()"
            >
              {{ $t('removeLabel') }}
            </button>
          </div>
        </div>

        <div class="form-group">
          <label for="payload_add">{{ $t('payloadLabel') }}:</label>
          <div
            v-for="(payload, index) in config.payload_add"
            class="payload-group"
          >
            <input
              v-model="config.payload_add[index].key"
              :placeholder="$t('keyPlaceholderLabel')"
              class="form-control payload-key"
              :disabled="!isAvailable"
            />
            <input
              v-model="config.payload_add[index].value"
              :placeholder="$t('valuePlaceholderLabel')"
              class="form-control payload-value"
              :disabled="!isAvailable"
            />
            <span
              v-if="isAvailable"
              v-tooltip:bottom="$t('inboxToolboxLargeRemovePayloadTooltip')"
              @click="removePayload(index)"
              class="payload-remove"
            >
              <icon glyph="trash" />
            </span>
          </div>
          <div class="payload-add">
            <button
              class="btn btn-default"
              @click="addPayload"
              :disabled="isPayloadBtnDisabled()"
            >
              {{ $t('addPayloadLabel') }}
            </button>
          </div>
        </div>

        <label for="persist_inbox_content">
          {{ $t('inboxToolboxLargePersistentContentLabel') }}:
        </label>
        <div class="card">
          <div
            class="form-group"
            :class="{
              'no-bottom':
                config.persist_content !== 'until' &&
                config.persist_content !== 'for'
            }"
          >
            <select2
              id="persist_inbox_content"
              v-model="config.persist_content"
              class="form-control"
              @input="setPersistContent($event)"
              :disabled="!isAvailable"
            >
              <option value="forever">
                {{ $t('inboxToolboxLargePersistentContentDontRemoveOption') }}
              </option>
              <option value="until">
                {{ $t('inboxToolboxLargePersistentContentRemoveOnOption') }}
              </option>
              <option value="for">
                {{ $t('inboxToolboxLargePersistentContentRemoveAfterOption') }}
              </option>
            </select2>
          </div>
          <div class="form-group" v-show="config.persist_content === 'until'">
            <label for="persist_content_until_date">
              {{ $t('inboxToolboxLargePersistentContentRemoveOnOption') }}:
            </label>
            <div class="input-group date" ref="dateTimePicker">
              <input
                type="text"
                class="form-control"
                :disabled="!isAvailable"
                :placeholder="$t('selectADatePlaceholder')"
              />
              <span class="input-group-addon">
                <icon glyph="date" />
              </span>
              <span
                v-if="
                  config.persist_content_until_date_timezone &&
                  config.persist_content_until_date_timezone !== 'profile'
                "
                class="input-group-addon"
              >
                {{
                  config.persist_content_until_date_timezone === 'project'
                    ? config.project_timezone
                    : config.persist_content_until_date_timezone
                }}
              </span>
            </div>
          </div>

          <div
            class="form-group"
            v-show="config.persist_content === 'until'"
            :class="{ 'no-bottom': config.persist_content === 'until' }"
          >
            <label for="persist_content_until_date_timezone">
              {{ $t('timeZoneLabel') }}:
            </label>
            <timezone-picker
              v-model="config.persist_content_until_date_timezone"
              :disabled="!isAvailable"
              :bespoke-options="bespokeOptions"
              class="form-control"
              @input="setTimezone($event)"
            />
          </div>

          <div class="form-group" v-show="config.persist_content === 'for'">
            <label for="persist_content_for_n">{{ $t('numberLabel') }}:</label>
            <input
              v-model="config.persist_content_for_n"
              type="number"
              class="form-control"
              :disabled="!isAvailable"
            />
          </div>

          <div
            class="form-group"
            v-show="config.persist_content === 'for'"
            :class="{ 'no-bottom': config.persist_content === 'for' }"
          >
            <label for="persist_content_for_unit">
              {{ $t('timeUnitLabel') }}:
            </label>
            <select2
              id="persist_content_for_unit"
              v-model="config.persist_content_for_unit"
              class="form-control"
              :disabled="!isAvailable"
            >
              <option value="minute">{{ $t('timeUnitMinutesOption') }}</option>
              <option value="hour">{{ $t('timeUnitHoursOption') }}</option>
              <option value="day">{{ $t('timeUnitDaysOption') }}</option>
              <option value="week">{{ $t('timeUnitWeeksOption') }}</option>
              <option value="month">{{ $t('timeUnitMonthsOption') }}</option>
            </select2>
          </div>
        </div>
      </div>

      <!-- Preview Section -->
      <div class="preview">
        <inbox-message-preview
          :message="formattedMessage"
          :message-type="config.inbox_type"
          :message-style="messageStyles"
          :global-styles="globalStyles"
        />
      </div>
    </tab>
  </tabs>
</template>

<script>
import Tabs from '@/components/TabSet'
import Tab from '@/components/Tab'
import Redactor from '@/components/Redactor'
import MxaApi from '@/libs/MxaApi'
import * as Toastr from 'toastr'
import alert from '@/libs/Alert'
import SelectBarcodeType from '@/components/SelectBarcodeType'
import SelectBarcodePosition from '@/components/SelectBarcodePosition'
import InboxMessagePreview from '@/views/WorkflowEdit/ToolboxesLarge/Inbox/InboxMessagePreview.vue'
import ColorPickerField from '@/components/ColorPickerField.vue'
import LanguageSelector from '@/components/LanguageSelector'
import MultiLanguageSelector from '@/components/MultiLanguageSelector'
import ISO6391 from 'iso-639-1'
import TimezonePicker from '@/components/TimezonePicker'
import '@/libs/bootstrap-datepicker/bootstrap-datetimepicker'
import $ from 'jquery'
import NationalExclusionCheckSettings from "@/components/NationalExclusionCheckSettings";

export default {
  name: 'Inbox',

  components: {
    NationalExclusionCheckSettings,
    Tabs,
    Tab,
    LanguageSelector,
    MultiLanguageSelector,
    InboxMessagePreview,
    ColorPickerField,
    Redactor,
    TimezonePicker,
    SelectBarcodeType,
    SelectBarcodePosition
  },

  props: ['action', 'readOnly', 'workflow'],

  inject: ['workflowApi'],

  mounted() {
    $(this.$refs.dateTimePicker)
      .datetimepicker({
        timeZone: this.getProjectTimeZone,
        format: 'D MMMM YYYY h:mm A',
        allowInputToggle: true,
        widgetPositioning: {
          horizontal: 'left',
          vertical: 'top'
        }
      })
      .on('dp.hide', this.saveOptions)

    this.dateTimePicker = $(this.$refs.dateTimePicker).data('DateTimePicker')

    this.updateDateTimePicker()
  },

  data() {
    const config = Object.assign(
      {
        exclusion: {
          check: false,
          category: null
        },
        inbox_type: 'alert',
        push_action: {
          action: '',
          url: '',
          deeplink: '',
          url_blank: false
        },
        push_icon: null,
        payload_add: [],
        inbox_style: {
          bg: '',
          title_bg: ''
        },
        persist_content: 'forever',
        persist_content_until_date: '',
        persist_content_until_date_timezone: 'project',
        project_timezone: this.$store.state.app.project.projectTimezone || '',
        persist_content_for_n: '',
        persist_content_for_unit: '',
        voucher_code_enabled: 0,
        voucher_code_position: 1
      },
      JSON.parse(JSON.stringify(this.action.config))
    )

    return {
      config,
      redactorPlugins: [
        'alignment',
        'fontsize',
        'fontcolor',
        'fontfamily',
        'emojis',
        'personalization',
        'snippet'
      ],
      redactorButtons: ['bold', 'italic', 'underline'],
      redactorConfig: {
        tabKey: false,
        enterKey: true,
        minHeight: 50,
        maxHeight: 350
      },
      processingImageUpload: false,
      bespokeOptions: {
        project: this.$t('timeZoneProjectTimezone'),
        profile: this.$t('timeZoneProfileTimezone')
      }
    }
  },

  beforeDestroy() {
    if (this.isAvailable) {
      const config = JSON.parse(JSON.stringify(this.config))
      this.workflowApi.updateAction(this.action.id, { config })
    }
  },

  computed: {
    getProjectTimeZone() {
      return this.$store.state.app.project.projectTimezone || ''
    },

    languages() {
      return this.config.languages
    },

    additionalLanguages() {
      return this.config.languages.filter(
        (lang) => lang !== this.config.defaultLang
      )
    },

    sortedLanguages() {
      return [...this.config.languages].sort((lang1, lang2) => {
        if (
          lang1 === this.config.defaultLang ||
          lang2 === this.config.defaultLang
        ) {
          return lang1 === this.config.defaultLang ? -1 : 1
        }
        return ISO6391.getName(lang1).localeCompare(ISO6391.getName(lang2))
      })
    },

    isAvailable() {
      return !this.readOnly && this.$store.getters.inboxEnabled
    },

    formattedMessage() {
      return {
        title: this.config.messages[this.config.defaultLang].push_title || '',
        text: this.messageText,
        icon: this.config.push_icon || ''
      }
    },

    messageStyles() {
      if (this.config.inbox_type === 0) {
        return {}
      }
      return {
        bg: this.config.inbox_style.bg,
        title_bg: this.config.inbox_style.title_bg
      }
    },

    globalStyles() {
      return {}
    },

    voucherCodesEnabled() {
      return (
        (this.workflow?.features?.voucher_codes || false) &&
        ((this.config?.voucher_code_enabled || 0) === 1 ||
          this.$store.getters.isFeatureEnabled('allowVoucherCodes'))
      )
    },

    messageText() {
      const messageText =
        this.config.messages[this.config.defaultLang].push_text ||
        (this.config.inbox_type === 'card'
          ? this.$t('inboxToolboxLargeMessageTextHere')
          : '')

      if ((this.config.voucher_code_enabled || 0) === 0) return messageText

      if (messageText === '') return 'X6RgzCXb'

      if (this.config.voucher_code_position === 0) {
        return '<p>X6RgzCXb</p>' + messageText
      }

      return messageText + '<p>X6RgzCXb</p>'
    }
  },

  methods: {
    saveOptions() {
      const config = this.config
      config.persist_content_until_date = this.getPersistUntil()
      this.workflowApi.updateAction(this.action.id, { config })
    },

    getPersistUntil() {
      const date = this.dateTimePicker.date()

      if (!date) {
        return ''
      }

      return date.format('YYYY-MM-DD HH:mm')
    },

    updateDateTimePicker() {
      if (this.config.persist_content_until_date) {
        this.dateTimePicker.date(
          new Date(this.config.persist_content_until_date)
        )
      }
    },

    setDefaultLang(lang) {
      if (!this.config.languages.includes(lang)) {
        this.config.languages.push(lang)
      }

      this.config.defaultLang = lang
    },

    setLanguages(languages) {
      if (!languages.includes(this.config.defaultLang)) {
        languages.push(this.config.defaultLang)
      }
      this.config.languages = languages
    },

    updateInboxType(type) {
      if (type === 'alert') {
        this.config.inbox_style.bg = ''
        this.config.inbox_style.title_bg = ''
      }
    },

    setDefaultAction(defaultAction) {
      if (!defaultAction) {
        this.config.push_action.url = ''
        this.config.push_action.deeplink = ''
      }

      if (defaultAction === 'url') {
        this.config.push_action.deeplink = ''
      }

      if (defaultAction === 'deeplink') {
        this.config.push_action.url = ''
      }
    },

    setPersistContent(persistUntil) {
      switch (persistUntil) {
        case 'until':
          this.config.persist_content_for_n = ''
          this.config.persist_content_for_unit = ''
          this.config.persist_content_until_date_timezone =
            this.getProjectTimeZone
          break
        case 'for':
          this.config.persist_content_until_date = ''
          this.config.persist_content_until_date_timezone = ''
          break
        default:
          this.config.persist_content_until_date = ''
          this.config.persist_content_for_n = ''
          this.config.persist_content_for_unit = ''
          this.config.persist_content_until_date_timezone = ''
      }
    },

    setTimezone(timezoneOption) {
      if (timezoneOption === 'project') {
        this.config.project_timezone = this.getProjectTimeZone
      } else {
        this.config.project_timezone = ''
      }
    },

    uploadImage(event) {
      this.processingImageUpload = true

      const projectId = this.$store.state.app.project.id,
        data = new FormData()

      data.append('image', event.target.files[0])
      event.target.value = null

      MxaApi.post(`projects/${projectId}/images`, data)
        .then(({ data }) => {
          this.config.push_icon = data.url
          this.processingImageUpload = false
        })
        .catch(({ data }) => Toastr.error(data.title))
    },

    removeImage() {
      this.config.push_icon = null
    },

    addPayload() {
      if (this.config.payload_add === null) {
        this.config.payload_add = []
      }

      this.config.payload_add.push({ key: '', value: '' })
    },

    removePayload(index) {
      if (
        this.config.payload_add[index].key === '' &&
        this.config.payload_add[index].value === ''
      ) {
        this.config.payload_add.splice(index, 1)
        return
      }

      alert.show({
        confirm: true,
        type: 'warning',
        okText: this.$t('inboxToolboxLargeDeleteAlertOkText'),
        title: this.$t('inboxToolboxLargeDeleteAlertTitle'),
        htmlMessage: true,
        message: this.$t('inboxToolboxLargeDeleteAlertMessage'),
        onOk: (resolve) => {
          this.config.payload_add.splice(index, 1)
          resolve()
        }
      })
    },

    isPayloadBtnDisabled() {
      if (!this.isAvailable) {
        return true
      }

      if (this.config.payload_add === null) {
        return false
      }

      if (!this.config.payload_add[this.config.payload_add.length - 1]) {
        return false
      }

      return (
        this.config.payload_add[this.config.payload_add.length - 1].key ===
          '' ||
        this.config.payload_add[this.config.payload_add.length - 1].value === ''
      )
    },
    getLanguage(languageCode) {
      return ISO6391.getName(languageCode)
    }
  },

  watch: {
    languages: {
      immediate: true,
      handler(languages) {
        let changes = false
        languages.forEach((lang) => {
          if (!this.config.messages[lang]) {
            this.$set(this.config.messages, lang, {
              push_title: '',
              push_text: ''
            })
            changes = true
          }
        })

        Object.keys(this.config.messages).forEach((lang) => {
          if (!languages.includes(lang)) {
            this.$delete(this.config.messages, lang)
            changes = true
          }
        })
      }
    }
  }
}
</script>
<style lang="sass" scoped>
.content
    overflow: scroll
    position: absolute
    top: 0
    bottom: 0
    right: 0
    left: 0
    padding: 20px 600px 20px 20px

    .language-header
        font-weight: bold
        font-size: 14px
        margin-top: 10px
        margin-bottom: 10px

    .language-content
        background-color: #e6e6e6
        padding: 15px
        margin-bottom: 20px
        border: 1px solid transparent
        border-radius: 4px
        box-shadow: 0 1px 1px rgba(0,0,0,.05)

    .colorpicker-container
        display: flex
        justify-content: space-between

    .payload-group
        display: flex
        margin-bottom: 20px

        .payload-value
            margin-left: 20px

        .payload-remove
            font-size: 20px
            margin-top: -10px
            display: flex
            align-items: center
            cursor: pointer
            margin-left: 10px

    .payload-add
        width: 100%

.preview
    position: absolute
    width: 45%
    top: 20px
    right: 20px
    display: flex
    justify-content: center
    margin-top: 25px
    background: #ececec
    padding: 50px 0 50px 0

.card
    background-color: #e6e6e6
    padding: 15px
    border: 1px solid transparent
    border-radius: 4px
    box-shadow: 0 1px 1px rgba(0,0,0,.05)

    .form-group.no-bottom
        margin-bottom: 0px

.VoucherOption
    &:first-of-type
        margin-top: 15px

    display: flex
    flex-direction: column
    padding-right: 0
    padding-left: 0
    margin-bottom: 15px
</style>
