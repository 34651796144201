import Chart from 'chart.js'

Chart.defaults.global.defaultFontColor = '#707070'
Chart.defaults.global.defaultFontFamily = 'Open Sans,sans-serif'
Chart.defaults.global.animation = false
Chart.defaults.global.responsive = false
Chart.defaults.global.tooltips.mode = 'index'
Chart.defaults.global.tooltips.intersect = false
Chart.defaults.global.tooltips.filter = (tooltipItem, data) =>
  data.datasets[tooltipItem.datasetIndex].tooltip !== false
