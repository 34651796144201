<!-- This component provides a color picker box with an input field next to it -->
<template>
  <component :is="isFormElement ? 'form-item' : 'div'" :label="labelText">
    <div class="color-picker-ct">
      <el-input
        v-model="color"
        class="input"
        :disabled="readOnly"
        @change="validateInput"
        @focus="onFocus"
      ></el-input>
      <el-color-picker-overrided
        ref="colorPicker"
        v-model="color"
        :disabled="readOnly"
        :append-to-body="appendToBody"
        :boundaries-element="boundariesElement"
        @change="validateInput"
      ></el-color-picker-overrided>
    </div>
  </component>
</template>

<script>
import FormItem from '@/components/FormItem'
import ElColorPickerOverrided from '@/components/ElColorPickerOverrided'
export default {
  components: { FormItem, ElColorPickerOverrided },
  props: {
    labelText: {
      type: String,
      required: true
    },
    value: {
      required: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    isFormElement: {
      type: Boolean,
      default: true
    },
    appendToBody: {
      type: Boolean,
      default: true
    },
    boundariesElement: HTMLDivElement
  },

  data() {
    return {
      previousValidValue: ''
    }
  },

  computed: {
    color: {
      get: function () {
        return this.value
      },

      set: function (newValue) {
        if (newValue === null) {
          newValue = ''
        }

        this.$emit('input', newValue)
      }
    }
  },

  watch: {
    value(value) {
      if (this.isValidInput(value)) {
        this.previousValidValue = value
      }
    }
  },

  methods: {
    onFocus() {
      setTimeout(() => {
        this.$refs.colorPicker.handleTrigger()
      }, 250)
    },

    isValidInput(value) {
      // look for a HEX or an RGB value
      const regex = /(?:#|0x)(?:[a-f0-9]{3}|[a-f0-9]{6})\b|(?:rgb?\([^)]*\))/i

      return RegExp(regex).test(value)
    },

    validateInput(value) {
      if (!this.isValidInput(value)) {
        this.color = this.previousValidValue
      }
    }
  }
}
</script>

<style scoped>
.color-picker-ct {
  display: flex;
  border: 1px solid #dbdee7;
  border-radius: 4px;
  height: 36px;
  width: 155px;
}

>>> .el-input__inner {
  border: none;
  height: 35px;
}

>>> .el-color-picker__trigger {
  padding: 0;
  margin: 3px;
  width: 30px;
  height: 30px;
}
>>> .el-color-picker__color {
  border: none;
}
>>> .el-color-picker__color-inner {
  border-radius: 3px;
}

>>> .el-form-item__content {
  line-height: inherit;
}
>>> .el-color-picker__icon {
  display: none;
}
>>> .el-color-picker__mask {
  height: 35px;
  width: 35px;
}
</style>
