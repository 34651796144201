import { CELL_SIZE } from '@/views/WorkflowEdit/GridSize'

const SUCCESSOR_OFFSET = {
  2: {
    a: {
      left: -(CELL_SIZE * 8),
      top: CELL_SIZE * 4
    },
    b: {
      left: CELL_SIZE * 8,
      top: CELL_SIZE * 4
    }
  },

  3: {
    a: {
      left: -(CELL_SIZE * 8),
      top: CELL_SIZE * 4
    },
    b: {
      left: 0,
      top: CELL_SIZE * 8
    },
    c: {
      left: CELL_SIZE * 8,
      top: CELL_SIZE * 4
    }
  },

  4: {
    a: {
      left: -(CELL_SIZE * 16),
      top: CELL_SIZE * 4
    },
    b: {
      left: -(CELL_SIZE * 8),
      top: CELL_SIZE * 8
    },
    c: {
      left: CELL_SIZE * 8,
      top: CELL_SIZE * 8
    },
    d: {
      left: CELL_SIZE * 16,
      top: CELL_SIZE * 4
    }
  },

  5: {
    a: {
      left: -(CELL_SIZE * 16),
      top: CELL_SIZE * 4
    },
    b: {
      left: -(CELL_SIZE * 8),
      top: CELL_SIZE * 8
    },
    c: {
      left: 0,
      top: CELL_SIZE * 12
    },
    d: {
      left: CELL_SIZE * 8,
      top: CELL_SIZE * 8
    },
    e: {
      left: CELL_SIZE * 16,
      top: CELL_SIZE * 4
    }
  }
}

export { SUCCESSOR_OFFSET }
