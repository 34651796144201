<template>
  <span>
    {{ $t('toolboxSmallProfileANumberBetween') }}
    <span v-if="isNumeric(conditionArgs.min)" class="criteria-condition-args">
      {{ conditionArgs.min }}
    </span>
    <span v-else class="invalid-text">{{ $t('toolboxSmallProfileMissingNumber') }}</span>
    {{ $t('toolboxSmallProfileAnd') }}
    <span v-if="isNumeric(conditionArgs.max)" class="criteria-condition-args">
      {{ conditionArgs.max }}
    </span>
    <span v-else class="invalid-text">{{ $t('toolboxSmallProfileMissingNumber') }}</span>
  </span>
</template>
<script>
export default {
  props: ['conditionArgs'],

  methods: {
    isNumeric(value) {
      return !isNaN(parseFloat(value)) && isFinite(value)
    }
  }
}
</script>
