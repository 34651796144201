<template>
  <input
    v-model="num"
    class="form-control form-number"
    type="number"
    :placeholder="$t('insertNumberPlaceholder')"
    @input="$emit('input', { value: num })"
  />
</template>
<script>
export default {
  props: ['value'],

  data() {
    return {
      num: this.value.value || ''
    }
  }
}
</script>
