<template>
  <div class="sidebar sidebar-sm">
    <div
      class="sidebar-header"
      :class="`action-style-${action.type.toLowerCase()}`"
    >
      <action-icon
        class="sidebar-icon"
        :actionType="workflowApi.getActionType(action)"
      />
      <div class="sidebar-title">
        <template v-if="readOnly">{{ action.name }}</template>
        <editable v-else :value="action.name" @input="changeName" />
      </div>
      <button
        type="button"
        class="close"
        :aria-label="$t('closeButton')"
        @click="$emit('close')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="sidebar-body" style="overflow: auto">
      <component
        :key="action.id"
        :is="actionType(action)"
        :action="action"
        :workflow="workflow"
        :readOnly="readOnly"
      ></component>
      <div>
        <button
          v-if="isMsgAction"
          class="btn btn-sm btn-seamless btn-default"
          @click="showMsg"
          :key="action.action_id"
          v-clipboard="action.action_id"
        >
          ID: {{ action.action_id }}
        </button>
      </div>
      <highlight
        v-if="devMode"
        :value="action"
        lang="json"
        style="margin: 20px; background-color: #333"
      />
    </div>
  </div>
</template>
<script>
import Highlight from '@/components/Highlight'
import ActionSettingsUpdateFromWorkflowMixin from '@/mixins/ActionSettingsUpdateFromWorkflowMixin'

export default {
  props: ['workflow', 'action', 'readOnly'],

  inject: ['workflowApi'],

  mixins: [ActionSettingsUpdateFromWorkflowMixin],

  components: {
    Highlight
  },

  computed: {
    isMsgAction() {
      return ['Email', 'Inbox', 'Push', 'Sms'].includes(this.action.type)
    },
    devMode() {
      return window.devMode
    }
  },

  watch: {
    action(newValue, oldValue) {
      if (newValue.id !== oldValue.id) {
        this.updateActionSettingsFromWorkflow()
      }
    }
  },

  mounted() {
    this.updateActionSettingsFromWorkflow()
  },

  methods: {
    showMsg() {
      this.$message({
        type: 'success',
        message: 'Copied successfully',
        duration: 2000
      })
    },
    actionType(action) {
      return require(`./ToolboxesSmall/${action.type}`).default
    },

    changeName(name, cb) {
      this.workflowApi.updateAction(this.action.id, { name }).then(() => cb())
    }
  }
}
</script>
<style lang="sass" scoped>
.sidebar-icon
    margin-top: 0

.btn-seamless
    background-color: transparent
    border-color: transparent
    margin-left: 10px
</style>
