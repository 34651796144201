<template>
  <div class="webpush-container">
    <tabs starting-tab="windows">
      <tab
        id="windows"
        :position="1"
        icon="windows"
        title="Windows"
        class="tab"
      >
        <div class="previews-container">
          <slot name="windows-push" />
        </div>
      </tab>
      <tab
        id="ios"
        :position="2"
        icon="ios"
        title="Apple"
        class="tab"
      >
        <div class="previews-container">
          <slot name="ios-push" />
        </div>
      </tab>
      <tab
        id="android"
        :position="3"
        icon="android"
        title="Android"
        class="tab"
      >
        <div class="previews-container">
          <slot name="android-push" />
        </div>
      </tab>
    </tabs>
  </div>
</template>

<script>
import Tab from '@/components/Tab'
import Tabs from '@/components/TabSet2'

export default {
  components: {
    Tabs,
    Tab
  }
}
</script>

<style lang="sass" scoped>
.webpush-container
    margin: 0 90px
    width: 405px

    .tab
        margin-left: -60px

        .previews-container
            display: flex
            flex-direction: column

::v-deep li
    width: 134.2px
    text-align: center
    border: none
    margin: 0

::v-deep li>a
    margin: 0
    color: inherit

::v-deep li.active>a, ::v-deep li.active>a:hover
    border: none
    border-radius: 0px
    margin: 0
    padding: 11px 0
    box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.349019607843137) inset
    background-color: rgba(204, 204, 204, 1)
    color: #666

::v-deep li.active:first-child>a, ::v-deep li:first-child>a:hover
    border-bottom-left-radius: 4px
    border-top-left-radius: 4px

::v-deep li.active:last-child>a, ::v-deep li:last-child>a:hover
    border-bottom-right-radius: 4px
    border-top-right-radius: 4px

::v-deep li:nth-child(2)
    border-right: 1px solid #ddd
    border-left: 1px solid #ddd
    border-radius: 0

::v-deep .nav-tabs
    width: 405px
    border: 1px solid #ddd

::v-deep .nav-tabs, ::v-deep li
    border-radius: 5px
</style>
