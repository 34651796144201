<template>
  <div class="sidebar sidebar-lg">
    <div
      class="sidebar-header"
      :class="`action-style-${action.type.toLowerCase()}`"
    >
      <action-icon
        class="sidebar-icon"
        :actionType="workflowApi.getActionType(action)"
      />
      <div class="sidebar-title">{{ action.name }}</div>
      <button
        type="button"
        class="close"
        :aria-label="$t('closeButton')"
        @click="$emit('close')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="sidebar-body">
      <component
        :key="action.id"
        :is="actionType(action)"
        :workflow="workflow"
        :action="action"
        :readOnly="readOnly"
        @close="$emit('close')"
      ></component>
    </div>
  </div>
</template>
<script>
export default {
  props: ['workflow', 'action', 'readOnly'],

  inject: ['workflowApi'],

  methods: {
    actionType(action) {
      return require(`./ToolboxesLarge/${action.type}`).default
    }
  }
}
</script>
<style lang="css" scoped>
/**
 * Remove these when vue-multiselect is upgraded to 2.1.17
 * https://github.com/shentao/vue-multiselect/pull/1350
 * this is the commit that adds what I think we need to vue-multiselect
 */

.sidebar .sidebar-header .sidebar-icon {
  margin-top: 0;
}

::v-deep .v-switch-button {
  z-index: 1 !important;
}

::v-deep .input-group input.form-control[type='text'] {
  z-index: 1 !important;
}
</style>
