<template>
  <span>
    {{ $t('toolboxSmallProfileTextContaining') }}
    <span v-if="conditionArgs.value" class="criteria-condition-args">
      '{{ conditionArgs.value }}'
    </span>
    <span v-else class="invalid-text">{{ $t('toolboxSmallProfileMissingText') }}</span>
  </span>
</template>
<script>
export default {
  props: ['conditionArgs']
}
</script>
