<template>
  <div ref="parent" class="bee-shopbox-plugin-wrapper">
    <el-container class="bee-shopbox-plugin">
      <div>
        <el-col>
          <el-row type="flex" justify="center" class="title" :gutter="0">{{
            isNewBlock
              ? 'Add Vouchers Block'
              : 'Edit Vouchers Block'
          }}</el-row>
          <el-divider />
          <span class="section-title">Variant</span>
          <el-divider />
          <el-row type="flex" justify="start" :gutter="0">
            <el-select id="barcodeVariant" v-model="state.barcodeVariant" :popper-append-to-body="false">
              <el-option
                v-for="(variant,index) in variants"
                :key="index"
                :label="variant.label"
                :value="variant.value"
              />
            </el-select>
          </el-row>
          <el-divider />
          <span class="section-title">Options</span>
          <el-divider />
          <el-row
            v-if="state.barcodeVariant !== 5"
            type="flex"
            class="variant"
            justify="start"
            :gutter="0"
          >
            <div class="field">
              <label for="barcodeScale" class="option-label">Scale Rate</label>
              <el-input-number
                id="barcodeScale"
                v-model="state.settings.scale"
                size="small"
                :min="100"
                :max="200"
                :step="5"
              />
            </div>
          </el-row>
          <div v-if="state.barcodeVariant === 5" type="flex" class="TextStyles">
            <div class="field">
              <label for="textStyle" class="option-label">Style</label>
              <el-checkbox-group
                style="display: flex"
                id="textStyle"
                v-model="state.settings.style"
              >
                <el-checkbox-button
                  style="margin-right: 0"
                  v-for="style in styles"
                  :label="style"
                  :key="style"
                >
                  {{ style }}
                </el-checkbox-button>
              </el-checkbox-group>
            </div>
            <div class="field">
              <label for="textSize" class="option-label">Size</label>
              <el-input-number
                id="textSize"
                v-model="state.settings.font"
                :min="12"
                :max="70"
                size="small"
              />
            </div>
            <div class="field">
              <label class="option-label">Color</label>
              <color-picker-field
                :is-form-element="false"
                label-text
                v-model="state.settings.color"
                :append-to-body="false"
                :boundaries-element="$refs.parent"
              />
            </div>
            <div class="field">
              <label class="option-label">Background Color</label>
              <color-picker-field
                :is-form-element="false"
                label-text
                v-model="state.settings.background"
                :append-to-body="false"
                :boundaries-element="$refs.parent"
              />
            </div>
          </div>
          <el-divider />
          <el-row type="flex" justify="center">
            <div :style="livePreviewStyles" v-html="preview"></div>
          </el-row>
        </el-col>
      </div>
    </el-container>
    <div class="footer">
      <el-divider />
      <el-row type="flex" justify="space-between" :gutter="0">
        <el-button @click="cancel">Cancel</el-button>
        <el-button @click="save" type="primary">Save</el-button>
      </el-row>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import { previews } from '@/components/AddOns/Vouchers/previews'
import ColorPickerField from '@/components/ColorPickerField'

export default {
  name: 'BeeVoucherPlugin',

  components: {
    ColorPickerField
  },

  props: {
    resolve: {
      type: Function,
      required: true
    },
    reject: {
      type: Function,
      required: true
    },
    currentState: {
      type: Object
    }
  },

  data: function() {
    return {
      projectID: undefined,
      isNewBlock: true,
      state: {
        barcodeVariant: 1,
        settings: {
          scale: 100,
          style: [],
          font: 12,
          color: '#000000',
          background: '#FFFFFF'
        }
      },
      styles: ['Italic', 'Bold', 'Underline'],
      variants: [
        {
          label: 'Interleaved 2 of 5 (ITF)',
          value: 1
        },
        {
          label: 'Code 128',
          value: 2
        },
        {
          label: 'Code 39',
          value: 3
        },
        {
          label: 'QR code',
          value: 4
        },
        {
          label: 'Text',
          value: 5
        },
      ]
    }
  },

  mounted() {
    if (Object.keys(this.currentState).length > 0) {
      this.updateState(this.currentState)
    }
  },

  computed: {
    barcodeType() {
      switch (this.state.barcodeVariant) {
        case 1: {
          return 'I25'
        }
        case 2: {
          return 'C128'
        }
        case 3: {
          return 'C39'
        }
        case 4: {
          return 'QRCODE'
        }
        case 5: {
          return 'TEXT'
        }
        default: {
          return undefined
        }
      }
    },
    barcodeParameters() {
      switch (this.state.barcodeVariant) {
        case 1: {
          return `{ scale: ${this.state.settings.scale} }`
        }
        case 2: {
          return `{ scale: ${this.state.settings.scale} }`
        }
        case 3: {
          return `{ scale: ${this.state.settings.scale} }`
        }
        case 4: {
          return `{ scale: ${this.state.settings.scale} }`
        }
        case 5: {
          return `{
            style: "${this.state.settings.style.join(',')}",
            font: ${this.state.settings.font},
            color: "${this.state.settings.color}",
            background: "${this.state.settings.background}"
          }`
        }
        default: {
          return undefined
        }
      }
    },
    preview() {
      if (this.state.barcodeVariant !== 5) {
        const scaleCoeff = this.state.settings.scale / 100
        return previews[this.barcodeType].replace('{scale}', scaleCoeff)
      }

      return `<span style="${this.previewStyle}">1234567890</span>`
    },
    previewStyle() {
      let style = ''

      if (this.state.settings.style.length > 0) {
        if (this.state.settings.style.includes('Italic')) {
          style += 'font-style: italic;'
        }
        if (this.state.settings.style.includes('Bold')) {
          style += 'font-weight: bold;'
        }
        if (this.state.settings.style.includes('Underline')) {
          style += 'text-decoration: underline;'
        }
      }

      style += `font-size: ${this.state.settings.font}px;`
      style += `color: ${this.state.settings.color};`
      style += `background-color: ${this.state.settings.background};`

      return style
    },
    blockStyles() {
      if (this.state.barcodeVariant === 5) return ''

      return `width: ${this.livePreviewStyles.width}; height: ${this.livePreviewStyles.height}; display: flex;align-items: center;justify-content: center;`
    },
    livePreviewStyles() {
      if (this.state.barcodeVariant === 5) return ''

      const baseWidth = 200
      const baseHeight = this.state.barcodeVariant === 4 ? 200 : 100

      const scaleCoeff = this.state.settings.scale / 100
      const width = baseWidth * scaleCoeff
      const height = baseHeight * scaleCoeff

      return {
        width: width + 'px',
        height: height + 'px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }
    }
  },

  methods: {
    updateState(state) {
      this.isNewBlock = false
      this.state = cloneDeep(state)
    },
    save() {
      const resultContent = this.generateContent()
      this.resolve({
        type: 'html',
        value: {
          html: resultContent
        }
      })
      this.$vuedals.close('bee-voucher-plugin-modal')
    },
    cancel() {
      this.reject('Editing has been canceled')
      this.$vuedals.close('bee-voucher-plugin-modal')
    },
    generateContent() {
      return `<div style="display: flex;align-items: center;justify-content: center;" data-plugin-state="${encodeURIComponent(
          JSON.stringify(this.state)
      )}">${this.twigWrapper()}</div>`
    },
    twigWrapper() {
      return `<span style="display: none">{{ voucher_code("${this.barcodeType}",
      ${this.barcodeParameters}) }}</span>
      <section class="remove-before-sending" style="${this.blockStyles}">
      ${this.preview}
      </section>`
    }
  }
}
</script>

<style scoped>
.bee-shopbox-plugin-wrapper {
  display: grid;
  grid-template-rows: 1fr 65px;
  max-height: calc(85vh - 40px);
}

.bee-shopbox-plugin {
  font-family: 'Open Sans', sans-serif;
  flex-direction: column;
  margin: 10px 0;
  padding: 10px 15px;
  overflow-y: auto;
  overflow-x: hidden;
}

.footer {
  bottom: 0;
  width: calc(100% - 40px);
  padding: 0 0px 20px;
  background: white;
  z-index: 1000;
  margin: 0 auto;
}

.footer .el-divider {
  margin-top: 0;
}

.title {
  font-size: 20px;
  text-align: center;
}

.section-title {
  font-size: 20px;
}

.flex-column {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.options-title {
  font-size: 16px;
}

.notification {
  height: 100%;
  justify-content: center;
}

>>> label.el-radio {
  align-items: center;
}

>>> label {
  display: flex;
  margin-bottom: 5px;
}

>>> .field {
  margin: 5px 0;
  flex: 0 0 40%;
}

>>> .el-divider--horizontal {
  margin: 14px 0;
}

>>> .el-radio__label {
  font-size: 18px;
}

>>> .el-switch__label > span {
  font-size: 16px;
}

>>> span,
>>> label {
  text-transform: none !important;
}

>>> .redactor-layer {
  padding: 10px;
}

.option-label {
  font-size: 14px;
}

.product-link-button-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.product-link-button-section .field {
  flex: 0 0 90%;
}

>>> .is-checked > .el-radio__inner,
>>> .el-button--primary {
  border-color: #1d87c8;
  background: #1d87c8;
}

>>> .is-checked > .el-radio__label {
  color: #1d87c8;
}

>>> .el-select .el-input.is-focus .el-input__inner {
  border-color: #1d87c8;
}

>>> .el-input--small .el-input__inner {
  height: 32px;
  line-height: 32px;
}

>>> .color-picker-ct .el-input__inner {
  border: none !important;
  height: 34px !important;
}

.TextStyles {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 10px;
}
</style>

<style>
html,
body {
  margin: 0;
}

.el-select-dropdown__item.selected {
  color: #1d87c8;
}
</style>
