<template>
  <span>
    clicked link
    <span v-if="conditionArgs.value" class="criteria-condition-args">
      '{{ conditionArgs.value }}'
    </span>
    <span v-else class="invalid-text">missing url</span>
  </span>
</template>
<script>
export default {
  props: ['conditionArgs']
}
</script>
