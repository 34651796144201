<template>
  <modal
    ref="modal"
    v-bind="{ title, disabled, closeable }"
    :show-cancel-button="confirm"
    :ok-text="okText"
    @ok="onOkEvent"
  >
    <div style="display: flex">
      <icon v-if="icon" size="36px" :glyph="icon" :class="type" />
      <div v-if="htmlMessage" v-html="message"></div>
      <div v-else>{{ message }}</div>
    </div>
  </modal>
</template>
<script>
import Modal from '@/components/Modal'

export default {
  components: {
    Modal
  },
  props: {
    confirm: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'plain'
    },
    title: {
      type: String,
      default: 'Alert'
    },
    htmlMessage: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ''
    },
    okText: {
      type: String,
      default: 'OK'
    },
    onOk: {
      type: Function,
      default: () => {}
    }
  },

  data() {
    return {
      disabled: false,
      closeable: true
    }
  },

  computed: {
    icon() {
      const iconMapping = {
        info: 'information',
        question: 'help',
        warning: 'warning-triangle',
        error: 'exclamation-mark-circle'
      }

      if (iconMapping.hasOwnProperty(this.type)) {
        return iconMapping[this.type]
      }

      return null
    }
  },

  methods: {
    onOkEvent() {
      if (!this.confirm) {
        this.$refs.modal.close()
        return
      }

      this.closeable = false
      this.disabled = true

      new Promise((resolve) => {
        this.onOk(resolve)
      }).then(() => {
        this.$refs.modal.close()
      })
    }
  }
}
</script>
<style lang="sass" scoped>
.icon
    flex-shrink: 0
    margin-right: 15px

.info
    color: $brand-info

.question
    color: $brand-info

.warning
    color: $brand-warning

.error
    color: $brand-danger
</style>
