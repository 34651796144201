<template>
  <ul>
    <li>
      <icon v-tooltip:left="$t('actionTooltipSent')" glyph="email" />
      {{ numberFilter(stats['Email:sent']) }}
    </li>
    <li>
      <icon v-tooltip:left="$t('actionTooltipOpens')" glyph="email-open" />
      {{ opens }}
    </li>
    <li>
      <icon v-tooltip:left="$t('actionTooltipClicks')" glyph="mouse" />
      {{ clicks }}
    </li>
  </ul>
</template>
<script>
import { numberFilter } from '@/helpers/filters'

export default {
  props: ['stats', 'trackEmailOpen', 'trackEmailClick'],

  data() {
    return {
      numberFilter
    }
  },

  computed: {
    opens() {
      if (!this.trackEmailOpen) return 'N/A'

      return this.numberFilter(this.stats['Email:openedUnique'])
    },

    clicks() {
      if (!this.trackEmailClick) return 'N/A'

      return this.numberFilter(this.stats['Email:clickedUnique'])
    }
  }
}
</script>
