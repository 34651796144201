const BeeLanguageEnum = Object.freeze({
  en: 'en-US',
  es: 'es-ES',
  fr: 'fr-FR',
  it: 'it-IT',
  pt: 'pt-BR',
  id: 'id-ID',
  ja: 'ja-JP',
  zh: 'zh-CN',
  de: 'de-DE',
  da: 'da-DK',
  sv: 'sv-SE',
  pl: 'pl-PL',
  hu: 'hu-HU',
  ru: 'ru-RU',
  ko: 'ko-KR',
  nl: 'nl-NL',
  fi: 'fi-FI',
  cs: 'cs-CZ',
  ro: 'ro-RO',
  nb: 'nb-NO',
  sl: 'sl-SL'
})

export { BeeLanguageEnum }
