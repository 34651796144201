<template>
  <tabs nav-class="nav-side" starting-tab="quick-stats" :full-height="true">
    <tab id="quick-stats" :position="1" icon="bar-chart">
      <email-stats
        v-bind="{
          arrivedTotal,
          sentTotal,
          deliveredTotal,
          bouncedTotal,
          uniqueOpensTotal,
          uniqueClicksTotal,
          unsubscribesTotal
        }"
      />
    </tab>
    <tab id="delivery" :position="2" icon="email" :keep-alive="true">
      <delivery
        v-bind="{
          arrivedTotal,
          sentTotal,
          deliveredTotal,
          bouncedTotal,
          dateRange,
          arrivedStats,
          sentStats
        }"
      />
    </tab>
    <tab id="engagement" :position="3" icon="email-open" :keep-alive="true">
      <engagement
        v-bind="{
          deliveredTotal,
          uniqueOpensTotal,
          uniqueClicksTotal,
          unsubscribesTotal,
          dateRange,
          sentStats,
          openStats,
          clickStats
        }"
      />
    </tab>
  </tabs>
</template>
<script>
import Tabs from '@/components/TabSet'
import Tab from '@/components/Tab'

import ReportingMixin from '@/libs/ReportingMixin'
import TrendLineMixin from '@/libs/TrendLineMixin'

import ActionStatsLoaderMixin from './Charts/ActionStatsLoaderMixin'
import EmailStats from './Email/EmailStats'
import Delivery from './Email/Delivery'
import Engagement from './Email/Engagement'

export default {
  components: {
    Tabs,
    Tab,
    EmailStats,
    Delivery,
    Engagement
  },
  mixins: [ActionStatsLoaderMixin, ReportingMixin, TrendLineMixin],

  props: ['trackEmailOpen', 'trackEmailClick'],

  computed: {
    arrivedTotal() {
      return this.getTotal('Email:trigger')
    },

    sentTotal() {
      return this.getTotal('Email:sent')
    },

    notSentTotal() {
      return this.getTotal('Email:notSent')
    },

    bouncedTotal() {
      return this.getTotal('Email:failed')
    },

    deliveredTotal() {
      return this.sentTotal - this.bouncedTotal
    },

    unsubscribesTotal() {
      return this.getTotal('Email:unsubscribed')
    },

    uniqueOpensTotal() {
      if (!this.trackEmailOpen) return 'N/A'

      return this.getTotal('Email:openedUnique')
    },

    uniqueClicksTotal() {
      if (!this.trackEmailClick) return 'N/A'

      return this.getTotal('Email:clickedUnique')
    },

    sentStats() {
      return this.getStats('Email:sent')
    },

    notSentStats() {
      return this.getStats('Email:notSent')
    },

    arrivedStats() {
      return this.getStats('Email:trigger')
    },

    openStats() {
      if (!this.trackEmailOpen) return 'N/A'

      return this.getStats('Email:openedUnique')
    },

    clickStats() {
      if (!this.trackEmailClick) return 'N/A'

      return this.getStats('Email:clickedUnique')
    }
  }
}
</script>
