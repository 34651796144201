<template>
  <multiselect
    :value="objectValue"
    :loading="loading"
    :placeholder="$t('selectAProfileAttributeLabel')"
    track-by="name"
    :custom-label="customLabel"
    select-label=""
    selected-label=""
    deselect-label=""
    :options="options"
    :option-height="36"
    :searchable="true"
    :allow-empty="false"
    :disabled="!options.length || loading || disabled"
    @input="onChange"
  />
</template>
<script>
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect
  },
  props: {
    options: {
      required: true,
      type: Array
    },
    value: {
      required: true
    },
    loading: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    }
  },

  computed: {
    objectValue() {
      return this.options.find(option => option.name === this.value)
    }
  },

  methods: {
    customLabel(option) {
      return option.display_name || option.name
    },

    onChange({ name }) {
      this.$emit('input', name)
    }
  }
}
</script>
