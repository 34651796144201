<template>
  <div class="message">
    <div v-for="line in lines" class="text">{{ line }}</div>
  </div>
</template>
<script>
export default {
  props: ['content'],

  computed: {
    lines() {
      return this.content.split(/(?:\r\n|\r|\n)/g)
    }
  }
}
</script>
<style lang="sass" scoped>
.message
    background-color: #e5e5e5
    border-radius: 5px
    margin: 3%
    padding: 5px

    .text
        font-size: 1.25rem
        min-height: 17px // for empty lines of text <newlines>
        text-align: left
</style>
