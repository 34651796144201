<template>
  <div class="panel panel-primary">
    <div class="panel-heading">
      <div class="panel-title">{{ $t('projectAdminProfilesLabel') }}</div>
    </div>
    <div class="panel-body">
      <label for="profileFilter">{{ $t('projectAdminProfilesSearchForProfile') }}</label>
      <div class="input-group" style="width: 300px">
        <input
          id="profileFilter"
          v-model="profileFilter"
          type="text"
          class="form-control"
          :placeholder="$t('projectAdminProfilesProfileFilterPlaceholder')"
        />
        <span class="input-group-addon">
        <icon glyph="search"/>
      </span>
      </div>
    </div>
    <table class="table table-hover">
      <thead class="table-header">
      <tr>
        <th class="col-md-3">{{ $t('projectAdminProfilesProfileIdentifier') }}</th>
        <th class="col-md-1">{{ $t('projectAdminProfilesProfileLanguage') }}</th>
        <th class="col-md-1">{{ $t('projectAdminProfilesProfileTimezone') }}</th>
        <th class="col-md-1">{{ $t('projectAdminProfilesProfileCreated') }}</th>
        <th class="col-md-1">{{ $t('projectAdminProfilesProfileUpdated') }}</th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="searching">
        <td class="col-md-7">{{ $t('projectAdminProfilesSearching') }}</td>
      </tr>
      <tr v-if="!searching && !profiles.length">
        <td class="col-md-7">
          {{
            !profileFilter.length
              ? $t('projectAdminProfilesEnterAnIdentifier')
              : $t('projectAdminProfilesNoMatchingProfiles')
          }}
        </td>
      </tr>
      <tr
        v-if="profiles.length"
        v-for="profile in profiles"
        @click="goToProfileActivity(profile.identifier)"
      >
        <td class="col-md-3">{{ profile.identifier }}</td>
        <td class="col-md-1">{{ profile.language }}</td>
        <td class="col-md-1">{{ profile.timezone ? profile.timezone.timezone : '' }}</td>
        <td class="col-md-1">{{ profile.created.date }}</td>
        <td class="col-md-1">{{ profile.updated.date }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import * as Toastr from 'toastr'
import MxaApi from '@/libs/MxaApi'
import {debounce} from 'lodash'
import ProfileActivity from './ProfileActivity.vue'

export default {
  components: {
    ProfileActivity
  },
  props: ['projectId'],

  data() {
    return {
      searching: false,
      profileFilter: '',
      profiles: []
    }
  },

  watch: {
    profileFilter(value) {
      if (value === '') {
        this.searching = false;
        this.profiles = [];
        return;
      }

      this.searching = true;
      this.profiles = [];
      this.filterProfiles(value);
    }
  },

  methods: {
    goToProfileActivity(id) {
      this.$router.push({name: 'ProjectAdminProfileActivity', params: {profileId: id}});
    },
    filterProfiles: debounce(function (identifier) {
      MxaApi.get(`projects/${this.projectId}/profiles`, {
        params: {
          identifier: identifier
        }
      }).then(({data}) => {
        this.profiles = data;
        this.searching = false;
      }).catch(({data}) => {
        this.searching = false;
        Toastr.error(data.title);
      });
    }, 500)
  },
}
</script>
<style lang="sass" scoped>
.panel
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  margin: 15px
  display: flex
  flex-direction: column
  flex: 1
  overflow: hidden

.rtable
  flex: 1
  display: flex
  flex-direction: column
  border-top: 1px solid #ddd

  td, th
    float: left

  .help-block
    padding: 8px

  .rtable-heading
    .rtable-row
      display: flex

      .rtable-cell
        background-color: #ECECEC
        font-weight: bold
        border-bottom: 1px solid #ddd
        overflow: hidden
        text-overflow: ellipsis
        padding: 8px

  .rtable-body
    flex: 1
    overflow-y: scroll

    .rtable-row
      display: flex
      border-top: 1px solid #ddd

      &:last-child
        border-bottom: 1px solid #ddd

      .rtable-cell
        overflow: hidden
        text-overflow: ellipsis
        padding: 8px

  .identifier
    flex: 1

  .link
    cursor: pointer

.rtable-hover
  > .rtable-body
    > .rtable-row:hover
      background-color: #f5f5f5
</style>
