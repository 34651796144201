class JobQueue {
  constructor(cb = () => {}) {
    this.cb = cb
    this.queue = []
    this.isProcessing = false
  }

  add(job) {
    return new Promise((resolve) => {
      this.queue.push(() => {
        job(() => {
          resolve()
          this.processQueue()
        })
      })

      if (!this.isProcessing) {
        this.isProcessing = true
        this.cb(true)
        this.processQueue()
      }
    })
  }

  processQueue() {
    if (this.queue.length === 0) {
      this.isProcessing = false
      this.cb(false)
      return
    }

    this.queue.shift()()
  }
}

export default JobQueue
