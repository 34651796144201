<template>
  <div style="margin: 20px">
    <button
      v-if="!readOnly"
      type="button"
      class="btn btn-default btn-block"
      @click="openEditor"
    >
      {{ $t('pushToolboxSmallEditButton') }}
    </button>

    <template v-if="isExclusionEnabled">
      <label>{{ $t('pushToolboxSmallExclusionCategory') }}</label>
      <div class="panel panel-default">
        <div class="panel-body">
          <div
            class="email-prop-value"
            v-if="
              action.config.exclusion && action.config.exclusion.check === false
            "
          >
            {{ $t('pushToolboxSmallNotEnabledLabel') }}
          </div>
          <div
            class="email-prop-value"
            v-if="
              (action.config.exclusion && action.config.exclusion.check) ||
              displayExclusionError
            "
            :class="{
              'invalid-text':
                displayExclusionError ||
                $v.action.config.exclusion.category.$invalid
            }"
          >
            {{ exclusionCategoryName }}
            <template
              v-if="
                displayExclusionError ||
                !$v.action.config.exclusion.category.required
              "
            >
              {{ $t('pushToolboxSmallMissingExclusionCategory') }}
            </template>
          </div>
        </div>
      </div>
    </template>

    <label>
      <icon glyph="apple" />
      {{ $t('pushToolboxSmallIosPreview') }}
    </label>
    <div v-if="action.config.ios && iosEnabled">
      <div style="background: #083146; padding: 30px; border-radius: 4px">
        <device-push-message-preview
          style="margin: auto"
          device="ios"
          :companyName="$store.state.app.project.name"
          :companyLogo="$store.getters.companyLogo"
          :title="action.config.ios.messages[action.config.defaultLang].title"
          :message="messageTextIOs"
          :picture="action.config.ios.pictureUrl"
        />
      </div>
    </div>
    <div v-else class="panel panel-default">
      <div class="panel-body">{{ $t('pushToolboxSmallNotEnabledLabel') }}</div>
    </div>

    <label style="margin-top: 20px">
      <icon glyph="android" />
      {{ $t('pushToolboxSmallAndroidPreviewLabel') }}:
    </label>
    <div v-if="action.config.android && androidEnabled">
      <div style="background: #083146; padding: 30px; border-radius: 4px">
        <device-push-message-preview
          style="margin: auto"
          device="android"
          :companyName="$store.state.app.project.name"
          :companyLogo="$store.getters.companyLogo"
          :title="
            action.config.android.messages[action.config.defaultLang].title
          "
          :message="messageTextAndroid"
          :picture="action.config.android.pictureUrl"
        />
      </div>
    </div>
    <div v-else class="panel panel-default">
      <div class="panel-body">{{ $t('pushToolboxSmallNotEnabledLabel') }}</div>
    </div>
    <label style="margin-top: 20px">
      <icon glyph="web" />
      {{ $t('pushToolboxSmallWebPushPreviewLabel') }}:
    </label>
    <div v-if="action.config.web && webPushEnabled">
      <div style="background: #083146; border-radius: 4px; padding: 10px">
        <web-push-apple
          #ios-push
          :title="action.config.web.messages[action.config.defaultLang].title"
          :message="messageTextWeb"
          :company-logo="$store.getters.companyLogo"
          :icon-url="action.config.web.iconUrl"
          :picture-url="action.config.web.pictureUrl"
          :url="action.config.web.url"
        />
      </div>
    </div>
    <div v-else class="panel panel-default">
      <div class="panel-body">{{ $t('pushToolboxSmallNotEnabledLabel') }}</div>
    </div>
  </div>
</template>
<script>
import DevicePushMessagePreview from '@/components/DevicePushMessagePreview'
import WebPushApple from '@/components/WebPushApple'
import { email, required, requiredIf } from 'vuelidate/lib/validators'
import NationalExclusionCategoriesMixin from '@/views/WorkflowEdit/NationalExclusionCategoriesMixin'

export default {
  props: ['action', 'readOnly'],
  inject: ['eventBus'],
  mixins: [NationalExclusionCategoriesMixin],

  components: {
    DevicePushMessagePreview,
    WebPushApple
  },

  validations: {
    action: {
      config: {
        exclusion: {
          category: {
            required: requiredIf((e) => {
              return e.check || false
            })
          }
        }
      }
    }
  },

  computed: {
    iosEnabled() {
      return !!this.$store.state.app.project.projectChannels.ios_push
    },

    androidEnabled() {
      return !!this.$store.state.app.project.projectChannels.android_push
    },

    webPushEnabled() {
      return !!this.$store.state.app.project.projectChannels.web_push
    },

    exclusionCategoryName() {
      if (
        !(this.action.config.exclusion && this.action.config.exclusion.category)
      )
        return ''

      const value = this.getNecLabelByValue(this.action.config.exclusion.category)
      if (value === undefined) {
        this.action.config.exclusion.check = false
        this.action.config.exclusion.category = null
      }

      return value
    },
    displayExclusionError() {
      return this.isExclusionEnabled && !this.action.config.exclusion
    },
    messageTextIOs() {
      const messageText =
        this.action.config.ios.messages[this.action.config.defaultLang].text
      if ((this.action.config.ios.voucher_code_enabled || 0) === 0)
        return messageText

      if (messageText === '') return 'X6RgzCXb'

      if (this.action.config.ios.voucher_code_position === 0) {
        return 'X6RgzCXb\n' + messageText
      }

      return messageText + '\nX6RgzCXb'
    },

    messageTextAndroid() {
      const messageText =
        this.action.config.android.messages[this.action.config.defaultLang].text
      if ((this.action.config.android.voucher_code_enabled || 0) === 0)
        return messageText

      if (messageText === '') return 'X6RgzCXb'

      if (this.action.config.android.voucher_code_position === 0) {
        return 'X6RgzCXb\n' + messageText
      }

      return messageText + '\nX6RgzCXb'
    },

    messageTextWeb() {
      const messageText =
        this.action.config.web.messages[this.action.config.defaultLang].text
      if ((this.action.config.web.voucher_code_enabled || 0) === 0)
        return messageText

      if (messageText === '') return 'X6RgzCXb'

      if (this.action.config.web.voucher_code_position === 0) {
        return 'X6RgzCXb\n' + messageText
      }

      return messageText + '\nX6RgzCXb'
    }
  },

  methods: {
    openEditor() {
      this.eventBus.$emit('showLargeToolbox', true)
    }
  }
}
</script>
<style lang="sass" scoped>
.btn-block
    margin-bottom: 20px
</style>
