import store from '@/stores/store'

export default {
  install(Vue) {
    Vue.mixin({
      methods: {
        $can(permission) {
          return store.getters.permissions.includes(permission)
        }
      }
    })
  }
}
