<template>
  <div class="tab-pane" :class="{ active: active }">
    <template v-if="keepAlive">
      <keep-alive>
        <slot v-if="active"></slot>
      </keep-alive>
    </template>
    <template v-else>
      <slot></slot>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    position: {
      type: Number,
      default: 1
    },
    icon: {
      type: String
    },
    tooltip: {
      type: String
    },
    title: {
      type: String
    },
    keepAlive: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    active() {
      return this.$parent.activeTab === this.id
    }
  },

  created() {
    this.$parent.addTab(this)
  },

  destroyed() {
    this.$parent.removeTab(this.id)
  }
}
</script>
