import { render, staticRenderFns } from "./WorkflowNav.vue?vue&type=template&id=063ba4cc&scoped=true&"
import script from "./WorkflowNav.vue?vue&type=script&lang=js&"
export * from "./WorkflowNav.vue?vue&type=script&lang=js&"
import style0 from "./WorkflowNav.vue?vue&type=style&index=0&id=063ba4cc&prod&lang=sass&scoped=true&"
import style1 from "./WorkflowNav.vue?vue&type=style&index=1&id=063ba4cc&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "063ba4cc",
  null
  
)

export default component.exports