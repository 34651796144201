<template>
  <span>
    {{ result.length }} of {{ result.upper_count }} ({{ result.message_parts }}
    {{ $t('smsCounterPartSms') }})
    <template>{{ state }}</template>
  </span>
</template>
<script>
import SmsCounter from '@/libs/SmsCounter'

export default {
  props: ['content', 'shortenUrl', 'forceEstimatedLabel'],

  computed: {
    result() {
      let content = this.content
      content = content.replaceAll(/{{(.*?)}}/g, '')

      let result = this.counter.count(content)
      return {
        length: result.length,
        upper_count: result.per_message * result.messages,
        message_parts: result.messages
      }
    },

    state() {
      if (this.forceEstimatedLabel || this.content.match(/{{(.*?)}}/)) {
        return this.$t('smsCounterEstimatedLabel')
      }

      return this.$t('smsCounterActualLabel')
    }
  },

  created() {
    this.counter = new SmsCounter(this.shortenUrl)
  }
}
</script>
<style lang="sass" scoped>
span
    padding: 10px
</style>
