import XpApi from './XpApi'
import { cloneDeep } from 'lodash'

export default class XpApiPaginator {
  constructor(payload) {
    this.payload = payload
    this.cancelled = false
  }

  setPage(payload, page) {
    let newPayload = cloneDeep(payload)
    newPayload.params = newPayload.params || {}
    newPayload.params.page = page
    return newPayload
  }

  async fetchAllPages() {
    return this.fetchPages({ start: 1, limit: null })
  }

  async fetchPages({ start, limit = 1 }) {
    let page = start
    let fetchData = []
    let pageCount = 0

    do {
      let currentPayload = this.setPage(this.payload, page)
      let response = await XpApi(currentPayload).then((response) => response)

      pageCount = response.headers['x-pagination-page-count'] || 1
      limit = limit || pageCount

      fetchData = fetchData.concat(response.data)
      page++
    } while (page <= pageCount && page <= limit && !this.cancelled)

    if (this.cancelled) {
      return Promise.reject(new Error('Cancelled')).catch(() => null)
    }

    return fetchData
  }

  cancel() {
    this.cancelled = true
  }
}
