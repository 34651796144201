<template>
  <ul>
    <li>
      <icon v-tooltip:left="$t('actionTooltipArrived')" glyph="user" />
      {{ numberFilter(stats['Start:entered']) }}
    </li>
    <li>
      <icon
        v-tooltip:left="$t('actionTooltipTotalEndedEarly')"
        glyph="cross-circle"
      />
      {{ numberFilter(stats['Delay:earlyCancelTotal']) }}
    </li>
  </ul>
</template>
<script>
import { numberFilter } from '@/helpers/filters'

export default {
  props: ['stats'],
  data() {
    return {
      numberFilter
    }
  }
}
</script>
