<template>
  <div class="criteria-options">
    <select2 v-model="negatorValue" class="negator">
      <option value="is">{{ $t('optionIs') }}</option>
      <option value="isnot">{{ $t('optionIsNot') }}</option>
    </select2>

    <select2
      v-model="value.condition"
      :title="$t('selectTitleNothingSelected')"
      class="condition-date"
    >
      <optgroup label="Date">
        <option title="Date: On" value="DateOn">{{ $t('optionOn') }}</option>
        <option title="Date: Before" value="DateBefore">{{ $t('optionBefore') }}</option>
        <option title="Date: After" value="DateAfter">{{ $t('optionAfter') }}</option>
        <option title="Date: Between" value="DateBetween">{{ $t('optionBetween') }}</option>
      </optgroup>
    </select2>

    <div
      :is="conditionCmp"
      v-model="value.conditionArgs"
      v-bind="{ isFutureDateOnly: true }"
      class="condition-args-date"
    ></div>
  </div>
</template>
<script>
import Select2 from '@/components/Select2'

export default {
  components: { Select2 },
  props: ['value'],

  computed: {
    conditionCmp() {
      try {
        return require(`./Profile/${this.value.condition}`).default
      } catch (e) {}

      return null
    },

    negatorValue: {
      get() {
        return this.value.negator ? 'isnot' : 'is'
      },
      set(val) {
        this.value.negator = val !== 'is'
      }
    }
  }
}
</script>
