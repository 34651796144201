var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin":"20px"}},[_c('button',{staticClass:"btn btn-default btn-block",attrs:{"type":"button"},on:{"click":_vm.openEditor}},[_vm._v("\n    "+_vm._s(_vm.readOnly
        ? _vm.$t('emailSmallToolboxPreviewBtn')
        : _vm.$t('emailSmallToolboxEditBtn'))+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"panel panel-default"},[_c('div',{staticClass:"panel-body"},[_c('div',{staticClass:"email-prop-name"},[_vm._v(_vm._s(_vm.$t('emailSubjectLine')))]),_vm._v(" "),_c('div',{staticClass:"email-prop-value",class:{ 'invalid-text': _vm.$v.action.subject.$invalid }},[_vm._v("\n        "+_vm._s(_vm.action.subject)+"\n        "),(!_vm.$v.action.subject.required)?[_vm._v("\n          "+_vm._s(_vm.$t('emailToolboxSmallMissingSubject'))+"\n        ")]:_vm._e(),_vm._v(" "),(
            _vm.$v.action.subject.required && !_vm.$v.action.subject.maxLengthValue
          )?[_vm._v("\n          ("+_vm._s(_vm.$t('emailToolboxSmallSubjectTooLong'))+")\n        ")]:_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"email-prop-name"},[_vm._v(_vm._s(_vm.$t('emailFromAddress')))]),_vm._v(" "),_c('div',{staticClass:"email-prop-value",class:{ 'invalid-text': _vm.$v.action.fromAddress.$invalid }},[_vm._v("\n        "+_vm._s(_vm.action.fromAddress)+"\n        "),(!_vm.$v.action.fromAddress.required)?[_vm._v("\n          "+_vm._s(_vm.$t('emailToolboxSmallMissingFromAddress'))+"\n        ")]:_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"email-prop-name"},[_vm._v(_vm._s(_vm.$t('emailFromName')))]),_vm._v(" "),_c('div',{staticClass:"email-prop-value",class:{ 'invalid-text': _vm.$v.action.fromName.$invalid }},[_vm._v("\n        "+_vm._s(_vm.action.fromName)+"\n        "),(!_vm.$v.action.fromName.required)?[_vm._v("\n          "+_vm._s(_vm.$t('emailToolboxSmallMissingFromName'))+"\n        ")]:_vm._e(),_vm._v(" "),(
            _vm.$v.action.fromName.required && !_vm.$v.action.fromName.maxLengthValue
          )?[_vm._v("\n          ("+_vm._s(_vm.$t('emailToolboxSmallFromNameTooLong'))+")\n        ")]:_vm._e()],2),_vm._v(" "),(_vm.action.replyToAddress || _vm.action.replyToName)?[_c('div',{staticClass:"email-prop-name"},[_vm._v("\n          "+_vm._s(_vm.$t('emailToolboxSmallReplyToEmail'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"email-prop-value",class:{ 'invalid-text': _vm.$v.action.replyToAddress.$invalid }},[[_vm._v(_vm._s(_vm.action.replyToAddress))],_vm._v(" "),(!_vm.$v.action.replyToAddress.required)?[_vm._v("\n            "+_vm._s(_vm.$t('emailToolboxSmallMissingReplyToAddress'))+"\n          ")]:_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"email-prop-name"},[_vm._v(_vm._s(_vm.$t('emailToolboxSmallReplyToName')))]),_vm._v(" "),_c('div',{staticClass:"email-prop-value",class:{ 'invalid-text': _vm.$v.action.replyToName.$invalid }},[[_vm._v(_vm._s(_vm.action.replyToName))],_vm._v(" "),(!_vm.$v.action.replyToName.required)?[_vm._v("\n            "+_vm._s(_vm.$t('emailToolboxSmallMissingReplyToName'))+"\n          ")]:_vm._e(),_vm._v(" "),(
              _vm.$v.action.replyToName.required &&
              !_vm.$v.action.replyToName.maxLengthValue
            )?[_vm._v("\n            ("+_vm._s(_vm.$t('emailToolboxSmallMissingReplyToNameTooLong'))+")\n          ")]:_vm._e()],2)]:_vm._e(),_vm._v(" "),(_vm.isExclusionEnabled)?_c('div',[_c('div',{staticClass:"email-prop-name"},[_vm._v("\n          "+_vm._s(_vm.$t('emailToolboxSmallExclusionCategory'))+"\n        ")]),_vm._v(" "),(
            _vm.action.config.exclusion && _vm.action.config.exclusion.check === false
          )?_c('div',{staticClass:"email-prop-value"},[_vm._v("\n          "+_vm._s(_vm.$t('emailToolboxSmallExclusionCategoryNotEnabled'))+"\n        ")]):_vm._e(),_vm._v(" "),(
            (_vm.action.config.exclusion && _vm.action.config.exclusion.check) ||
            _vm.displayExclusionError
          )?_c('div',{staticClass:"email-prop-value",class:{
            'invalid-text':
              _vm.displayExclusionError ||
              _vm.$v.action.config.exclusion.category.$invalid
          }},[_vm._v("\n          "+_vm._s(_vm.exclusionCategoryName)+"\n          "),(
              _vm.displayExclusionError ||
              !_vm.$v.action.config.exclusion.category.required
            )?[_vm._v("\n            "+_vm._s(_vm.$t('emailToolboxSmallMissingExclusionCategory'))+"\n          ")]:_vm._e()],2):_vm._e()]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"email-prop-name"},[_vm._v("\n        "+_vm._s(_vm.$t('emailToolboxSmallMessageType'))+":\n      ")]),_vm._v(" "),_c('div',{staticClass:"email-prop-value"},[_vm._v("\n        "+_vm._s(_vm.ignoreSubscriptionMap(_vm.action.config.ignore_subscription))+"\n      ")])],2)]),_vm._v(" "),(!_vm.action.contentId)?_c('div',{staticClass:"panel panel-default"},[_c('div',{staticClass:"panel-body"},[_vm._v(_vm._s(_vm.$t('emailToolboxSmallPanelContentNoContent')))])]):_c('div',{staticClass:"panel panel-default email-preview"},[_c('iframe',{staticClass:"email-preview-content",attrs:{"srcdoc":_vm.contentModel.content}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }