<template>
  <div style="height: 100%; overflow-y: scroll; padding: 30px">
    <label>{{ $t('smsStatsTitle') }}:</label>
    <div class="stats-bar">
      <div class="stats-bar-item">
        <icon class="stats-bar-item-icon" glyph="user" />
        <div class="stats-bar-item-title">
          {{ $t('actionReportingStatsArrivedLabel') }}:
        </div>
        <div class="stats-bar-item-number">
          {{ numberFilter(arrivedTotal) }}
        </div>
      </div>
      <div class="stats-bar-item">
        <icon class="stats-bar-item-icon" glyph="sms" />
        <div class="stats-bar-item-title">
          {{ $t('actionReportingStatsSentLabel') }}:
        </div>
        <div class="stats-bar-item-number">
          {{ numberFilter(sentTotal) }}
          ({{ percentageOfFilter(sentTotal, arrivedTotal) }})
        </div>
      </div>
    </div>
    <hr />
    <line-chart
      :chart-data="chartData"
      :title="$t('smsActionReportingProfilesArrivedLabel')"
      title-class="action-color-messages"
      :y-label="$t('smsActionReportingNumberOfProfilesLabel')"
    />
  </div>
</template>
<script>
import ReportingMixin from '@/libs/ReportingMixin'
import TrendLineMixin from '@/libs/TrendLineMixin'

import ActionStatsLoaderMixin from './Charts/ActionStatsLoaderMixin'
import LineChart from '@/components/DateLineChart'
import { numberFilter, percentageOfFilter } from '@/helpers/filters'

export default {
  components: {
    LineChart
  },
  mixins: [ActionStatsLoaderMixin, ReportingMixin, TrendLineMixin],

  data() {
    return {
      numberFilter,
      percentageOfFilter
    }
  },

  computed: {
    arrivedTotal() {
      return this.getTotal('Sms:trigger')
    },

    sentTotal() {
      return this.getTotal('Sms:sent')
    },

    arrivedStats() {
      return this.getCombinedStats('Sms:trigger')
    },

    sentStats() {
      return this.getStats('Sms:sent')
    },

    chartData() {
      return {
        labels: this.dateRange,
        datasets: [
          {
            label: this.$t('actionReportingStatsArrivedLabel'),
            backgroundColor: 'RGBA(77, 140, 192, 0.2)',
            borderColor: 'RGBA(77, 140, 192, 1)',
            borderWidth: 1,
            data: this.arrivedStats
          },
          {
            tooltip: false,
            fill: false,
            label: this.$t('actionReportingStatsTrendLineLabel'),
            data: this.getTrendLine(this.arrivedStats),
            backgroundColor: 'RGBA(77, 140, 192, 1)',
            borderColor: 'RGBA(77, 140, 192, 1)',
            spanGaps: true
          },
          {
            label: this.$t('actionReportingStatsSentLabel'),
            backgroundColor: 'RGBA(255, 195, 113, 0.2)',
            borderColor: 'RGBA(255, 195, 113, 1)',
            borderWidth: 1,
            data: this.sentStats
          },
          {
            tooltip: false,
            fill: false,
            label: this.$t('actionReportingStatsTrendLineLabel'),
            data: this.getTrendLine(this.sentStats),
            backgroundColor: 'RGBA(255, 195, 113, 1)',
            borderColor: 'RGBA(255, 195, 113, 1)',
            spanGaps: true
          }
        ]
      }
    }
  }
}
</script>
