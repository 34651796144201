<template>
  <div style="margin: 20px">
    <div class="form-group">
      <label class="control-label">{{ $t('promotionLabel') }}:</label>
      <multiselect
        :placeholder="$t('selectPromotionPlaceholder')"
        :select-label="$t('vueMultiSelectSelectLabel')"
        :selected-label="$t('vueMultiSelectSelectedLabel')"
        :deselect-label="$t('vueMultiSelectDeselectLabel')"
        :deselect-title="$t('vueMultiSelectDeselectTitle')"
        :no-results="$t('vueMultiSelectNoResults')"
        :options="promotions"
        :value="selectedPromotion"
        label="name"
        track-by="id"
        :custom-label="promotionsLabel"
        :disabled="readOnly"
        @input="onSelect"
      >
        <template #noOptions>
          {{ $t('vueMultiSelectNoOptions') }}
        </template>
      </multiselect>
    </div>

    <div v-if="hasOptions" class="form-group">
      <label class="control-label">{{ $t('optionLabel') }}:</label>
      <multiselect
        :placeholder="$t('selectOptionPlaceholder')"
        :select-label="$t('vueMultiSelectSelectLabel')"
        :selected-label="$t('vueMultiSelectSelectedLabel')"
        :deselect-label="$t('vueMultiSelectDeselectLabel')"
        :deselect-title="$t('vueMultiSelectDeselectTitle')"
        :no-results="$t('vueMultiSelectNoResults')"
        :options="events"
        :value="selectedEvent"
        label="label"
        :disabled="readOnly"
        @input="onInput"
      >
        <template #noOptions>
          {{ $t('vueMultiSelectNoOptions') }}
        </template>
      </multiselect>
    </div>

    <div v-if="isAmountRequired" class="form-group">
      <label class="control-label">{{ $t('promotionAmountLabel') }}:</label>
      <div style="margin-bottom: 10px">
        <select2
          v-model="amountType"
          class="form-control"
          :disabled="readOnly"
          @input="onAmountTypeChange"
        >
          <option value="custom">
            {{ $t('promotionAmountCustomAmountOption') }}
          </option>
          <option value="attribute">
            {{ $t('promotionAmountProfileAttributeOption') }}
          </option>
        </select2>
      </div>
      <input
        v-if="amountType === 'custom'"
        v-model="action.promotion_amount"
        class="form-control"
        type="number"
        :disabled="readOnly"
        @input="onAmountInput"
        @change="onAmountChange"
      />
      <multiselect
        v-if="amountType === 'attribute'"
        :placeholder="$t('selectProfileAttributePlaceholder')"
        :select-label="$t('vueMultiSelectSelectLabel')"
        :selected-label="$t('vueMultiSelectSelectedLabel')"
        :deselect-label="$t('vueMultiSelectDeselectLabel')"
        :deselect-title="$t('vueMultiSelectDeselectTitle')"
        :no-results="$t('vueMultiSelectNoResults')"
        :value="selectedNumberProfileAttribute"
        :options="numberProfileAttributes"
        track-by="id"
        label="name"
        :disabled="readOnly"
        @input="onAmountAttributeChange"
      >
        <template #noOptions>
          {{ $t('vueMultiSelectNoOptions') }}
        </template>
      </multiselect>
    </div>

    <div v-if="isDurationRequired" class="form-group">
      <label class="control-label">{{ $t('promotionDurationLabel') }}:</label>
      <input
        v-model="action.promotion_duration"
        class="form-control"
        type="number"
        :disabled="readOnly"
        @input="onDurationInput"
        @change="onDurationChange"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Promotion',
  inject: ['workflowApi'],
  props: ['action', 'readOnly'],

  data() {
    return {
      translations: {
        alira: this.$t('promotionAliraLabel'),
        lithium: this.$t('promotionLithiumSubscriptionsLabel'),
        lithiumv2: this.$t('promotionLithiumPromotionsLabel'),
        sbtech: this.$t('promotionSBTechLabel'),
        tglab: this.$t('promotionTGLabLabel')
      },
      event: [
        { id: 1, label: this.$t('promotionEventAddToGroupLabel') },
        { id: 2, label: this.$t('promotionEventRemoveFromGroupLabel') }
      ],
      amountType:
        this.action.promotion_amount_attribute_title !== null
          ? 'attribute'
          : 'custom'
    }
  },

  computed: {
    events() {
      let promotion = this.$store.state.project.workflowPromotions.find(
        (promotion) => promotion.id === this.action.promotion_id
      )
      let event = []
      if (promotion) {
        if (promotion.can_add) {
          event.push({ id: 1, label: this.$t('promotionEventAddToGroupLabel') })
        }
        if (promotion.can_remove) {
          event.push({
            id: 2,
            label: this.$t('promotionEventRemoveFromGroupLabel')
          })
        }
      }
      return event
    },

    promotions() {
      return this.$store.state.project.workflowPromotions.filter(
        (promotions) => promotions.can_add || promotions.can_remove
      )
    },

    selectedPromotion() {
      return this.$store.state.project.workflowPromotions.find(
        (promotion) => promotion.id === this.action.promotion_id
      )
    },

    selectedEvent() {
      return this.event.find((event) => event.id === this.action.event)
    },

    numberProfileAttributes() {
      return this.$store.state.project.numberProfileAttributes
    },

    selectedNumberProfileAttribute() {
      return this.$store.state.project.numberProfileAttributes.find(
        (attr) => attr.name === this.action.promotion_amount_attribute_title
      )
    },

    hasOptions() {
      let selectedPromotion = this.selectedPromotion
      if (selectedPromotion) {
        return !!(selectedPromotion.can_add && selectedPromotion.can_remove)
      }
    },

    isAmountRequired() {
      let selectedPromotion = this.selectedPromotion
      if (selectedPromotion) {
        return !!parseInt(selectedPromotion.amount_required)
      }
    },

    isDurationRequired() {
      let selectedPromotion = this.selectedPromotion
      if (selectedPromotion) {
        return !!parseInt(selectedPromotion.duration_required)
      }
    }
  },

  created() {
    this.$store.dispatch('fetchWorkflowPromotions')
    this.$store.dispatch('fetchNumberProfileAttributes')
  },

  methods: {
    promotionsLabel(option) {
      let external_system =
        this.translations[option.external_system] || option.external_system
      if (option.integration) {
        return (
          option.integration.name +
          ' - ' +
          external_system +
          ' - ' +
          option.name
        )
      }
      return external_system + ' - ' + option.name
    },

    onSelect(option) {
      if (!option) {
        return
      }

      this.action.promotion_amount = 0
      this.action.promotion_duration = 1

      this.workflowApi.updateAction(this.action.id, {
        promotion_amount_required: option.amount_required
      })

      this.workflowApi.updateAction(this.action.id, {
        promotion_duration_required: option.duration_required
      })

      this.workflowApi.updateAction(this.action.id, { promotion_id: option.id })

      //bitewise operation switches between 1 or 2 when selecting a promotion
      this.workflowApi.updateAction(this.action.id, {
        event: option.can_add || option.can_remove << 1
      })
    },

    onInput(option) {
      if (!option) {
        return
      }
      this.workflowApi.updateAction(this.action.id, { event: option.id })
    },

    onAmountChange(event) {
      const value = event.target.value

      if (!value) {
        this.action.promotion_amount = 0
        return
      }

      this.action.promotion_amount = Number.parseFloat(value).toFixed(2)
      this.workflowApi.updateAction(this.action.id, {
        promotion_amount: this.action.promotion_amount
      })
    },

    onAmountAttributeChange(option) {
      if (!option) {
        return
      }

      this.workflowApi.updateAction(this.action.id, {
        promotion_amount_attribute_title: option.name
      })
    },

    onAmountTypeChange() {
      this.workflowApi.updateAction(this.action.id, {
        promotion_amount: 0,
        promotion_amount_attribute_title: null
      })
    },

    onDurationChange(event) {
      const value = event.target.value

      if (!value) {
        this.action.promotion_duration = 1
        return
      }

      this.workflowApi.updateAction(this.action.id, {
        promotion_duration: this.action.promotion_duration
      })
    },

    onAmountInput(event) {
      if (event.target.value.toString().length > 10) {
        this.action.promotion_amount = +event.target.value
          .toString()
          .substring(0, 10)
      }
    },

    onDurationInput(event) {
      if (event.target.value.toString().length > 10) {
        this.action.promotion_duration = +event.target.value
          .toString()
          .substring(0, 10)
      }
    }
  }
}
</script>
