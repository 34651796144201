<template>
  <pre><code class="hljs" v-html="highlighted"></code></pre>
</template>
<script>
import hljs from 'highlight.js/lib/highlight'

export default {
  props: {
    value: {
      required: true
    },

    lang: {
      required: true,
      type: [String, Array]
    }
  },

  computed: {
    langArray() {
      if (Array.isArray(this.lang)) {
        return this.lang
      }

      return [this.lang]
    },

    highlighted() {
      let val = this.value

      if (typeof val !== 'string') {
        val = JSON.stringify(this.value, null, '  ')
      }

      return hljs.highlightAuto(val, this.langArray).value
    }
  },

  created() {
    this.langArray.forEach((lang) => {
      if (!hljs.listLanguages().includes(lang)) {
        const langModule = require(`highlight.js/lib/languages/${lang}`)
        hljs.registerLanguage(lang, langModule)
      }
    })
  }
}
</script>
