const ANCHORS = {
  2: {
    a: [0.25, 1, 0, 1],
    else: [0.75, 1, 0, 1]
  },
  3: {
    a: [0.17, 1, 0, 1],
    b: [0.5, 1, 0, 1],
    else: [0.83, 1, 0, 1]
  },
  4: {
    a: [0.13, 1, 0, 1],
    b: [0.37, 1, 0, 1],
    c: [0.62, 1, 0, 1],
    else: [0.87, 1, 0, 1]
  },
  5: {
    a: [0.1, 1, 0, 1],
    b: [0.3, 1, 0, 1],
    c: [0.5, 1, 0, 1],
    d: [0.7, 1, 0, 1],
    else: [0.9, 1, 0, 1]
  },
  6: {
    a: [0.09, 1, 0, 1],
    b: [0.247, 1, 0, 1],
    c: [0.415, 1, 0, 1],
    d: [0.577, 1, 0, 1],
    e: [0.745, 1, 0, 1],
    else: [0.913, 1, 0, 1]
  }
}

export { ANCHORS }
