<template>
  <div class="panel panel-primary">
    <div class="panel-heading">
      <div class="panel-title">
        <router-link
          tag="button"
          type="button"
          class="btn btn-xs btn-primary back"
          :to="{ name: 'ProjectAdminProfiles' }"
          exact
        >
          &#11013;
        </router-link>
        Profile Activity: {{ profileId }}
      </div>
    </div>

    <table class="table">
      <thead class="table-header">
        <tr>
          <th class="col-md-1">Environment</th>
          <th class="col-md-2">Workflow/Campaign</th>
          <th class="col-md-1">Status</th>
          <th class="col-md-4">Activity</th>
          <th class="col-md-1">Action</th>
          <th class="col-md-1">Timestamp</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="execution in activity">
          <td class="col-md-1">
            {{ execution.execution }}
          </td>
          <td class="col-md-2">
            <b>WorkflowID:</b> <router-link
              :to="{ name: 'ProjectAdminProfileActivityByWorkflow', params: { projectId, profileId, workflowId: execution.workflow_id } }">{{
              execution.workflow_id }}</router-link>
            <br />
            <b>CampaignID:</b> <router-link
              :to="{ name: 'ProjectAdminProfileActivityByCampaign', params: { projectId, profileId, campaignId: execution.workflow_campaign_id } }">{{
              execution.workflow_campaign_id }}</router-link>
            <br />
            <b>Active:</b> {{ execution.workflow_campaign_active }}
            <br />
            <b>Title:</b> {{ execution.workflow_campaign_title }}
            <br />
            <router-link
              :to="{ name: 'WorkflowPreview', params: { projectId, workflowId: execution.workflow_id } }">View
              Workflow</router-link>
          </td>
          <td class="col-md-1">
            <span class="status">{{ execution.status }}</span>
            <div v-if="execution.status === 'scheduled'">
              until
              <br />
              {{ formatDate(execution.suspendUntil, 'L LTS') }}
            </div>
            <div v-if="execution.status === 'scheduled' || execution.status === 'halted'">
              <button type="button" @click="cancelEnvironment(execution.execution)">Cancel</button>
            </div>
          </td>
          <td class="col-md-6 activity-log" colspan="3">

            <table class="table row-fluid">
              <tbody>
                <template v-for="actionActivity in execution.activity">
                  <tr>
                    <td class="col-md-8 activity"><action-icon :actionType="actionActivity.actionType" />
                      {{ formatActionActivity(actionActivity.activity) }} -
                      <strong>{{ actionActivity.action }}</strong>
                    </td>
                    <td class="col-md-2">{{ actionActivity.actionId }} / {{ actionActivity.actionRef }}</td>
                    <td class="col-md-2">
                      {{ formatDate(actionActivity.timestamp, 'L LTS') }}
                    </td>
                  </tr>
                  <template v-for="actionActivityChildren in actionActivity.children">
                    <tr>
                      <td class="col-md-8 activity sub"><action-icon :actionType="'Spacer'" />
                        {{
                          formatActionActivity(actionActivityChildren.activity)
                        }}
                        -
                        <strong>{{ actionActivityChildren.action }}</strong>
                      </td>
                      <td class="col-md-2"></td>
                      <td class="col-md-2">
                        {{
                          formatDate(actionActivityChildren.timestamp, 'L LTS')
                        }}
                      </td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </table>

          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import MxaApi from '@/libs/MxaApi';
import url from '@/libs/UrlTemplateTag';
import moment from "moment";

export default {
  props: ['projectId', 'profileId', 'campaignId', 'workflowId'],

  data() {
    return {
      activity: []
    }
  },

  watch: {
    profileId: function () {
      this.getActivity(this.$route.name);
    },
    '$route'(to, from) {
      this.getActivity(to.name);
    }
  },

  methods: {
    async getActivity(routeName) {
      this.activity = [];
      if (routeName == 'ProjectAdminProfileActivity') {
        MxaApi.get(url`projects/${this.projectId}/profiles/${this.profileId}/activity`)
          .then(({ data }) => this.activity = data);
      }
      if (routeName == 'ProjectAdminProfileActivityByCampaign') {
        MxaApi.get(url`projects/${this.projectId}/profiles/${this.profileId}/activity/c${this.campaignId}`)
          .then(({ data }) => this.activity = data);
      }
      if (routeName == 'ProjectAdminProfileActivityByWorkflow') {
        MxaApi.get(url`projects/${this.projectId}/profiles/${this.profileId}/activity/wf${this.workflowId}`)
          .then(({ data }) => this.activity = data);
      }
    },
    cancelEnvironment(id) {
      if(confirm('Are you sure?')) {
        MxaApi.post(
          url`projects/${this.projectId}/environment/cancel`,
          {
            id: id
          }
        )
          .then(({ data }) => {
            this.getActivity(this.$route.name);
          });
      }
    },
    formatActionActivity(name) {
      // Converts the colon to a space, uppercases each part and seperates camelCase words with spaces
      // EG: Converts "Decision:evaluatedNo" to "Decision - Evaluated No"
      return name
        .split(':')
        .map(name => (name.charAt(0).toUpperCase() + name.slice(1)).replace(/([A-Z])/g, ' $1').trim())
        .join(' - ')
    },
    formatDate(date, format) {
      return moment(date).format(format)
    }
  },

  created() {
    this.getActivity(this.$route.name);
  }
}
</script>
<style lang="sass" scoped>
button.back
    margin-left: -5px
    margin-top: -3px
    margin-right: 5px

.activity-log
    padding: 8px 0px

    table
        background: transparent
        margin-bottom: 0

        td
            border-top: none
            padding: 0px 8px 4px 8px
.activity
    text-transform: capitalize
    .sub
        padding-left: 26px
.status
    text-transform: capitalize
</style>
